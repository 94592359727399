import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { Label, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';
import UpdatePassword from './UpdatePassword';

const AccountOptionBox = styled.div`
  padding: 20px 20px 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
`;

const AccountOption = styled(Label)`
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
`;
  
const UpdatePasswordText = styled(Label)`
  font-weight: 300;
  margin-bottom: 0;
  cursor: pointer;
  :hover {
    color: hsla(0,0%,100%,0.800);
  }
`;

const UpdatePasswordModal: FC = () => {
  const { createModal, setModalOpen } = useModal();
  const { t } = useTranslation(['Users', 'AddEditUser', 'Common']);

  const openUpdatePasswordModal = useCallback(() => {
    createModal({
      isCloseEnable: true,
      width: '460px',
      padding: true,
      closeText: t('Common:close'),
      dismissCallback: () => { setModalOpen(false); },
      customComponent: <UpdatePassword />,
    });
  }, [createModal, setModalOpen, t]);

  return (
    <>
      <AccountOptionBox>
        <AccountOption htmlFor='account_option' labelText={t('accountOptions')} />
        <div onClick={() => { openUpdatePasswordModal(); }}>
          <UpdatePasswordText htmlFor='Update_Password' labelText={t('AddEditUser:updatePasswordText')} />
        </div>
      </AccountOptionBox>
    </>
  );
}

export default UpdatePasswordModal