import { useCallback, useState } from 'react';
import { format } from 'date-fns';
import { useNotification } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';

export const useSystemExport = () => {
  const { t } = useTranslation(['SystemExport', 'Common']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isProcessed, setIsProcessed] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [zipFileData, setZipFileData] = useState<Blob>();
  const {sendNotification} = useNotification();

  const onExportSystemData = useCallback(async () => {
    setIsLoading(true);
    setIsProcessed(false);    
    setErrorMessage('');

    try {
      const response = await fetch('/api/system_config/export');

      if (response.ok) {
        const res: Blob =  await response.blob();
        setZipFileData(res);

        const fileName = `config_export_${format(new Date(), 'yyyy-LL-dd_HH-mm-ssxx')}.zip`;
        const url = URL.createObjectURL(new Blob([res], {type: 'application/zip'}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        setErrorMessage(response.statusText);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setErrorMessage(error.message);
      } else {
        console.error(error);
        setErrorMessage('unknownError');
      }
    } finally {
      setIsLoading(false);
      setIsProcessed(true);
    }
  }, []);

  const onDownloadingSystemData = useCallback(() => {
    const fileName = `config_export_${format(new Date(), 'yyyy-LL-dd_HH-mm-ssxx')}.zip`;
    if (!zipFileData) {
      sendNotification({type: 'error', message: t('fileNotExist')});
      return;
    }
    const url = URL.createObjectURL(new Blob([zipFileData], {type: 'application/zip'}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [zipFileData, t, sendNotification]);

  return {
    isLoading,
    isProcessed,
    errorMessage,
    onExportSystemData,
    onDownloadingSystemData
  };
};
