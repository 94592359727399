import { useCallback, useState } from 'react';
import { IFetchOptions } from '../types';

export interface IRelay {
  id: number
  name: string
  device_id: number
  port: number
  duration?: number
  on_delay: number
  active: boolean
}

interface IRelaysResponse {
  message: string
  relays: IRelay[]
  info: {
    total: number
    limit: number
    offset: number
  }
}

export const useRelays = () => {
  const [relays, setRelays] = useState<IRelay[]>([])
  const [relaysTotal, setRelaysTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false);

  const fetchRelays = useCallback(async (options?: IFetchOptions) => {

    setIsLoading(true);

    const {
      limit = 100,
      offset = 0,
      device_id
    } = (options || {});


    const searchString = new URLSearchParams({
      limit: '' + limit,
      offset: '' + offset,
      ...(device_id !== undefined ? { device_id: `${device_id}` } : {})
    });

    const url = '/api/relay?' + searchString.toString();
    try {
      const response = await fetch(url)

      if (response.ok) {
        const {
          relays,
          info: {
            total
          }
        } = await response.json() as IRelaysResponse

        setRelays(relays);
        setRelaysTotal(total)

      } else {
        return false;
      }
      return true;

    } catch (error) {
      console.error('error', error)
      return false;
    } finally {
      setIsLoading(false);
    }

  }, [])

  return {
    relays,
    isLoading,
    relaysTotal,
    actions: {
      fetchRelays
    }
  }
}