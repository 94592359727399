import * as React from "react";
function SvgPerson(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <circle cx={12.25} cy={4} r={3} />
        <path d="M17.5 13.75a5.25 5.25 0 00-10.5 0V16h2.25l.75 7.5h4.5l.75-7.5h2.25v-2.25z" />
      </g>
    </svg>
  );
}
export default SvgPerson;
