import { FC } from 'react';
import { IFilterItem } from 'scorer-ui-kit';
import { Container } from '../Style';
import { useUser } from '../hooks/useUser';
import CameraGridView from '../components/cameras/CameraGridView';
import CameraListView from '../components/cameras/CameraListView';

export interface ISelectedFilterValues {
  [key: string]: string | undefined | IFilterItem[] | boolean
}

const Cameras: FC = () => {

  const { features: { tas = false } = {} } = useUser();

  return (
    <Container>
      {tas ?
        <CameraListView /> :
        <CameraGridView />}
    </Container>
  )

}

export default Cameras;