import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Content, IntroductionText,
  PageTitle,
  Button,
  TagList,
  ITag,
  Tabs,
  TabList,
  Tab,
  TabContent,
  useTo,
  Spinner
} from 'scorer-ui-kit';
import styled from 'styled-components';
import FeatureCondition from '../components/atoms/FeatureContition';
import AlertsTab from '../components/camera-details/AlertsTab';
import CameraBasicDetails from '../components/camera-details/CameraBasicDetails';
import CameraConfig from '../components/camera-details/CameraConfigTab';
import DetectionTab from '../components/camera-details/DetectionTab';
import ZoneMapTab from '../components/camera-details/ZoneMapTab';
import CameraFileUpload from '../components/dashboard-tabs/CameraFileUpload';
import MediaViewer from '../components/molecules/MediaViewer';
import { useCamera } from '../hooks/useCamera';
import { useElementTitle } from '../hooks/useElementTitle';
import { DASHBOARD_PARAMS, useSelected } from '../hooks/useSelected';
import { useUser } from '../hooks/useUser';
import { EllipsisStyles } from '../Style';
import { getCategoTagList, getDetectionTypesLocal } from '../utils';

const Container = styled(Content)`
  overflow: inherit;
  isolation: isolate;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-Between;
  margin-bottom: 30px;
`;

const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-Between;
  h1 {
    max-width: 280px;
    ${EllipsisStyles}
  }
`;

const EditMetaButton = styled(Button)`
  margin-top: 12px;
`;

const PageHeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PageHeaderRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 570px;
  border-left: 1px solid ${({ theme }) => theme.colors.divider};
`;

const TitleAndIntroWrapper = styled.div`
  padding-right: 20px;
  min-width: 400px;
`;

const EmptyCategoryTags = styled.div`
  font-size: 12px;
  color: hsl(0, 0%, 46%);
  font-style: italic;
`;

const Divider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.divider};
  margin-left: 0px;
`;

const TagListWrapper = styled.div`
  div {
    border: none;
  }
`;

const EmptyDescription = styled.div`
  margin-top: 22px;
  font-style: italic;
  font-size: 12px;
  color: hsl(207, 5%, 57%);
`;

const CameraDescription = styled.div`
  word-break: break-word;
`;

interface IParam {
  cameraID?: string | undefined
}

const TAB_LIST = ['alerts', 'detection', 'zoneMap'];

const CameraDetails: FC = () => {
  const [categoryTagList, setCategoryTagList] = useState<ITag[]>([]);
  const {features: {cameraSnapshot = false}} = useUser();
  const { camera, cameraLoading, actions: { fetchCamera } } = useCamera();

  const fromPage: string | null = new URLSearchParams(window.location.search).get('fromPage');
  const { t } = useTranslation(['CameraDetails', 'Common']);
  const to = useTo();
  const params: IParam = useParams();
  useElementTitle('h1', camera?.name!);
  const { getParam, updateParam } = useSelected();
  const cameraID = params.cameraID !== undefined ? parseInt(params.cameraID): null;
  const {scorer_name = `RTSPcam${cameraID}`, meta: {rtspURL=''}={}} = camera||{};

  const generateTagList = useCallback(() => {
    if (camera?.category && camera?.tags) {
      return getCategoTagList(camera?.category, camera?.tags);
    } else if (camera?.tags) {
      return getCategoTagList('', camera?.tags);
    } else if (camera?.category) {
      return getCategoTagList(camera?.category, '');
    }

    return [];

  }, [camera?.category, camera?.tags]);

  const onChangeTab = useCallback((id) => {
    updateParam(DASHBOARD_PARAMS.tab, id);
  }, [updateParam]);

  useEffect(() => {
    setCategoryTagList(generateTagList());
  }, [generateTagList]);

  useEffect(() => {
    cameraID !== null && fetchCamera(cameraID);
  }, [fetchCamera, cameraID]);

  const getAreaHref = useCallback((fromPage: string | null) => {
    let areaHref = '/cameras'
    switch (fromPage) {
      case 'dashboard':
        areaHref = '/'
        break;
      case 'knownVehicles':
        areaHref = '/detection/known-vehicles'
        break;
      case 'knownPeople':
        areaHref = '/detection/known-people'
        break;
      case 'intrusion':
        areaHref = '/detection/intrusion'
        break;
      case 'faceAnalysis':
        areaHref = '/detection/face-analysis'
        break;
      default:
        areaHref = '/cameras';
        break;
    }
    return areaHref;
  }, []);

  return (
    <Container>

      <PageHeaderWrapper>

        <PageHeaderLeftContainer>

          <TitleAndIntroWrapper>
            <TitleRow>
              <div>
                <PageTitle title={camera?.name! || '-'} icon='Camera' areaTitle={fromPage ? t(`fromPageAreaTitle.${fromPage}`) : t('cameras')} areaHref={getAreaHref(fromPage)} />
              </div>
              <FeatureCondition enabledFeatures={['cameraEdit']}>
                <EditMetaButton size='xsmall' design='secondary' onClick={to(`/cameras/${cameraID}/edit`)}>{t('editMeta')}</EditMetaButton>
              </FeatureCondition>
            </TitleRow>
            <TagListWrapper>
              { categoryTagList.length !== 0 ? <TagList tagsConfig={categoryTagList} /> : <EmptyCategoryTags>{t('emptyCategoryTags')}</EmptyCategoryTags>}
            </TagListWrapper>
            {camera?.details ? <CameraDescription><IntroductionText>{camera?.details}</IntroductionText></CameraDescription> : <EmptyDescription>{t('emptyDescription')}</EmptyDescription>}
          </TitleAndIntroWrapper>
        </PageHeaderLeftContainer>
        <PageHeaderRightContainer>
          <CameraBasicDetails status={t('Common:cameraStatus.online')} locationName={camera?.location_name!} detectionTypes={getDetectionTypesLocal(camera?.detection_types)} zoneName={camera?.zone_name!} />
          <MediaViewer
            cameraID={cameraID}
            imageUrl={`/snapshot/api/v1/stacks/${scorer_name}/snapshot?timestamp=${Date.now()}`}
            videoUrl='/websocket_sink/socket.io'
            rtspURL={rtspURL}
            showImage={cameraSnapshot}
          />
        </PageHeaderRightContainer>
      </PageHeaderWrapper>

      {cameraLoading ?
        <Spinner size='large' styling='primary' /> :

        <Tabs>
          <TabList defaultTabId={ getParam(DASHBOARD_PARAMS.tab) ||'alerts'} >
            {TAB_LIST.map((tab) => <Tab key={tab} tabFor={tab} onClick={() => onChangeTab(tab)} > {t(tab)} </Tab>)}
            <FeatureCondition enabledFeatures={['cameraConfig']}>
              <Tab tabFor='cameraConfig' onClick={() => onChangeTab('cameraConfig')} > {t('cameraConfig')} </Tab>
            </FeatureCondition>
            <FeatureCondition enabledFeatures={['cameraFiles']}>
              <Tab tabFor='cameraFileUpload' onClick={() => onChangeTab('cameraFileUpload')} > {t('cameraFileUpload')}  </Tab>
            </FeatureCondition>
          </TabList>

          <Divider />

          <TabContent tabId='alerts'>
            <AlertsTab {...{ camera }} />
          </TabContent>

          <TabContent tabId='detection'>
            <DetectionTab />
          </TabContent>

          <TabContent tabId='zoneMap'>
            <ZoneMapTab {...{ camera }} />
          </TabContent>

          <TabContent tabId='cameraConfig'>
            <CameraConfig camera={camera}/>
          </TabContent>

          <TabContent tabId='cameraFileUpload'>
            <CameraFileUpload />
          </TabContent>

        </Tabs>}

    </Container>
  )
};

export default CameraDetails;