import { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { ButtonWithIcon, Icon, DropArea, InputFileButton } from 'scorer-ui-kit'
import styled, { css } from 'styled-components';
const SAMPLE_FILE = '/sample-file.csv';

const HeadingText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 20px;
  font-weight: 500;
`;

const Heading = styled.div`
  margin-left: 4px;
  display: flex;
  align-items: center;
  gap: 0 13.5px;
  color: #5a6269;
`;

const InformationText = styled.div`
  margin-top: 18px;
  margin-left: 10px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  line-height: 1.79;
  color: #8b9196;
`;

const StyledDropArea = styled(DropArea)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const IconContainer = styled.div`
  margin-bottom: 27px;  
`;

const InputButtonWrapper = styled.div`
  z-index: 99;
  margin-top: 20px;
`;

const Title = styled.div`
  color: hsl(208, 8%, 38%);
  font-size: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 2.14;
  text-align: center;
  color: hsl(207, 5%, 57%);
  margin-top: 10px;
  max-width: 386px;
`;

const CsvUploadContainer = styled.div<{isDarkTheme: boolean}>`
  width: 100%;
  padding: 55px 0px 42px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 5px;
  position: relative;
  ${({isDarkTheme}) => isDarkTheme ? css`
    box-shadow: rgb(6 21 32 / 7%) 0px 4px 9px 0px;
    background-color: rgb(44, 46, 48);
    border: 1px solid rgb(61, 61, 67);
  ` : css`
    box-shadow: rgb(39 118 197 / 12%) 0px 5px 25px 0px;
    background-color: rgb(230, 230, 230);
    border: 1px solid rgb(231, 231, 231);
  `}
`;

const DownloadButton = styled(ButtonWithIcon)`
  margin-top: 27px;
`;

const PrepareFileContainer = styled.div`
  margin-top: 27px;
  padding: 25px 126px 28px 30px;
  border-radius: 5px;
  border: solid 1px ${({ theme }) => theme.colors.divider};
`;

const SelectTab = styled.div`
  margin-top: 85px;
`;

interface IProps {
  onFileSelect: (file: File) => void
}

const SelectFileTab: FC<IProps> = ({onFileSelect}) => {
  const { t } = useTranslation(['NumberPlateImport', 'NumberPlates', 'Common']);
  const isDarkTheme = !localStorage.getItem('isDarkThemeEnabled') ? true : localStorage.getItem('isDarkThemeEnabled') === 'true';

  const onFileChange = useCallback((files: FileList) => {
    onFileSelect(files[0]);
  }, [onFileSelect]);

  return (
    <SelectTab>
      <CsvUploadContainer {...{isDarkTheme}}>
        <StyledDropArea dropCallback={onFileChange} />
        <IconContainer>
          <Icon icon='FileTypesCsv' color='dimmed' size={72} />
        </IconContainer>
        <Title>{t('selectCsvFile')}</Title>
        <Description>{t('importCsvDescription')}</Description>

        <InputButtonWrapper>
          <InputFileButton
            buttonSize='small'
            text={t('selectCsvFile')}
            accept='.csv'
            inputCallback={onFileChange}
            buttonDesign='primary'
          />
        </InputButtonWrapper>
      </CsvUploadContainer>
      <PrepareFileContainer>
        <Heading>
          <Icon icon='Question' color='dimmed' size={23} />
          <HeadingText>{t('howToPrepareFile')}</HeadingText>
        </Heading>
        <InformationText>{t('csvPrepareInfo')}</InformationText>
        <DownloadButton icon='Download' position='left' design='secondary' onClick={() => {window.location.href = SAMPLE_FILE;}}>{t('downloadExampleCsv')}</DownloadButton>
      </PrepareFileContainer>
    </SelectTab>
  )
}

export default SelectFileTab;
