import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Content,
  FilterBar,
  IFilterDropdownConfig,
  PageHeader,
  TypeTable,
  ActionButtons,
  IconButtonData,
  IFilterResult,
  IFilterItem,
  ButtonWithIcon,
  useTo,
  ISearchFilter,
  useNotification,
  useModal,
  ConfirmationModal
} from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import Pagination from '../components/Pagination';
import { INITIAL_ROWS } from '../constants';
import { getSelected, readParams, updateParams } from '../utils';
import { useHistory, useLocation } from 'react-router-dom';
import { ISelectedFilterValues } from './KnownPeopleDetection';
import { IFetchLogicGroupsOptions, useLogicGroups } from '../hooks/useLogicGroups';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    max-width: 610px !important;
  }
`;

const FilterWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
`;

const StyledBtnWithIcon = styled(ButtonWithIcon)`
  margin-bottom: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const initialFilterValues: ISelectedFilterValues = {
  status: undefined,
  sortDirection: 'asc',
  sortBy: 'name',
  paramsLoaded: false,
  groupName: undefined
}

const sizeOptions = [1, 10, 20, 50, 100];

const RelayLogicGroups: React.FC = () => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const { logicGroups, logicGroupsLoading, totalGroups, actions: { fetchLogicGroups, deleteLogicGroup } } = useLogicGroups();
  const { sendNotification } = useNotification();

  const params = useLocation().search;
  const [historyParams] = useState(params);

  const to = useTo();

  const { createModal } = useModal();

  const [dropdownConfig, setDropdownConfig] = useState<IFilterDropdownConfig[]>();

  const { push } = useHistory();
  const { t } = useTranslation(['RelayLogicGroups', 'Common']);

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };

  const tableColumns: ITableColumnConfig[] = [
    {
      groupTitle: t('group'),
      header: t('id'),
      cellStyle: 'firstColumn',
    },
    {
      columnId: 'name',
      groupTitle: t('group'),
      header: t('name'),
      cellStyle: 'firstColumn',
      sortable: true,
      sortActive: selectedFilterValues.sortBy ?  (selectedFilterValues.sortBy === 'name') : false
    },
    {
      groupTitle: t('group'),
      header: t('status'),
      cellStyle: 'lowImportance',
    },
    // {
    //   groupTitle: t('links'),
    //   header: t('cameras'),
    //   cellStyle: 'normalImportance',
    // },
    {
      groupTitle: t('links'),
      header: t('actions'),
      cellStyle: 'normalImportance',
    },
    // {
    //   groupTitle: t('assigned'),
    //   header: t('numberPlates'),
    //   cellStyle: 'normalImportance',
    // },
    {
      header: '',
      cellStyle: 'normalImportance'
    }
  ];

  const getTypesList = useCallback(() => {
    return [
      {
        text: t('enabled'),
        value: 'enabled'
      },
      {
        text: t('disabled'),
        value: 'disabled'
      }
    ];
  }, [t]);

  const searchers: ISearchFilter[] = [
    {
      id: 'groupName',
      placeholder: t('filterByName'),
    }
  ];

  const getDropdownConfig = useCallback(() => {

    return ([
      {
        id: 'status',
        buttonText: t('status'),
        list: getTypesList(),
        buttonIcon: 'MetaEnabledState',
        optionType: 'radio',
        selected: getSelected(selectedFilterValues.status)
      }
    ]);
  }, [t, selectedFilterValues, getTypesList]);

  useEffect(() => {
    setSelectedFilterValues(readParams(historyParams, initialFilterValues));
  }, [historyParams]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    push({ search: updateParams(selectedFilterValues) });
  }, [push, selectedFilterValues]);

  useEffect(() => {
    setDropdownConfig(getDropdownConfig() as IFilterDropdownConfig[]);
  }, [getDropdownConfig, selectedFilterValues]);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);

  const getApiParams = useCallback((): IFetchLogicGroupsOptions => {

    const { status, sortDirection = 'asc', sortBy = 'name', groupName } = selectedFilterValues;
    return ({
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      active: status !== undefined ? status === 'enabled' : undefined,
      name: groupName as string,
      sortBy: sortBy as string,
      sortDirection: sortDirection as string
    });

  }, [pageSize, currentPage, selectedFilterValues]);

  const handleDelete = useCallback(async (id: number) => {
    const result = await deleteLogicGroup(id);
    if (result) {
      sendNotification({ type: 'success', message: t('result.deleteSuccess') })
      fetchLogicGroups(getApiParams());
    } else {
      sendNotification({ type: 'error', message: t('result.deleteError') })
    }
  }, [deleteLogicGroup, fetchLogicGroups, getApiParams, sendNotification, t]);

  const openConfirmationModal = useCallback((eventId: number) => {
    createModal({
      isCloseEnable: false,
      customComponent: (
        <ConfirmationModal
          title={t('confirmPipelineDeletion')}
          message={t('deletePipelineMsg')}
          leftButtonText={t('Common:cancel')}
          leftButtonDesign='secondary'
          rightButtonText={t('Common:delete')}
          rightButtonDesign='danger'
          rightButtonCallback={() => handleDelete(eventId)}
        />
      )
    });
  }, [createModal, t, handleDelete]);

  const generateConfigButtons = useCallback((id: number): IconButtonData[] => {
    return (
      [
        {
          icon: '',
          onClick: () => { },
        },
        {
          icon: 'Edit',
          onClick: to(`/event-pipeline/${id}/edit`),
        },
        {
          icon: 'Delete',
          onClick: () => openConfirmationModal(id),
        }
      ]
    )
  }, [to, openConfirmationModal]);

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = (logicGroups ?? []).map((relayLogic) => {

      const {
        id,
        name,
        active,
        rules: _,
        unique_actions
      } = relayLogic;

      const row: IRowData = {
        columns: [
          { text: `${id}` },
          { text: name || `[${t('noneSet')}]`, href: `/event-pipeline/${id}/edit` },
          { text: t(active ? 'enabled' : 'disabled') },
          // { text: `${cameras}` },
          { text: `${unique_actions}` },
          // { text: `${numberplates}` },
          { customComponent: <ActionButtons buttonsConfig={generateConfigButtons(id)} /> },
        ]
      };
      return row;
    });
    return data.length ? data : INITIAL_ROWS;
  }, [t, generateConfigButtons, logicGroups]);

  useEffect(() => {
    if (logicGroupsLoading) return;
    setRowData(generateRowData());
  }, [generateRowData, logicGroupsLoading]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    fetchLogicGroups(getApiParams());
  }, [getApiParams, fetchLogicGroups, pageSize, currentPage, selectedFilterValues]);

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {
    setCurrentPage(1);
    const selectedValues: ISelectedFilterValues = res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {

      switch (id) {
        case 'status':
          selectedValues[id] = (selected as IFilterItem).value as string;
          break;
        case 'groupName':
          selectedValues[id] = (selected as IFilterItem).value as string;
          break;
        default:
          selectedValues[id] = (selected as IFilterItem[]).map(({ value }) => value).join(',');
      }
      return selectedValues;
    },
      { ...initialFilterValues, paramsLoaded: selectedFilterValues.paramsLoaded }
    );
    setSelectedFilterValues({ ...selectedValues });
  }, [selectedFilterValues]);

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrentPage(parseInt(val));
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(totalGroups / pageSize));
  }, [totalGroups, pageSize]);

  return (
    <Container>
      <PageHeaderWrapper>
        <PageHeader icon='DevicesScorerEdge' title={t('relayLogicGroups')} areaTitle={t('settings')} areaHref='#' introductionText={t('introText')} />
        <ButtonGroup>
          <StyledBtnWithIcon icon='Add' position='left' size='small' onClick={to('/event-pipeline/add')}>{t('addNew')}</StyledBtnWithIcon>
        </ButtonGroup>

      </PageHeaderWrapper>

      <FilterWrapper>
        {dropdownConfig &&
          <FilterBar
            searchersConfig={searchers}
            dropdownsConfig={dropdownConfig}
            filtersTitle={t('Common:filter.filters') + ':'}
            resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
            totalResults={rowData.length === 1 && rowData[0].columns.length === 0 ? 0 : rowData.length}
            onChangeCallback={onChangeFilterValue}
            clearText={t('Common:filter.clearAll')}
          />}
      </FilterWrapper>

      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
        isLoading={logicGroupsLoading}
        emptyTableText={t('noRelayLogicGroupsFound')}
        loadingText={t('Common:loadingData')}
        sortCallback={sortCallback}
        hasHeaderGroups
        hasTypeIcon
        defaultAscending={ selectedFilterValues.sortDirection === 'asc' ? true : false }
      />

      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={sizeOptions}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}

    </Container>
  );
};

export default RelayLogicGroups;