import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import L from 'leaflet';
import {
  MapContainer,
  ImageOverlay,
  Pane,
} from 'react-leaflet';

import {
  camerasZIndex,
  mapOverlayZIndex,
  ZOOM_LAYER3,
  ZOOM_LAYER2,
} from '../mapSetup';

import CameraMarks from '../molecules/CameraMarks';
import { Camera } from '../../../hooks/useCameras';
import { CameraAlertCount } from '../../../hooks/useAlertCount';
import { LAYER } from '../atoms/MapZoomListener';
import MapHoverProvider from '../../../context/MapHoverContext';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { useZone } from '../../../hooks/useZone';
import { MapSettings } from '../../../types';
import { getMapBounds, getZoneBounds } from '../mapUtils';

const MapWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  #map {
    height: 100%;
    width: 100%;
    background-color: hsl(228, 71%, 99%);
  }
`;

// https://leafletjs.com/reference.html#map-pane

const MapPane = styled(Pane)`
  z-index: ${mapOverlayZIndex};
`;

const CamerasPane = styled(Pane)`
  z-index: ${camerasZIndex};
`;

const FullMapHiddenImage = styled.img`
  display: none;
`;

const { REACT_APP_BUILD_VERSION=Date.now() } = process.env;
interface IOneCampMap {
  camera: Camera
  alertCounts: CameraAlertCount,
}

const OneCamMap: React.FC<IOneCampMap> = ({ camera, alertCounts }) => {
  const [defaultMapSettings, setDefaultMapSettings] = useState<MapSettings | null>(null);
  const { updateParam } = useSelected();
  //**Zone fetch might be refactored if sent from parent component */
  const { zone, actions: { fetchZone } } = useZone();
  const { id: cameraId, zone_id: zoneId, position_x = 0, position_y = 0 } = camera;
  const fullMapImgRef = useRef<HTMLImageElement>(null);

  /** set layer 3 on mount */
  useEffect(() => {
    updateParam(DASHBOARD_PARAMS.layer, LAYER.cameras);
    updateParam(DASHBOARD_PARAMS.cameraId, cameraId);
    updateParam(DASHBOARD_PARAMS.zoneId, zoneId);
  }, [cameraId, updateParam, zoneId]);

  useEffect(() => {
    fetchZone(zoneId)
  }, [fetchZone, zoneId])

  const setDefaults = useCallback(() => {
    if (fullMapImgRef.current && fullMapImgRef.current.height !== 0 && zone) {
      const { height, width } = fullMapImgRef.current;
      const mapBounds = getMapBounds(height, width);
      const zoneBounds = getZoneBounds(zone.points);

      setDefaultMapSettings({
        bounds: mapBounds,
        maxBounds: zoneBounds,
        center: [-position_y, position_x],
        zoom: ZOOM_LAYER3,
        minZoom: ZOOM_LAYER2,
        maxZoom: ZOOM_LAYER3
      })
    }
  }, [position_x, position_y, zone]);

  /** Adding the default map setting on mount */
  useEffect(() => {
    setDefaults()
  }, [setDefaults])

  return (
    <MapHoverProvider>
      <FullMapHiddenImage ref={fullMapImgRef} onLoad={setDefaults} src='/data/maps/tbe-main-map.jpg' />
      <MapWrapper>
        {defaultMapSettings && zone && zone.image && (
          <MapContainer
            id="map"
            crs={L.CRS.Simple}
            center={defaultMapSettings.center}
            zoom={defaultMapSettings.zoom}
            minZoom={defaultMapSettings.minZoom}
            maxZoom={defaultMapSettings.maxZoom}
            maxBounds={defaultMapSettings.maxBounds}
            bounds={defaultMapSettings.bounds}
            scrollWheelZoom={false}
            attributionControl={false}
          >
            <MapPane name='map-pane'>
              {zone && (
                <ImageOverlay
                  url={`${zone.image}?hostname=${REACT_APP_BUILD_VERSION}`}
                  bounds={defaultMapSettings.maxBounds}
                />
              )}
            </MapPane>
            <CamerasPane name='cameras-pane'>
              <CameraMarks {...{ alertCounts }} cameras={[camera]} />
            </CamerasPane>
          </MapContainer>
        )}
      </MapWrapper>
    </MapHoverProvider>
  );
}

export default OneCamMap;