import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Icon, IntroductionText, Label, Spinner, useTo } from 'scorer-ui-kit';

import styled from 'styled-components';
import { IImportCount } from '../../pages/NumberPlateImport';

const Container = styled.div`
  width: 100%;
  margin-top: 111px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled(Label)`
  font-size: 20px;
  margin: 23px 0 25px;
`;

const InfoContainer = styled.div`
  width: 387px;
  > p {
    text-align: center;
    line-height: 1.79;
    margin: 0;
  }
`;

const IntroductionTextWrapper = styled(InfoContainer)`
  margin-bottom: 25px;
`;

const SpinnerContainer = styled.div`
  > svg {
    width: 64px;
    height: 64px;
  }
`;

const ReturnButton = styled(Button)`
  margin-top: 37px;
`;

const TickIconBox = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background-color: #87d58f;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
  }
`;

const ErrorIconBoxDanger = styled(TickIconBox)`
  background-color: #de6b6b;
`;

interface IProps {
  loadingImport: boolean,
  errorMessage: string,
  importCount: IImportCount
}

const ProcessTab: FC<IProps> = ({loadingImport, errorMessage = '', importCount}) => {
  const { t } = useTranslation(['NumberPlateImport', 'NumberPlates', 'Common']);
  const to = useTo();

  const renderProcessedElement = useCallback(() => {
    return (
      !errorMessage ? <>
        <TickIconBox>
          <Icon weight='regular' icon='Success' color='inverse' size={36} />
        </TickIconBox>
        <Title htmlFor='' labelText={t('successProcessing')} />
        <InfoContainer>
          <IntroductionText>{t('SuccessIntroText').replace('{imported}', `${importCount.imported}`).replace('{total}', `${importCount.total}`)}</IntroductionText>
        </InfoContainer>
        <ReturnButton size='small' design='secondary' onClick={to('/number-plates')}>{t('returnBtnText')}</ReturnButton>
      </> : <>
        <ErrorIconBoxDanger>
          <Icon weight='regular' icon='Warning' color='inverse' size={36} />
        </ErrorIconBoxDanger>
        <Title htmlFor='' labelText={t('errorProcessing')} />
        <IntroductionTextWrapper>
          <IntroductionText>{t('failureIntroText')}</IntroductionText>
        </IntroductionTextWrapper>
        <InfoContainer>
          <IntroductionText>{t('systemErrorMessage')}</IntroductionText>
          <IntroductionText>{`[${errorMessage}]`}</IntroductionText>
        </InfoContainer>
      </>
    );
  }, [importCount, errorMessage, t, to]);

  return (
    <Container>
      {loadingImport ? <>
        <SpinnerContainer>
          <Spinner size='xlarge' styling='primary' />
        </SpinnerContainer>
        <Title htmlFor='' labelText={t('nowProcessing')} />
        <InfoContainer>
          <IntroductionText>{t('processingIntoText')}</IntroductionText>
        </InfoContainer>
      </> : renderProcessedElement()}
    </Container>
  )
}

export default ProcessTab;