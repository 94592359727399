import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Checkbox, IFilterValue, SelectField, SmallInput } from 'scorer-ui-kit';

import { INodeTargets, INodeOptionSet, INodeOptionSetOptions, IPipelineNodeCore } from '..';
import { useTranslation } from 'react-i18next';
import { AgeList, GenderList } from '../../../constants';
import { FilterDropdown } from '../../FilterDropdown';

const NodeButtonRow = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 10px;

  button {
    margin-left: 10px;
  }
`

const InlineLabel = styled.div`

  font-family: ${({theme}) => theme.fontFamily.ui};
  font-size: 14px;
  color: #888;
  line-height: 30px;

  > div, > label {
    display: inline-block;
    margin-bottom: -5px;
    margin-right: 10px;
  }
`

const MultiSelectDropdown = styled(FilterDropdown)`
  height: 30px;
  margin-top: 5px;
  margin-bottom:30px;

  > div > div {
    > div{
    width: 234px;
    max-height: 180px;
     > div> div{
       max-height: 166px;
   }
}
  
  button{
    height: 30px;
    width: 234px;
    font-size: 16px !important;
    background-color: hsla(210,4.3%,18%,1.000) !important;
    border-color: hsla(210,4.3%,18%,1.000) !important;
    color: #8B9196!important;

    > div > div{
      display: flex;
      justify-content: flex-start;
      margin-left: 5px;
      font-style: normal;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      line-height: 15px;
      -webkit-text-decoration: none;
      text-decoration: none;
    }
    > div > div:last-child {
      position: absolute;
      top: 12px;
      left: 195px;
    }
  }
}
  @media (max-width: 1660px) {
    > div > div {
      >div{
        width: 200px;
      }
      button {
      width: 200px;
      font-size: 14px;

      > div > div {
        font-size: 14px;
      }

      > div > div:last-child {
        left: auto;
        right: 12px;
      }
    }
    }

  }
`;

const DisplayText = styled.div`
  display: flex;
`;

export const ValueText = styled.div`
  font-family: ${({theme}) => theme.fontFamily.data};
  font-weight: 500;
  color: rgb(120, 139, 145);
  text-transform: titlecase;
  font-size: 12px;
  line-height: 14px;
  margin-top: 2px;
  margin-left: 2px;
`;

const LabelText = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.data};
  color: hsl(207,5%,57%);
  font-size: 14px;
  font-weight: 500;
`;

interface INodeSectionEdit {
  type: INodeTargets;
  index: number;
  config?: INodeOptionSet[];
  configFields?: IPipelineNodeCore['data']['configFields'],
  handlerCancel?: any
  handlerUpdate?: any
  externalData: {[key:string]: any[]}
}

export interface ISelectProps {
  text:string;
  value:string;
}

const NodeSectionEdit : React.FC<INodeSectionEdit> = ({type, index, config, configFields=[], handlerCancel, handlerUpdate, externalData}) => {
  const { t } = useTranslation(['Common']);
  const [newConfigFields, setNewConfigFields] = useState<IPipelineNodeCore['data']['configFields']>(configFields);
  const [selectedAgeOptions, setSelectedAgeOptions] = useState<ISelectProps[]>([]);
  const [selectedGenderOptions, setSelectedGenderOptions] = useState<ISelectProps[]>([]);

  useEffect(()=>{
    if(config && config[0]?.optionsSource ==='age' && config[1]?.optionsSource ==='gender'){
      const ageData: ISelectProps[] = configFields[0]?.map((item:any) => ({
        text: item,
        value: item,
      }));
      const genderData: ISelectProps[] = configFields[1]?.map((item:any) => ({
        text: item,
        value: item,
      }));

      if(configFields && configFields[0]?.includes('All Ages')){
        setSelectedAgeOptions(AgeList);
      }else{
        setSelectedAgeOptions(ageData ? ageData :[]);
      }if(configFields && configFields[1]?.includes('All Genders')){
        setSelectedGenderOptions(GenderList)
      }else{
        setSelectedGenderOptions(genderData ? genderData : []);
      }
    }
  },[configFields, config])

  const updateKeyValue = useCallback((index: number, value : any) => {
    setNewConfigFields((oldConfigFields=[] )=> {
      oldConfigFields[index] = value;
      return [...oldConfigFields]
    });
  },[]);

  useEffect(() =>{
    if(selectedAgeOptions?.length !== 0 || selectedGenderOptions?.length !== 0 ){
      setNewConfigFields((oldConfigFields = []) => {
        const ageValue = (selectedAgeOptions as ISelectProps[])?.map((selection: any) => selection.value);
        const genderValue = (selectedGenderOptions as ISelectProps[])?.map((selection: any) => selection.value);
        if (ageValue && ageValue.includes('All Ages')) {
          oldConfigFields[0] = ['All Ages'];
        }else{
          oldConfigFields[0] = ageValue;
        } 
        if (genderValue && genderValue.includes('All Genders')) {
          oldConfigFields[1] = ['All Genders'];
        } else {
          oldConfigFields[1] = genderValue;
        }
    
        return [...oldConfigFields];
      });
    }
  },[selectedAgeOptions, selectedGenderOptions]);

const handleSelect = (newSelection: IFilterValue, optionIndex: number, optionsSource: string) => {
  const newSelectionValues = (newSelection as ISelectProps[])?.map(item => item.value);
  const allValuesExist = AgeList?.filter(item => item.value !== 'All Ages')?.every(item => newSelectionValues?.includes(item.value));
  const allGenderExist = GenderList?.filter(item => item.value !== 'All Genders')?.every(item => newSelectionValues?.includes(item.value));

  if (optionsSource === 'age') {
    if((( selectedAgeOptions as ISelectProps[])?.every((option) => option.value !== 'All Ages') ) && ( newSelection as ISelectProps[])?.some((option) => option.value === 'All Ages')){
      setSelectedAgeOptions(AgeList);
    }else{
      if (( ((!selectedAgeOptions?.length) ||( selectedAgeOptions as ISelectProps[])?.some((option) => option.value !== 'All Ages') ) && ( newSelection as ISelectProps[])?.some((option) => option.value === 'All Ages')) && ((newSelection  as ISelectProps[])?.length !== AgeList.length-1)) {
        setSelectedAgeOptions(AgeList);
      } else {
        if( allValuesExist && !(selectedAgeOptions as ISelectProps[])?.some((option) => option.value === 'All Ages') && ( newSelection as ISelectProps[])?.some((option) => option.value !== 'All Ages')){
          setSelectedAgeOptions(AgeList);
        }else if(!allValuesExist ){
          setSelectedAgeOptions((newSelection as ISelectProps[])?.filter(item => item.value !== 'All Ages'));
        }
        else if((selectedAgeOptions as ISelectProps[])?.some((option) => option.value === 'All Ages') && ( newSelection as ISelectProps[])?.some((option) => option.value !== 'All Ages')){
        setSelectedAgeOptions([]);
        }
        }
      }
    }

   else if (optionsSource === 'gender') {
    if((( selectedGenderOptions as ISelectProps[])?.every((option) => option.value !== 'All Genders') ) && ( newSelection as ISelectProps[])?.some((option) => option.value === 'All Genders')){
      setSelectedGenderOptions(GenderList);
    }else{
      if ( (((!selectedGenderOptions?.length) ||( selectedGenderOptions as ISelectProps[])?.some((option) => option.value !== 'All Genders') ) && ( newSelection as ISelectProps[])?.some((option) => option.value === 'All Genders')) && ((newSelection  as ISelectProps[])?.length !== GenderList?.length-1) ) {
        setSelectedGenderOptions(GenderList);
      } else {
        if(allGenderExist && !(selectedGenderOptions as ISelectProps[])?.some((option) => option.value === 'All Genders') && ( newSelection as ISelectProps[])?.some((option) => option.value !== 'All Genders')){
          setSelectedGenderOptions(GenderList);
          }else if(!allGenderExist ){
            setSelectedGenderOptions((newSelection as ISelectProps[])?.filter(item => item.value !== 'All Genders'));
          }
          else if((selectedGenderOptions as ISelectProps[])?.some((option) => option.value === 'All Genders') && ( newSelection as ISelectProps[])?.some((option) => option.value !== 'All Genders')){
            setSelectedGenderOptions([]);
          }
        }
    }
  }
};

  const createSelectField = (config : any, selectedValue: any, optionIndex: number) => {
    const {name, options, label, optionsSource} = config;
    let overrideOptions :{label: string,value: (string|number)}[]= [];
    if(optionsSource && externalData[optionsSource] && externalData[optionsSource].length > 0){
      //Watch out for now we assume this will always be a string
      overrideOptions = externalData[optionsSource].map(({name=''}:any)=>({label:name, value:name}));
    }
    return (
    <>
    { optionsSource!=='age' && optionsSource!=='gender' ?
      <SelectField key={'option-' + optionIndex} defaultValue={selectedValue} isCompact label={{text: label, htmlFor: 'Age'}} placeholder={t('eventRules.selectConditionType')} changeCallback={ (value) => updateKeyValue(optionIndex, value) }>
        {(overrideOptions.length ? overrideOptions :options).map((option : INodeOptionSetOptions, index : number) => {
          return <option key={'select-' + name + index} value={option.value}>{option.label}</option>
        })}
      </SelectField>
           : 
          <>
          <DisplayText>
            <LabelText>{optionsSource==='age' ? t('Age') : t('Gender')}:</LabelText>
            <ValueText>
             {optionsSource === 'age' ? newConfigFields && newConfigFields[0]?.map((item: string) => t(`${item}`)).join(', ') : newConfigFields && newConfigFields[1]?.map((item: string) => t(`${item}`)).join(', ')}</ValueText>
          </DisplayText>
          <MultiSelectDropdown
            buttonIcon=""
            buttonText={optionsSource === 'age' ? t('Select Age') : t('Select Gender')}
            list={optionsSource === 'age' ? AgeList.map((item) => ({text:t(`${item.text}`), value: item.value})) : GenderList.map((item) => ({text:t(`${item.text}`), value: item.value}))}
            onSelect={(selectedOptions) => handleSelect(selectedOptions, optionIndex, optionsSource)}
            optionType="checkbox"
            selected={optionsSource === 'age' ? selectedAgeOptions : selectedGenderOptions}
            maxDisplayedItems={9}
          />
          </>
    }
    </>
    )
  }

  const createTextField = (config : any, value: any ,optionIndex: number) => {
    const {label} = config;
    return (
      <SmallInput key={'option-' + optionIndex} type='text' defaultValue={value} {...{label}} onChange={ (event) => updateKeyValue(optionIndex, event.target.value) } />
    )
  }

  const createCheckboxField = (config : any, value: any,optionIndex: number) => {
    const {label} = config;

    return (
      <InlineLabel key={'option-' + optionIndex}>
        <Checkbox checked={value} onChangeCallback={ (value) => { updateKeyValue(optionIndex, value) } }/>
        {label}
      </InlineLabel>
    )
  }


  return (
    <>
      {config?.map((conf, i) => {
        const {fieldType} = conf;
        const value = (newConfigFields||[])[i];
        switch(fieldType){
          case 'select':
            return createSelectField(conf, value, i);
          case 'text':
            return createTextField(conf, value, i);
          case 'checkbox':
            return createCheckboxField(conf, value, i);
          default:
            throw new Error();
        }
      })}

      <NodeButtonRow>
        {handlerCancel ? <Button size='xsmall' design='secondary' onClick={ () => handlerCancel(type, index, false)}>{t('cancel')}</Button> : null}
        {handlerUpdate ? <Button size='xsmall' onClick={ () => handlerUpdate({target: type, index, config, overview: newConfigFields?.[0] || '', configFields: newConfigFields})}>{t('save')}</Button> : null}
      </NodeButtonRow>
    </>
  )

}

export default NodeSectionEdit;