import { FC } from 'react';
import { Icon } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';
import { RichZone } from '../../pages/Dashboard';
import { AlertsCountWithIcon } from './AlertsCountWithIcon';

const Container = styled.div`
  padding: 5px 17px 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TextContainer = styled.div<{ isLastRow: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 13px;
  ${({ theme, isLastRow }) => !isLastRow && css`
    border-bottom: 1px solid ${theme.colors.divider};
  `};
  padding-bottom: 5px;
  overflow: hidden;
`;

const ZoneName = styled.div`
  width: max-content;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.09px;
  color: #767676;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  :hover {
    color: hsl(205, 100%, 65%);
  }
`;

const DetectionTypes = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 12px;
  font-style: italic;
  line-height: 1.67;
  letter-spacing: 0.15px;
  color: #949ea7;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  margin-right: 22px;
  white-space: nowrap;
  overflow: hidden;
`;

interface IZoneRow extends RichZone {
  totalZones: number,
  index: number,
  handleZoneClick: () => void
}

const ZoneRow: FC<IZoneRow> = ({ name = '-', detectionTypes = '-', totalZones, index, zoneAlerts = 0, handleZoneClick }) => {

  return (
    <Container>

      <LeftSection>
        <Icon icon='Zone' size={12} color='dimmed' />
        <TextContainer isLastRow={index === (totalZones - 1)}>
          <ZoneName title={name!} onClick={handleZoneClick}>{name}</ZoneName>
          <DetectionTypes>{detectionTypes}</DetectionTypes>
        </TextContainer>
      </LeftSection>

      <AlertsCountWithIcon count={zoneAlerts} />

    </Container>
  )
};

export default ZoneRow;