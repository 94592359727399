import { useContext } from 'react';
import {FeatureList, FeaturesContext} from '../../context/FeaturesContext';

interface IRenderFeature {
  enabledFeatures?: FeatureList[];
}
const FeatureCondition: React.FC<IRenderFeature> = ({ enabledFeatures = [], children }) => {
  const { features } = useContext(FeaturesContext);

  return (
    <>
      {enabledFeatures.some(feature => features[feature] === true) ? children : null}
    </>
  )
}

export default FeatureCondition;