import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Icon, IntroductionText, Label, TagList, TypeTable } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import { INITIAL_ROWS } from '../../constants';
import styled from 'styled-components';
import { IEntries } from './CheckAndConfirmTab';
import { getCategoTagList, getMetaDetection } from '../../utils';
import Pagination from '../Pagination';
import LongTextCell from '../atoms/LongTextCell';
import { EllipsisStyles } from '../../Style';

const TabHeader = styled.div`
  margin: 45px 0;
  max-width: 610px;
`;

const TitleContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  left: -30px;
`;

const Title = styled(Label)`
  font-size: 16px;
  margin-bottom: 0;
`;

const IntroductionContainer = styled.div`
  > p {
    font-style: normal;
    line-height: 1.79;
    margin: 0;
  }
`;

const TagListWrapper = styled.div`
  div {
    border: none;
    > div > div:last-child {
      max-width: 75px;
      ${EllipsisStyles}
    }
  }
  max-height: 126px;
  padding-top: 5px;
  overflow-y: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const ErrorIconBox = styled.div`
  width: 16px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #de6b6b;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
  }
`;

interface IProps {
  entries: IEntries[],
  tab: string,
  icon: string,
  title: string,
  introduction: string,
  loadingTableData: boolean,
  isSelectable?: boolean,
  onEntriesSelect?: (toggleAll: boolean, tab: string, checked: boolean, id?: string | number) => void;
}

const sizeOptions = [1, 10, 20, 50, 100];

const Entries: FC<IProps> = ({entries = [], tab, title, introduction, icon, loadingTableData, isSelectable = false, onEntriesSelect = () => {}}) => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { t } = useTranslation(['NumberPlateImport', 'NumberPlates', 'Common']);

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };

  const tableColumns: ITableColumnConfig[] = [
    {
      header: t('reference'),
      sortable: false,
      cellStyle: 'firstColumn',
      columnId: 'name',
      minWidth: 100
    },
    {
      header: t('NumberPlates:type'),
      sortable: false,
      cellStyle: 'normalImportance',
      columnId: 'type',
      minWidth: 100
    },
    {
      groupTitle: t('NumberPlates:numberPlate'),
      header: t('location'),
      sortable: false,
      cellStyle: 'normalImportance',
      columnId: 'area',
      minWidth: 60
    },
    {
      groupTitle: t('NumberPlates:numberPlate'),
      header: t('class'),
      sortable: false,
      cellStyle: 'normalImportance',
      columnId: 'user_type',
      minWidth: 60,
    },
    {
      groupTitle: t('NumberPlates:numberPlate'),
      header: t('kana'),
      sortable: false,
      cellStyle: 'normalImportance',
      columnId: 'kana',
      minWidth: 60
    },
    {
      groupTitle: t('NumberPlates:numberPlate'),
      header: t('number'),
      sortable: false,
      cellStyle: 'normalImportance',
      columnId: 'number',
      minWidth: 100,
    },
    {
      header: t('NumberPlates:categoryAndTags'),
      sortable: false,
      cellStyle: 'normalImportance',
      columnId: 'categorayTags',
      minWidth: 100,
    },
    {
      groupTitle: t('validation'),
      header: t('data'),
      sortable: false,
      cellStyle: 'normalImportance',
      columnId: 'data',
      minWidth: 20,
      alignment: 'center'
    },
    {
      groupTitle: tab !== 'deletedEntries' ? t('validation') : '',
      header: t('status'),
      sortable: false,
      cellStyle: 'normalImportance',
      columnId: 'status',
      minWidth: 80,
      alignment: 'right'
    },
  ];
  const tabTableColumns = tab !== 'deletedEntries' ? tableColumns : tableColumns.filter(item => item.columnId !== 'data');
  
  const generateRowData = useCallback((): ITypeTableData => {

    const data: ITypeTableData = entries.map((entrie, index) => {

      const {
        name = '-',
        type = '-',
        user_type = '-',
        kana = '-',
        number = '-',
        category,
        tags,
        area = '-',
        checked = false,
        status = '-'
      } = entrie;

      const row: IRowData = {
        id: index,
        _checked: checked,
        columns: [
          { customComponent: !name ? <div>-</div> : <LongTextCell text={name} maxWidth='110px' /> },
          { text: !type ? '-' : getMetaDetection(type) },
          { customComponent: !area ? <div>-</div> : <LongTextCell text={area} maxWidth='75px' /> },
          { customComponent: !user_type ? <div>-</div> : <LongTextCell text={user_type} maxWidth='75px' /> },
          { customComponent: !kana ? <div>-</div> : <LongTextCell text={kana} maxWidth='75px' /> },
          { customComponent: !number ? <div>-</div> : <LongTextCell text={number} maxWidth='110px' /> },
          {
            customComponent: (
              tags && category
                ? (
                  <TagListWrapper title={`${category}   ${tags}`}>
                    <TagList tagsConfig={getCategoTagList(category as string, tags as string)} />
                  </TagListWrapper>
                )
                : <div>-</div>
            )
          },
        ...(tab !== 'deletedEntries') ? [{ 
          customComponent: tab === 'failedEntries' ?
          <ErrorIconBox><Icon weight='regular' icon='Exclamation' color='inverse' size={16} /></ErrorIconBox>
          :  <Icon size={16} icon='Success' color='dimmed' />
        }] : [],
          { text: !status ? '-' : status }
        ]
      };
      return row;
    });
    return data.length ? data : INITIAL_ROWS;
  }, [entries, tab]);

  useEffect(() => {
    if (loadingTableData) return;
    const paginatedData = generateRowData().slice((pageSize*(currentPage-1)), pageSize*currentPage);
    setRowData(paginatedData);
  }, [entries, loadingTableData, currentPage, pageSize, generateRowData]);

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrentPage(parseInt(val));
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(entries.length / pageSize));
  }, [entries, pageSize]);

  const onSelect = useCallback((checked: boolean, toggleAll: boolean, id?: string | number) => {
    if (toggleAll) {
      onEntriesSelect(toggleAll, tab, checked);
    }
    if (id === undefined) return;
    onEntriesSelect(toggleAll, tab, checked, id);
  }, [tab, onEntriesSelect]);

  return (
    <>
      <TabHeader>
        <TitleContainer>
          <IconContainer>
            <Icon icon={icon} size={18} />
          </IconContainer>
          <Title htmlFor='' labelText={title} />
        </TitleContainer>
        <IntroductionContainer>
          <IntroductionText>{introduction}</IntroductionText>
        </IntroductionContainer>
      </TabHeader>

      <TypeTable
        columnConfig={tabTableColumns}
        rows={rowData}
        isLoading={loadingTableData}
        emptyTableText={t('noEntriesAvailable')}
        loadingText={t('Common:loadingData')}
        hasHeaderGroups
        selectCallback={(checked, id) => onSelect(checked, false, id)}
        toggleAllCallback={(checked) => onSelect(checked, true)}
        selectable={isSelectable}
      />

      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={sizeOptions}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}
    </>
  )
}

export default Entries;