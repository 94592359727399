import React, { useState, useCallback, FC } from 'react';
import { Icon } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { RichLocation } from '../../pages/Dashboard';
import { AlertsCountWithIcon, CountWithIcon } from './AlertsCountWithIcon';
import ZoneRow from './ZoneRow';
import { getDetectionTypesLocal } from '../../utils';
import { DASHBOARD_PARAMS, useSelected } from '../../hooks/useSelected';

const LocationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const LocationTitleWrapper = styled.div`
  padding: 10px 13px 8px 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  border-top: 1px solid ${({ theme }) => theme.colors.divider};
`;

const LocationTitle = styled.div`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: 600;
  letter-spacing: 0.09px;
  color: #767676;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
  cursor: pointer;
  :hover {
    color: hsl(205, 100%, 65%);
  }
`;

const ArrowWrapper = styled.div`
  height: 20px;
  width: 20px;
  margin-left: 10px;
  border: 1px solid ${({ theme }) => theme.colors.divider};
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    margin-bottom: 2px;
  }
`;

const ZonesList = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  /* background: rgb(246, 248, 249) none repeat scroll 0% 0%; */
  background-image: ${({ theme }) => theme.styles.global.background};
  ${({ isOpen }) => isOpen ? css`
    display: block;
  ` :
    css`
    display: none;
  `};
  box-shadow: inset 0 6px 5px 0 rgba(226, 238, 247, 0.5);
  background-color: rgba(213, 213, 213, 0.1);
`;

const HeaderRightSection = styled.div`
  display: flex;
`;

const NoZonesText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.09px;
  color: #767676;
  margin: 15px;
  text-align: center;
`;


const LocationDetails: FC<RichLocation> = ({ name, id, zones, totalCameras, locationAlerts = 0 }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { updateParam } = useSelected();
  const { t } = useTranslation('Dashboard');

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((prevCollpsed) => !prevCollpsed);
  }, []);

  const handleLocationAndZoneClick = useCallback((layer, locId, zoneId = '') => {
    updateParam(DASHBOARD_PARAMS.layer, layer);
    updateParam(DASHBOARD_PARAMS.locationId, locId);
    updateParam(DASHBOARD_PARAMS.zoneId, zoneId);
  }, [updateParam]);

  return (
    <LocationWrapper>

      <LocationTitleWrapper>
        <LocationTitle title={name!} onClick={() => handleLocationAndZoneClick(2, id)}>{name}</LocationTitle>
        <HeaderRightSection>
          <CountWithIcon icon='Zone' count={zones.length} />
          <CountWithIcon icon='Camera' count={totalCameras} />
          <AlertsCountWithIcon count={locationAlerts} minWidth='60px' />
          <ArrowWrapper onClick={toggleCollapse}>
            <Icon icon={isCollapsed ? 'Up' : 'Down'} size={9} color='dimmed' />
          </ArrowWrapper>
        </HeaderRightSection>
      </LocationTitleWrapper>

      <ZonesList isOpen={isCollapsed}>
        {zones.map((zone, index) => <ZoneRow key={zone.id} totalZones={zones.length} {...zone} handleZoneClick={() => handleLocationAndZoneClick(3, id, zone.id)} detectionTypes={getDetectionTypesLocal(zone.detectionTypes)} index={index} />)}
        {zones.length === 0 && <NoZonesText>{t('locationsTab.noZonesAvailable')}</NoZonesText>}
      </ZonesList>

    </LocationWrapper>
  )
};

export default LocationDetails;