import * as React from "react";
function SvgVehicleWhitelist(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <path d="M3.203 6.358L4.952 2.46A2.249 2.249 0 017.061 1h8.882c.939 0 1.78.581 2.109 1.46l1.75 3.9M1.752 12.8v1.7a1.5 1.5 0 103 0V13M4.377 9.25a.375.375 0 00-.375.375M4.752 9.625a.375.375 0 00-.375-.375M4.377 10a.375.375 0 00.375-.375M4.002 9.625c0 .207.168.375.375.375" />
        <path d="M21.6 7.75a3 3 0 00-2.6-1.5H4a3 3 0 00-3 3v2.25A1.5 1.5 0 002.5 13h6M23.5 16.5a6 6 0 11-6-6" />
        <path d="M23.5 11.999l-5.47 5.47a.749.749 0 01-1.06 0l-1.72-1.719" />
      </g>
    </svg>
  );
}
export default SvgVehicleWhitelist;
