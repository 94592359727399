import React, { FC, useCallback } from 'react'
import { Container, PageHeaderWrapper } from '../Style';
import styled from 'styled-components';
import { ButtonWithIcon, Icon, IntroductionText, Label, PageHeader, Spinner } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import { useSystemExport } from '../hooks/useSystemExport';

const Divider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.divider};
  margin: 70px 0 61px;
`;

const ExportContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ExportIntroContainer = styled.div`
  max-width: 500px;
`;

const ExportLabel = styled(Label)`
  font-size: 20px;
  margin-bottom: 0;
`;

const ExportIntroduction = styled.div`
  font-size: 14px;
  line-height: 1.79;
  color: #8b9196;
`;

const SpinnerContainer = styled.div`
  > svg {
    width: 64px;
    height: 64px;
  }
`;

const Title = styled(Label)`
  font-size: 20px;
  margin: 23px 0 15px;
`;

const InfoContainer = styled.div`
  width: 387px;
  > p {
    text-align: center;
    line-height: 1.79;
    margin: 0;
  }
`;

const ExportingContainer = styled.div`
  width: 100%;
  margin-top: 71px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const IconBox = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    display: flex;
  }
`;

const SuccessIconBox = styled(IconBox)`
  background-color: #87d58f;
`;

const ErrorIconBox = styled(IconBox)`
  background-color: #de6b6b;
`;

const DownloadButton = styled(ButtonWithIcon)`
  margin-top: 37px;
`;

const RetryButton = styled(ButtonWithIcon)`
  margin-top: 10px;
`;

const SystemExport: FC = () => {
  const { t } = useTranslation(['SystemExport', 'Common']);
  const {isLoading, isProcessed, errorMessage, onExportSystemData, onDownloadingSystemData} = useSystemExport();

  const renderLoadingElement = useCallback(() => {
    return (
      <>
        <SpinnerContainer>
          <Spinner size='xlarge' styling='primary' />
        </SpinnerContainer>
        <Title htmlFor='' labelText={t('preparingExport')} />
        <InfoContainer>
          <IntroductionText>{t('preparingInfo')}</IntroductionText>
        </InfoContainer>
      </>
    );
  }, [t]);

  const renderProcessedElement = useCallback(() => {
    return (
      !errorMessage ? <>
        <SuccessIconBox>
          <Icon weight='regular' icon='Success' color='inverse' size={36} />
        </SuccessIconBox>
        <Title htmlFor='' labelText={t('downloadReady')} />
        <InfoContainer>
          <IntroductionText>{t('downloadInfo')}</IntroductionText>
        </InfoContainer>
        <DownloadButton size='small' design='secondary' icon='Download' position='left' onClick={onDownloadingSystemData}>{t('downloadExportedData')}</DownloadButton>
      </> : <>
        <ErrorIconBox>
          <Icon weight='regular' icon='Warning' color='inverse' size={36} />
        </ErrorIconBox>
        <Title htmlFor='' labelText={t('errorTitle')} />
        <InfoContainer>
          <IntroductionText>{t('errorMessage')}</IntroductionText>
          <IntroductionText>{`[${t(errorMessage)}]`}</IntroductionText>
        </InfoContainer>
        <RetryButton size='small' design='secondary' icon='RetryJob' position='left' onClick={onExportSystemData}>{t('retry')}</RetryButton>
      </>
    );
  }, [errorMessage, t, onDownloadingSystemData, onExportSystemData]);

  return (
    <Container>
      <PageHeaderWrapper>
        <PageHeader icon='ViewSettings' areaTitle={t('settings')} areaHref='#' title={t('systemExport')} />
      </PageHeaderWrapper>
      <Divider />

      {
        (!isLoading && !isProcessed) ?
        <ExportContainer>
          <ExportIntroContainer>
            <ExportLabel htmlFor='exportLabel' labelText={t('exportTitle')} />
            <ExportIntroduction>{t('exportInfo')}</ExportIntroduction>
          </ExportIntroContainer>
          <ButtonWithIcon icon='Download' position='left' onClick={onExportSystemData}>{t('exportTitle')}</ButtonWithIcon>
        </ExportContainer>
        : <ExportingContainer>
          {!isProcessed ? renderLoadingElement() : renderProcessedElement()}
        </ExportingContainer>
      }
    </Container>
  )
}

export default SystemExport;