import React, { VideoHTMLAttributes, useState, useCallback } from 'react'
import { IMediaType, MediaBox } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';

const Container = styled.div<{isEnlarged: boolean}>`
  width: 100%;
  max-height: calc(100vh - 100px);
  max-width: calc(100vw - 100px);
  position: relative;
  > :first-child {
    ${({isEnlarged}) => isEnlarged && css`
      filter: blur(8px);
      cursor: pointer;
    `}
    transition: all 0.3s;
  }
`;

const CroppedImage = styled.div<{isEnlarged: boolean, isVisible: boolean}>`
  visibility: ${({isVisible}) => isVisible ? 'visible' : 'hidden'};
  position: absolute;
  right: 35px;
  bottom: 30px;
  height: 30%;
  max-height: 80%;
  border-radius: 3px;
  transform-origin: bottom right;
  transition: all 0.3s;
  ${({isEnlarged}) => isEnlarged ? css`
    transform: scale(2);
  `: css`
    transform: scale(1);
  `}
  :hover {
    cursor: pointer;
  }

  > img {
    border-radius: 3px;
    width: 100%;
    height: 100%;
    max-height: calc(45vh - 100px);
    max-width: calc(45vw - 100px);
    box-shadow: 0 0 0 1px #64a8e3;
    display: ${({isVisible}) => isVisible ? 'block' : 'none'};
  }
`;

interface IProps {
  id: number
  mediaUrl: string
  cropped_image_url: string
  mediaType: IMediaType
}

const videoDefaultOptions: VideoHTMLAttributes<HTMLVideoElement> = { controls: true };

const TableThumbnailModal: React.FC<IProps> = ({id, mediaUrl, cropped_image_url, mediaType}) => {

  const [isFullImageLoaded, setIsFullImageLoaded] = useState(false);
  const [isCroppedImageLoaded, setIsCroppedImageLoaded] = useState(false);
  const [isEnlarged, setIsEnlarged] = useState(false);

  const checkFullImageLoaded = useCallback(() => {
    setIsFullImageLoaded(true);
  }, []);

  const checkCroppedImageLoaded = useCallback(() => {
    setIsCroppedImageLoaded(true);
  }, []);

  return (
    <Container key={id} {...{isEnlarged}}>
      <div onClick={() => {setIsEnlarged(false)}}>
        <MediaBox
          {...{
            mediaType,
          }}
          src={mediaUrl}
          hasModalLimits
          retryLimit= {5}
          videoOptions = {videoDefaultOptions}
          onMediaLoad={checkFullImageLoaded}
        />
      </div>
      {isFullImageLoaded ?
        <CroppedImage {...{isEnlarged,}} isVisible={isCroppedImageLoaded} onClick={() => {setIsEnlarged(prev => !prev)}}>
          <img src={cropped_image_url} onLoad={checkCroppedImageLoaded} onError={() => {setIsCroppedImageLoaded(false);}} alt='' />
        </CroppedImage>
      : null}
    </Container>
  )
}

export default TableThumbnailModal;
