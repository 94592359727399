import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWithIcon, Label } from 'scorer-ui-kit';
import styled from 'styled-components';

const AdvancedContainer = styled.div`
  margin-top: 53px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const AdvanceTextBox = styled.div`
  width: 540px;
`;

const AdvancedTitle = styled(Label)`
  font-size: 20px;
  margin-bottom: 0;
  color: hsl(208deg 8% 38%);
`;

interface IAdvanced {
  onDeleteCallback: () => void
}

const AdvancedTab:FC<IAdvanced> = ({onDeleteCallback}) => {

  const { t } = useTranslation(['AddEditUser', 'Users', 'Common']);

  return (
    <AdvancedContainer>
      <AdvanceTextBox>
        <AdvancedTitle htmlFor='' labelText={t('AddEditUser:deleteUser')} />
        <Label htmlFor='' labelText={t('AddEditUser:deleteInfo')} />
      </AdvanceTextBox>
      <ButtonWithIcon icon='Delete' design='danger' onClick={onDeleteCallback}>{t('AddEditUser:deleteUser')}</ButtonWithIcon>
    </AdvancedContainer>
  )
}

export default AdvancedTab