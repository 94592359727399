import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';


const ModalTitle = styled.div`
  ${({theme: { typography }}) => typography.modal.title};
  margin-bottom: 20px;
`;

const ConfirmLabel = styled.label`
  ${({theme: { typography }}) => typography.modal.basicContent};
  line-height: 1.79;
`;

const NameLabel = styled.label`
  ${({theme: { typography }}) => typography.modal.basicContent};
  display: block;
`;

const Divider = styled.div<{ margin?: string }>`
  width: auto;
  height: 1px;
  margin: 19px 0px 18px 0px;
  background-color: ${({ theme }) => theme.colors.divider};
`;

const ModalDivider = styled(Divider)`
  margin: 19px 0px 5px 0px;
`;

const NoteLabel = styled.label`
  ${({theme: { typography }}) => typography.modal.basicContent};
  line-height: 1.67;
`;

const DeleteNoteList = styled.ul`
  padding-left: 0;
  margin-top: 10px;
`;

const DeleteNote = styled.li`
  ${({theme: { typography }}) => typography.modal.basicContent};
  line-height: 1.67;
`;

const ButtonsGroup = styled.div`
  text-align: right;
  margin-top: 28px;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const ConfirmDeleteContent = styled.div`
  padding: 23px 40px 26px 40px;
  line-height: 1.79;
`;

interface IDeleteConfirmationModal {
  fullName: string,
  onDeleteConfirmation: () => {}
}

const DeleteConfirmationModal: React.FC<IDeleteConfirmationModal> = ({ fullName, onDeleteConfirmation }) => {
  const { t } = useTranslation(['AddEditPerson']);
  const { setModalOpen } = useModal();

  const onCancel = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <ConfirmDeleteContent>
      <ModalTitle> {t('deleteRecognitionProfile')} </ModalTitle>
      <ConfirmLabel>{t('deleteConfirmText')}</ConfirmLabel>
      <NameLabel>{fullName}</NameLabel>
      <Divider />
      <NoteLabel>{t('pleaseNote')}</NoteLabel>
      <DeleteNoteList>
        {
          Array.from(Array(3), (e, i) => <DeleteNote key={i}>{t('deleteNotes.' + i)}</DeleteNote>)
        }
      </DeleteNoteList>
      <ModalDivider />

      <ButtonsGroup>
        <StyledButton design='secondary' onClick={onCancel}>
          {t('cancel')}
        </StyledButton>
        <StyledButton design='danger' onClick={onDeleteConfirmation}>
          {t('confirmDeletion')}
        </StyledButton>
      </ButtonsGroup>
    </ConfirmDeleteContent>
  );
};

export default DeleteConfirmationModal;