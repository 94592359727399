import React, { FC, useCallback, useEffect, useState  } from 'react'
import { useCameras, IFetchCamerasOptions } from '../../hooks/useCameras'
import { useTranslation } from 'react-i18next';
import { FilterBar, Icon, IFilterDropdownConfig, IFilterItem, IFilterResult, ISearchFilter, PageHeader, TypeTable } from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled, { css } from 'styled-components';
import Pagination from '../Pagination';
import LongTextCell from '../atoms/LongTextCell';
import { dropdownHelper, getDetectionTypesLocal, readParams, updateParams } from '../../utils';
import { INITIAL_ROWS } from '../../constants';
import { useZones } from '../../hooks/useZones';
import { useLocations } from '../../hooks/useLocations';
import { ISelectedFilterValues } from '../../pages/Cameras';
import { PageHeaderWrapper } from '../../Style';
import { useHistory, useLocation } from 'react-router-dom';


const FilterWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
  position: relative;
`;

const SortingTextContainer = styled.div<{isAscending: boolean}>`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 4px;
  > div{
    display: flex;
    ${({isAscending}) => isAscending && css`
      transform: rotate(180deg);
    `}
  }
`;

const SortingText = styled.span`
  font-size: 12px;
  padding: 0 9px;
  border-right: 1px solid;
`;

const SettingsIconContainer = styled.span`
  padding-left: 9px;
  > div {
    display: flex;
  }
`;

const initialFilterValues: ISelectedFilterValues = {
  type: undefined,
  location: undefined,
  zone: undefined,
  camName: '',
  sortDirection: 'asc',
  sortBy: 'name',
  paramsLoaded: false
}

const sizeOptions = [1, 10, 20, 50, 100];

const CameraListView: FC<{}> = () => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [dropdownConfig, setDropdownConfig] = useState<IFilterDropdownConfig[]>();
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const { locations, loading: locationsLoading, actions: { fetchLocations } } = useLocations();
  const { zones, loadingZones, actions: { fetchZones } } = useZones();
  const { cameras, cameraCount, loading, actions: { fetchCameras } } = useCameras();

  const { push } = useHistory();
  const params = useLocation().search;
  const [historyParams] = useState(params);

  const { t } = useTranslation(['Cameras', 'CameraDetails', 'Common']);

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };
  const searchers: ISearchFilter[] = [
    {
      id: 'camera-search',
      placeholder: t('filterByCameraName'),
    }
  ];

  const getAnalysisTypeList = useCallback(() => {
    const analysisTypes = cameras.map(camera => {return {name: getDetectionTypesLocal(camera.detection_types), value: camera.detection_types}});
    const uniqueTypes = [...new Map(analysisTypes.map(item => [item.name, item])).values()];
    return uniqueTypes;
  }, [cameras]);

  const getDropdownConfig = useCallback(() => {

    return ([
      {
        id: 'location',
        buttonText: t('location'),
        list: dropdownHelper(locations, { textKey: 'name', valueKey: 'id' }),
        buttonIcon: 'Location',
        optionType: 'checkbox',
        loadingText: t('loadingLocations'),
        hasOptionsFilter: true,
        isLoading: locationsLoading,
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      },
      {
        id: 'zone',
        buttonText: t('zone'),
        list: dropdownHelper(zones, { textKey: 'name', valueKey: 'id' }),
        buttonIcon: 'Zone',
        optionType: 'checkbox',
        loadingText: t('loadingZones'),
        hasOptionsFilter: true,
        isLoading: loadingZones,
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      },
      {
        id: 'type',
        buttonText: t('type'),
        list: dropdownHelper(getAnalysisTypeList(), { textKey: 'name', valueKey: 'value' }),
        buttonIcon: 'AnalysisType',
        optionType: 'checkbox',
        loadingText: t('loadingCameras'),
        hasOptionsFilter: true,
        isLoading: loading,
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      }
    ]);
  }, [locationsLoading, loading, locations, zones, loadingZones, t, getAnalysisTypeList]);

  const tableColumns: ITableColumnConfig[] = [
    {
      groupTitle: t('cameraMeta'),
      header: t('cameraName'),
      sortable: true,
      cellStyle: 'firstColumn',
      columnId: 'name',
      sortActive: (selectedFilterValues.sortBy === 'name')
    },
    {
      groupTitle: t('location'),
      header: t('location'),
      sortable: true,
      cellStyle: 'normalImportance',
      columnId: 'location_name',
      sortActive: (selectedFilterValues.sortBy === 'location_name')
    },
    {
      groupTitle: t('location'),
      header: t('zone'),
      sortable: true,
      cellStyle: 'normalImportance',
      columnId: 'zone_name',
      sortActive: (selectedFilterValues.sortBy === 'zone_name')
    },
    {
      groupTitle: t('details'),
      header: t('analysisType'),
      sortable: true,
      cellStyle: 'normalImportance',
      columnId: 'detection_types',
      sortActive: (selectedFilterValues.sortBy === 'detection_types')
    },
  ];

  useEffect(() => {
    setDropdownConfig(getDropdownConfig() as IFilterDropdownConfig[]);
  }, [getDropdownConfig, locationsLoading, locations, zones, loadingZones, selectedFilterValues]);

  useEffect(() => {
    fetchLocations();
    fetchZones();
  }, [fetchLocations, fetchZones]);

  useEffect(() => {
    setSelectedFilterValues(readParams(historyParams, initialFilterValues));
  }, [historyParams]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    push({ search: updateParams(selectedFilterValues) });
  }, [push, selectedFilterValues]);

  const getSortByTextAndKey = useCallback((text: string) => {
    switch (text) {
      case 'name':
        return 'cameraName';
      case 'location_name':
        return 'location';
      case 'zone_name':
        return 'zone';
      case 'detection_types':
        return 'analysisType';
      default:
        return 'cameraName';
    }
  }, []);

  const getApiParams = useCallback((): IFetchCamerasOptions => {

    const { type, location, zone, camName, sortDirection = 'asc', sortBy = 'name' } = selectedFilterValues;

    return ({
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      locationId: location as string,
      zoneId: zone as string,
      camName: camName as string,
      sortBy: sortBy as string,
      sortDirection: sortDirection as string,
      type: type as string
    });

  }, [pageSize, currentPage, selectedFilterValues]);

  const generateRowData = useCallback((): ITypeTableData => {

    const data: ITypeTableData = cameras.map((camera) => {

      const {
        name = '-',
        location_name = '-',
        zone_name = '-',
        detection_types,
        id
      } = camera;

      const row: IRowData = {
        columns: [
          { text: name,  href:  `/cameras/${id}` },
          { customComponent: <LongTextCell text={location_name} /> },
          { customComponent: <LongTextCell text={zone_name} /> },
          { customComponent: <LongTextCell text={getDetectionTypesLocal(detection_types)} /> }
        ]
      };
      return row;
    });
    return data.length ? data : INITIAL_ROWS;
  }, [cameras]);

  useEffect(() => {
    if (loading) return;
    setRowData(generateRowData());
  }, [cameras, loading, generateRowData]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    fetchCameras(getApiParams());
  }, [pageSize, currentPage, selectedFilterValues, fetchCameras, getApiParams]);

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {
    setCurrentPage(1);

    const selectedValues: ISelectedFilterValues = res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {
      switch (id) {
        case 'camera-search': {
          const {value} = selected as IFilterItem;
          selectedValues.camName = value as string;
          break;
        }
        default:
          selectedValues[id] = (selected as IFilterItem[]).map(({ value }) => value).join(',');
      }
      return selectedValues;
    },
      { ...initialFilterValues, paramsLoaded: selectedFilterValues.paramsLoaded }
    );
    setSelectedFilterValues({...selectedValues});
  }, [selectedFilterValues]);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrentPage(parseInt(val));
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(cameraCount / pageSize));
  }, [cameraCount, pageSize]);

  return (
    <>
      <PageHeaderWrapper>
        <PageHeader icon='Camera' title={t('cameras')} introductionText={t('introTextFukaya')} />
      </PageHeaderWrapper>
      <FilterWrapper>
        {dropdownConfig &&
          <FilterBar
            searchersConfig={searchers}
            dropdownsConfig={dropdownConfig}
            filtersTitle={t('Common:filter.filters') + ':'}
            resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
            totalResults={rowData.length === 1 && rowData[0].columns.length === 0 ? 0 : rowData.length}
            onChangeCallback={onChangeFilterValue}
          />}
        {
          <SortingTextContainer isAscending={selectedFilterValues.sortDirection === 'asc'}>
            <Icon icon='FilterSorting' size={14} color='dimmed' />
            <SortingText>{t('Common:filter.sortedBy') + ' ' + t(getSortByTextAndKey(selectedFilterValues.sortBy as string))}</SortingText>
            <SettingsIconContainer>
              <Icon icon='ViewSettings' size={14} color='dimmed' />
            </SettingsIconContainer>
          </SortingTextContainer>
        }
      </FilterWrapper>

      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
        isLoading={loading}
        emptyTableText={t('noCamerasAvailable')}
        loadingText={t('Common:loadingData')}
        sortCallback={sortCallback}
        hasHeaderGroups
        defaultAscending={ selectedFilterValues.sortDirection === 'asc' ? true : false }
      />

      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={sizeOptions}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}
    </>
  )
}

export default CameraListView