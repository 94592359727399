import * as React from "react";
function SvgDownloadImage(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <circle cx={17.5} cy={17.5} r={6} />
        <path d="M17.5 14.5v6m0 0l-2.25-2.25m2.25 2.25l2.25-2.25M8.5 20.5h-6A1.5 1.5 0 011 19V2.5A1.5 1.5 0 012.5 1h10.629a1.5 1.5 0 011.06.439l2.872 2.872a1.5 1.5 0 01.439 1.06V8.5" />
        <path d="M5.5 5.875a.375.375 0 110 .75.375.375 0 010-.75m6.835 3.961L11.5 8.5a.75.75 0 00-1.238-.014l-2 2.851-1.232-.987a.75.75 0 00-1.093.169L4 14.5h4.5" />
      </g>
    </svg>
  );
}
export default SvgDownloadImage;
