import { FC } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';


const ModalTitle = styled.div`
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 20px;
  font-weight: 500;
  color: #727980;
`;

const ConfirmLabel = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: #7c7e7f;
`;

const ButtonsGroup = styled.div`
  margin-top: 25px;
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const ConfirmDismissContent = styled.div`
  padding: 23px 22px 26px 22px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.79;
  color: hsl(200, 1%, 49%);
`;

interface IDismissAllModal {
  onDismissAll: () => void
}

const DismissAllAlertsModal:FC<IDismissAllModal> = ({onDismissAll = () => {}}) => {

  const { t } = useTranslation(['Dashboard', 'Common']);
  const { setModalOpen } = useModal();

  const onCancel = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  return (
    <ConfirmDismissContent>
      <ModalTitle> {t('cameraTabModal.dismissModalTitle')} </ModalTitle>
      <ConfirmLabel>{t('cameraTabModal.dismissAllNote')}</ConfirmLabel>
      <ButtonsGroup>
        <StyledButton design='secondary' onClick={onCancel}>
          {t('Common:cancel')}
        </StyledButton>
        <StyledButton onClick={onDismissAll}>
          {t('cameraTabModal.dismissAll')}
        </StyledButton>
      </ButtonsGroup>
    </ConfirmDismissContent>
  )
}

export default DismissAllAlertsModal;