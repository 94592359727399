import { useState, useCallback } from 'react';
import { AlertTypes } from '../context/AlertsContext';

export interface Camera {
  id: number;
  name: string;
  details: string; //"This is a camera 1",
  detection_types: 'CAR_COUNTING'|'NUMBER_PLATE';
  alert_type: AlertTypes,
  tags: string; //"Project1,tag2",
  category: string; //"Shopping1",
  position_x: number; //999,
  position_y: number; //999;
  scorer_name: string; //RTSPcam3

  zone_id: number;
  zone_name: string;

  meta: { [key: string]: string }
  orientation: number;

  location_id: number;
  location_name: string;
}

interface CameraResponse {
  cameras: Camera[],
  limit: number,
  message: string; // "Succeeded",
  offset: number; //0,
  total_record_count: number;
}
export interface IFetchCamerasOptions {
  limit?: number;
  offset?: number;
  camName?: string;
  locationId?: string
  zoneId?: string
  type?: string;
  sortBy?: string;
  sortDirection?: string;
  tags?: string;
}
export const useCameras = () => {
  const [cameras, setCameras] = useState<Camera[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [cameraCount, setCameraCount] = useState(0);

  const fetchCameras = useCallback(async (options?: IFetchCamerasOptions) => {
    setLoading(true);
    setErrorMessage('');

    const {
      limit = 100,
      offset = 0,
      camName,
      locationId,
      zoneId,
      tags,
      type,
      sortBy,
      sortDirection
    } = (options || {});


    const searchString = new URLSearchParams({
      limit: '' + limit,
      offset: '' + offset,
      ...(camName ? { camera_name: `${camName}` } : {}),
      ...(locationId ? { location_id: `${locationId}` } : {}),
      ...(zoneId ? { zone_id: `${zoneId}` } : {}),
      ...(tags ? { tags: `${tags}` } : {}),
      ...(sortBy ? { sort_by: `${sortBy}` } : {}),
      ...(sortDirection ? { sort_direction: `${sortDirection}` } : {}),
      ...(type !== undefined ? { detection_types: `${type}` } : {})
    });

    const url = '/api/camera?' + searchString.toString();

    try {
      const response = await fetch(url)
      if (response.ok) {
        const {
          cameras = [],
          total_record_count
        } = await response.json() as CameraResponse;
        setCameras(cameras);
        setCameraCount(total_record_count);
      } else {
        console.error(response.statusText);
        setErrorMessage(response.statusText);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setErrorMessage(error.message);
      } else {
        console.error(error);
        setErrorMessage('Unknown Error fetching Cameras.');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    cameras,
    cameraCount,
    loading,
    errorMessage,
    actions: {
      fetchCameras
    }
  }
}

