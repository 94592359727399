import * as React from "react";
function SvgPersonUnknown(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <circle cx={6.25} cy={4} r={3} />
        <path d="M10.805 11.138A5.251 5.251 0 001 13.75V16h2.25L4 23.5h4.5l.323-3.233" />
        <circle cx={17} cy={17} r={6} />
        <path d="M15.5 15.5A1.5 1.5 0 1117 17v.75m0 1.875a.375.375 0 100 .75.375.375 0 000-.75h0" />
      </g>
    </svg>
  );
}
export default SvgPersonUnknown;
