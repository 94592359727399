import React, { FC, useEffect, useState } from 'react';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Label, TabsWithIconBar } from 'scorer-ui-kit';
import styled from 'styled-components';
import { ICsvData, IImportData } from '../../hooks/useNumberPlateImport';
import { NumberPlate } from '../../hooks/useNumberPlates';
import Entries from './Entries';

const Container = styled.div`
  margin-top: 51px;
`;

const Divider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.divider};
  margin-top: 56px;
`;

const EntriesLabel = styled(Label)`
  font-size: 20px;
  margin-bottom: 0;
  > span {
    margin-bottom: 0;
  }
`;

const Status = styled.div`
  margin-right: 17px;
  flex-shrink: 0;
`;

const Counts = styled.div`
  flex-shrink: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 0;
`;

const Heading = styled(Label)`
  margin-bottom: 3px;
  > span {
    margin-bottom: 0;
  }
`;

const Message = styled(Label)`
  margin-bottom: 0;
  > span {
    margin-bottom: 0;
  }
  font-style: italic;
  font-weight: normal;
  color: rgba(120, 138, 144, 0.72);
`;

const EntriesCountContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px 55px;
`;

const EntriesCountAndButton = styled.div`
  margin-top: 22px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px 5px;
  flex-wrap: wrap;
`;

const TabsWithIconBarContainer = styled.div`
  > div > div:first-child {
    box-shadow: none;
    border-color: ${({ theme }) => theme.colors.divider};
  }
`;

type IGetFailedStatus = (failure_code: number, column_name: string, column_length: number) => string;

interface IHeadings {
  [key: string]: string
}

interface IEntriesCount {
  [key: string]: number
}

export interface IEntries extends NumberPlate {
  checked?: boolean,
  status: string
}

interface IProps {
  importMode: string,
  csvData: ICsvData,
  loadingCsvData: boolean,
  importData: (data: IImportData) => void
  handleCancel: () => void
}

const CheckAndConfirmTab: FC<IProps> = ({ importMode, csvData, loadingCsvData, importData, handleCancel }) => {
  const { t } = useTranslation(['NumberPlateImport', 'NumberPlates', 'Common']);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const [newEntries, setNewEntries] = useState<IEntries[]>([]);
  const [updatedEntries, setUpdatedEntries] = useState<IEntries[]>([]);
  const [failedEntries, setFailedEntries] = useState<IEntries[]>([]);
  const [deletedEntries, setDeletedEntries] = useState<IEntries[]>([]);
  const [entriesCount, setEntriesCount] = useState<IEntriesCount>({
    deleted_entries: 0,
    new_entries: 0,
    updated_entries: 0
  });
  const headings: IHeadings = ({
    new_entries: 'newEntries',
    updated_entries: 'updates',
    deleted_entries: 'deletions'
  });

  const getFailedEntryStatus: IGetFailedStatus = useCallback((failure_code, column_name = '', column_length = 0) => {
    const mapColumnName: { [key: string]: string } = {
      name: t('reference'),
      type: t('NumberPlates:type'),
      area: t('location'),
      user_type: t('class'),
      kana: t('kana'),
      number: t('number'),
      tags: t('NumberPlates:tags'),
      description: t('description'),
      category: t('NumberPlates:category')
    };

    switch (failure_code) {
      case 100:
        return t('xIsEmpty').replace('{X}', mapColumnName[column_name]);
      case 200:
        return t('xMaxLengthError').replace('{X}', mapColumnName[column_name]).replace('{xLength}', `${column_length}`);
      case 300:
        return t('valueOfXIsInvalid').replace('{X}', mapColumnName[column_name]);
      case 301:
        return t('duplicateEntry');
      case 400:
        return t('unknownDbError');
      case 500:
        return t('unknownSystemError');
      default:
        return '';
    }
  }, [t]);

  useEffect(() => {
    const { new_entries = [], updated_entries = [], deleted_entries = [], failed_entries = [] } = csvData;

    setNewEntries(new_entries.map((item, index) => { return { ...item, id: index, checked: true, status: t('ready') } }));
    setUpdatedEntries(updated_entries.map((item, index) => { return { ...item, id: index, checked: true, status: t('ready') } }));
    setDeletedEntries(deleted_entries.map(item => { return { ...item, status: t('markedForDeletion') } }));
    setFailedEntries(failed_entries.map(({ entry, failure_code, column_name, column_length }) => { return { ...entry, status: getFailedEntryStatus(failure_code, column_name, column_length) } }));
    setTotalEntries(new_entries.length + updated_entries.length + (importMode === 'sync' ? deleted_entries.length : 0) + failed_entries.length);
  }, [importMode, csvData, t, getFailedEntryStatus]);

  useEffect(() => {
    setEntriesCount({
      new_entries: newEntries.filter(item => item.checked).length,
      updated_entries: updatedEntries.filter(item => item.checked).length,
      deleted_entries: deletedEntries.length
    })
  }, [newEntries, updatedEntries, deletedEntries]);

  const onEntriesSelect = useCallback((toggleAll: boolean, tab: string, checked: boolean, id?: string | number) => {
    if (tab === 'newEntries') {
      setNewEntries(prev => {
        return prev.map((item, index) => {
          if (index === id || toggleAll) {
            return { ...item, checked };
          }
          return item;
        });
      });
    } else {
      setUpdatedEntries(prev => {
        return prev.map((item, index) => {
          if (index === id || toggleAll) {
            return { ...item, checked };
          }
          return item;
        });
      });
    }
  }, []);

  const getEntriesTabs = useCallback(() => {
    return [
      {
        customComponent: <Entries entries={newEntries} loadingTableData={loadingCsvData} onEntriesSelect={onEntriesSelect} tab='newEntries' icon='Success' title={t('newEntriesTitle')} isSelectable={importMode === 'patch'}
          introduction={t('newEntriesIntro')} />,
        icon: 'Success',
        subtitle: importMode === 'patch' ? t('selectedNofTotal').replace('{N}', `${newEntries.filter(item => item.checked).length}`).replace('{Total}', `${newEntries.length}`) : t('totalNEntries').replace('{N}', `${newEntries.length}`),
        tabFor: 'newEntries',
        title: t('newEntries')
      },
      {
        customComponent: <Entries entries={updatedEntries} loadingTableData={loadingCsvData} onEntriesSelect={onEntriesSelect} tab='updatedEntries' icon='Vehicle' title={t('duplicatesTitle')} isSelectable={importMode === 'patch'}
          introduction={t('duplicatesIntro')} />,
        icon: 'Vehicle',
        subtitle: importMode === 'patch' ? t('selectedNofTotal').replace('{N}', `${updatedEntries.filter(item => item.checked).length}`).replace('{Total}', `${updatedEntries.length}`) : t('totalNEntries').replace('{N}', `${updatedEntries.length}`),
        tabFor: 'update',
        title: t('update')
      },
      ...(importMode === 'sync') ? [{
        customComponent: <Entries entries={deletedEntries} loadingTableData={loadingCsvData} tab='deletedEntries' icon='Delete' title={t('deletions')} isSelectable={false}
          introduction={t('deletionsIntro')} />,
        icon: 'Delete',
        subtitle: t('totalNEntries').replace('{N}', `${deletedEntries.length}`),
        tabFor: 'delete',
        title: t('delete')
      }] : [],
      {
        customComponent: <Entries entries={failedEntries} loadingTableData={loadingCsvData} tab='failedEntries' icon='Warning' title={t('errors')} isSelectable={false}
          introduction={t('errorsIntro')} />,
        icon: 'Warning',
        subtitle: t('NEntriesWithErrors').replace('{N}', `${failedEntries.length}`),
        tabFor: 'errors',
        title: t('errors')
      },
    ];
  }, [importMode, newEntries, updatedEntries, deletedEntries, failedEntries, loadingCsvData, t, onEntriesSelect]);

  const handleImport = useCallback(() => {
    const new_entries = newEntries.filter(item => item.checked).map(item => {
      const { id, status, checked, ...rest } = item;
      return rest;
    });
    const updated_entries = updatedEntries.filter(item => item.checked).map(item => {
      const { id, status, checked, ...rest } = item;
      return rest;
    });
    const deleted_entries = (importMode === 'patch') ? [] : deletedEntries.map(item => {
      const { id, status, checked, ...rest } = item;
      return rest;
    });

    const finalData = {
      mode: importMode,
      deleted_entries,
      new_entries,
      updated_entries
    };

    importData(finalData);
  }, [importMode, newEntries, updatedEntries, deletedEntries, importData]);

  return (
    <Container>
      <EntriesLabel htmlFor='' labelText={t('checkConfirmTitle')} />
      <EntriesCountAndButton>
        <EntriesCountContainer>
          <Status>
            <Heading htmlFor='Status' labelText={t('status')} />
            <Message htmlFor='' labelText={loadingCsvData ? t('loadingEntries') : t('readyForCheck')} />
          </Status>
          <Counts>
            <Heading htmlFor='Status' labelText={t('totalEntries')} />
            <Message htmlFor='' labelText={`${totalEntries}`} />
          </Counts>
          {
            Object.keys(headings).map((item) => {
              if (importMode !== 'sync' && item === 'deleted_entries') {
                return null;
              }
              if (Object.keys(headings).includes(item)) {
                return <Counts key={item}>
                  <Heading htmlFor='Status' labelText={t(headings[item])} />
                  <Message htmlFor='' labelText={`${entriesCount[item]}`} />
                </Counts>
              }
              return null;
            })
          }
        </EntriesCountContainer>
        <ButtonContainer>
          <Button size='small' disabled={loadingCsvData} onClick={handleImport}>{importMode === 'sync' ? t('synchronizeData') : t('importNEntries').replace('{N}', (entriesCount.new_entries + entriesCount.updated_entries + ''))}</Button>
          <Button size='small' design='secondary' disabled={loadingCsvData} onClick={handleCancel}>{t('cancel')}</Button>
        </ButtonContainer>
      </EntriesCountAndButton>
      <Divider />
      <TabsWithIconBarContainer>
        <TabsWithIconBar
          defaultTabId="newEntries"
          paddingLeft="0"
          tabList={getEntriesTabs()}
        />
      </TabsWithIconBarContainer>
    </Container>
  )
}

export default CheckAndConfirmTab;