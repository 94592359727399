import { FC } from 'react';
import styled, { css } from 'styled-components';
import { EllipsisStyles } from '../../Style';

const CellText = styled.div<{hasHover: boolean, maxWidth: string}>`
  font-size: 14px;
  color: hsla(195,10%,52%,0.72);
  font-family: ${({ theme }) => theme.fontFamily.data};
  max-width: ${({maxWidth}) => maxWidth};
  ${({ hasHover }) => hasHover && css`
    cursor: pointer;
    :hover {
      color: hsl(205, 100%, 65%);
    }
  `};
  ${EllipsisStyles}
`;

interface ILongTextCell {
  text: string
  hasHover?: boolean
  maxWidth?: string,
  onClickCallback?: () => void
}

const LongTextCell: FC<ILongTextCell> = ({ text, maxWidth = '100px', hasHover = false, onClickCallback = () => {}}) => {

  return <CellText title={text} hasHover={hasHover} maxWidth={maxWidth} onClick={onClickCallback}>{text}</CellText>
}

export default LongTextCell;