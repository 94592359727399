import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { Icon, IMediaType, TableRowThumbnail, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';
import TableThumbnailModal from './TableThumbnailModal';


const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > :first-child {
    cursor: pointer;
  }
`;


interface IProps {
  id: number
  mediaUrl: string
  cropped_image_url: string
  mediaType: IMediaType
  icon?: string
}

const TableThumbnail:FC<IProps> = ({id, mediaUrl, cropped_image_url, mediaType, icon}) => {

  const { createModal } = useModal();
  const { t } = useTranslation(['IntrusionDetection', 'Common']);

    
  const customTableModal = useCallback((id, mediaUrl, cropped_image_url, mediaType) => {
    createModal({
      padding: false,
      width: 'auto',
      closeText: t('Common:close'),
      customComponent: (
        <TableThumbnailModal {...{id, mediaUrl, cropped_image_url, mediaType}} />
      )
    });
  }, [createModal, t]);

  return (
    <Container onClick={(e) => {
      e.stopPropagation();
      customTableModal(id, mediaUrl, cropped_image_url, mediaType);
    }}>
      <TableRowThumbnail image={cropped_image_url} mediaType={mediaType} />
      {icon ?
        <Icon
          icon={icon}
          color='dimmed' weight='regular' size={16}
        />
      : null}
    </Container>
  )
}

export default TableThumbnail;