import React, { useCallback, useReducer, useMemo, useEffect, useState } from 'react';
import styled, {css} from 'styled-components';
import { Icon, ButtonWithIcon } from 'scorer-ui-kit';

import Node from './Nodes/Node';
import NodeSectionAdd from './Nodes/NodeSectionAdd';
import NodeSectionEdit from './Nodes/NodeSectionEdit';
import NodeSectionDetails from './Nodes/NodeSectionDetails';
import NodeSectionEditRelations from './Nodes/NodeSectionEditRelations';
import NodeSectionDetailsRelations from './Nodes/NodeSectionDetailsRelations';

import { triggersReducer, actionsReducer, conditionsReducer, IReducerActionForActions, IReducerActionForConditions, IReducerActionForTriggers } from './MatrixReducers';
import { INodeTargets, IPipeline, IPipelineAction, IPipelineActionRelationLink, IPipelineNodeCore, IPipelineStatus} from '.';
import { Camera } from '../../hooks/useCameras';
import { IRelay } from '../../hooks/useRelays';
import { ILogicGroup } from '../../hooks/useLogicGroup';
import { ITag } from '../../hooks/useTags';
import { ICategory } from '../../hooks/useCategories';
import { useTranslation } from 'react-i18next';
import NodeEmptyState from './Nodes/NodeEmptyState';
import NodeSectionEditEmail from './Nodes/NodeSectionEditEmail';

const LINE_V_OFFSET = '34px';

const GridItem = styled.div<{rowStart?: number, rowEnd?: number | null, colStart?: number, colEnd?: number, status?:'valid'|'pending'|'invalid'}>`
  ${({rowStart}) => rowStart && css`grid-row-start: ${rowStart}`};
  ${({rowEnd}) => rowEnd && css`grid-row-end: ${rowEnd}`};
  ${({colStart}) => colStart && css`grid-column-start: ${colStart}`};
  ${({colEnd}) => colEnd && css`grid-column-end: ${colEnd}`};

  z-index: 8;
`

const GridContainer = styled.div`
  overflow: auto;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: minmax(225px, 300px) 60px minmax(225px, 300px) 60px minmax(225px, 300px) 60px minmax(225px, 300px) 60px 120px;
`
const ColumnHeaderContainer = styled(GridItem)`
  grid-row-start: 1;

  h2 {
    font-family: ${({theme}) => theme.fontFamily.ui};
    font-weight: 500;
    color: hsl(208, 8%, 38%);
    font-size: 16px;
  }

  p {
    font-family: ${({theme}) => theme.fontFamily.ui};
    font-weight: 500;
    font-size: 12px;
    color: hsl(207, 5%, 57%);
  }
`
const ColumnNodeContainer = styled(GridItem)`
  ${({rowStart}) => rowStart && css`grid-row-start: ${rowStart}`};
  ${({rowEnd}) => rowEnd && css`grid-row-end: ${rowEnd}`};
  ${({colStart}) => colStart && css`grid-column-start: ${colStart}`};
  ${({colEnd}) => colEnd && css`grid-column-end: ${colEnd}`};
`

const ColumnButtonContainer = styled(ColumnNodeContainer)<{isTop?: boolean}>`
  display: flex;
  flex-direction: column;
  justify-content: ${({isTop}) => isTop ? 'top' : 'center'};
  align-items: center;
  min-height: 80px;

  > button {

    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    ${({isTop, theme}) => !isTop && css`
      opacity: 0.75;
      transition: opacity ${theme.animation.speed.normal} ${theme.animation.easing.primary.inOut};
      &:hover {
        opacity: 1;
      }
    `}

  }


`

const LineContainer = styled(GridItem)`
  position: relative;
  min-height: 50px;
  z-index: 0;
`

const Line = styled.div<{direction?:'default'|'up'|'down', status?:'valid'|'pending'|'invalid'}>`
  pointer-events: none;
  overflow: visible;

  position: absolute;
  left: 0;
  right: 0;
  top: calc(${LINE_V_OFFSET} - 2px);
  border-color: ${({theme}) => theme.colors.icons.subtle};
  border-width: 2px;
  border-style: none;

  border-left-style: solid;
  border-bottom-style: solid;

  ${({direction}) => direction && css`
    left: calc(50% - 1px);
    bottom: calc(0px - 80px);
  `}

  ${({direction}) => direction === 'up' && css`
    right: calc(50% - 1px);
    left: 0;
    bottom: calc(0px - 80px);
    border-left-style: none;
    border-right-style: solid;
  `}

  ${({status, theme}) => status && css`
    border-color: ${status === 'valid' && theme.colors.icons.primary};
    border-color: ${status === 'invalid' && theme.colors.icons.danger};
  `}

`

const LineV = styled(Line)`
  border-bottom: none;
  left: calc(50% - 1px);
  top: 0;
  bottom: 0;
  width: 0;
`

const LineS = styled(Line)`
  right: calc(50% - 1px);
  left: 0;
  bottom: calc(0px - ${LINE_V_OFFSET});
  border-right-style: solid;
  border-left-style: none;


  &::after {
    content: '';
    position: absolute;
    display: block;
    border-width: 2px;
    border-top-style: solid;
    right: calc(-100% - 2px);
    width: 100%;
    top:0;

    ${({status, theme}) => status && css`
      border-color: ${theme.colors.icons.subtle};
      border-color: ${status === 'valid' && theme.colors.icons.primary};
      border-color: ${status === 'invalid' && 'red'};
    `}
  }

  ${({direction}) => direction === 'down' && css`
    border-bottom-style: none;

    &::after {
      bottom: 0;
      border-top-style: none;
      border-bottom-style: solid;
    }
  `}


`

const PipelineStatusContainer = styled(GridItem)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: top;
  align-items: left;
  padding-top: 17px;
  padding-left: 35px;

  &::before {
    content: '';
    position: absolute;
    display: block;
    border-width: 2px;
    border-top-style: solid;
    width: 35px;
    top: calc(${LINE_V_OFFSET} - 2px);
    left: 0;

    ${({status, theme}) => status && css`
      border-color: ${theme.colors.icons.subtle};
      border-color: ${status === 'valid' && theme.colors.icons.primary};
      border-color: ${status === 'invalid' && 'red'};
    `}
  }
`

interface IMatrixColumn {
  enabled?: boolean;
  title: string;
  description?: string;
}
const MatrixColumns : IMatrixColumn[] = [
  {
    title: 'detectionTriggerTitle',
    description: 'detectionTriggerDescription'
  },
  {
    title: 'conditionsTitle',
    description: 'conditionsDescription'
  },
  {
    title: 'actionsTitle',
    description: 'actionsDescription'
  },
  {
    enabled: false,
    title: 'actionRelationsTitle',
    description: 'actionRelationsDescription'
  },
  {
    title: 'pipelineStateTitle',
    description: 'pipelineStateDescription'
  }
]



const nthToOdd = (number: number) => {
  return 2 * number - 1;
}
const nthToEven = (number: number) => {
  return 2 * number;
}

/* These functions exist because we alternate between nodes and lines in our grid and
this is a more readable way to keep that in mind when returning to this code, hopefully. */
const nodeCol = (number: number) => {
  return nthToOdd(number);
}
const nodeRow = (number: number) => {
  return nthToOdd(number) + 1;
}
const lineCol = (number: number) => {
  return nthToEven(number);
}
const lineRow = (number: number) => {
  return nthToEven(number) + 1;
}


interface IColumnHeader {
  title: string;
  description?: string;
  colStart?: number;
}
const ColumnHeader : React.FC<IColumnHeader> = ({title, description, colStart}) => {
  return (
    <ColumnHeaderContainer colStart={colStart}>
      <h2>{title}</h2>
      <p>{description}</p>
    </ColumnHeaderContainer>
  );
}


interface IDispatchRouting {
  triggers: React.Dispatch<IReducerActionForTriggers>
  actions: React.Dispatch<IReducerActionForActions>
  conditions: React.Dispatch<IReducerActionForConditions>
}

interface Props{
  logicGroup?: ILogicGroup;
  updatePipeline: (pipeline?: IPipeline) => void;
  updatePipelineStatus: (pipelineStatus?: IPipelineStatus) => void;
  cameras: Camera[];
  relays: IRelay[];
  tags: ITag[];
  catagories: ICategory[];
}

// check if single action is ready and valid
const checkActionReady = ({editing: actionEditing, relations, data:{links =[]}}: IPipelineAction) => (
  !actionEditing &&
  (!relations ||
    (links.length > 0 && relations.editing !== true)
  )
);

/** NOTE:
 * Grid items don't actually have to be in order, you can use the rowStart and End stuff a lot
 * So we can output all nodes in one step. Lines in another.
 * Headers in another.
 */
const RulesMatrix : React.FC<Props> = ({cameras, relays, logicGroup, tags, catagories, updatePipeline, updatePipelineStatus}) => {
  const { t } = useTranslation(['RelayLogicGroups', 'Common']);

  const [triggersConditionsReady, setTriggersConditionsReady] = useState(false);

  const [triggers, triggerDispatch] = useReducer(triggersReducer, []);
  const [actions, actionDispatch] = useReducer(actionsReducer, []);
  const [conditions, conditionDispatch] = useReducer(conditionsReducer, []);
  const [pipelineStatus, setPipelineStatus] = useState<IPipelineStatus>({
    triggerAdd: false,
    triggerEdit: false,
    filtersEdit: false,
    actionAdd: false,
    actionEdit: false,
    relayLinks: false
  });

  useEffect(() => {
    if(!logicGroup || cameras.length === 0) return;
    const {layout} = logicGroup;
    triggerDispatch({type: 'load', state: layout.triggers});
    conditionDispatch({type: 'load', state: layout.conditions, externalData:{cameras}});
    actionDispatch({type: 'load', state: layout.actions});
  },[cameras, logicGroup]);


  /**
   * Dispatch Setup
   */
  const dispatch : IDispatchRouting = useMemo(() => {
    return {
      triggers: triggerDispatch,
      conditions: conditionDispatch,
      actions: actionDispatch
    }
  }, [triggerDispatch, conditionDispatch, actionDispatch]);

  const addNode = useCallback((target: INodeTargets, index?: number) => {
    dispatch[target]({ type: 'add', index });
  }, [dispatch]);

  const removeNode = useCallback((target: INodeTargets, index: number) => {
    if(target === 'triggers') {
      dispatch.conditions({type: 'clear'});
    }
    dispatch[target]({ type: 'remove', index })
  }, [dispatch]);

  const setEditingNode = useCallback((target: INodeTargets, index: number, editing:boolean ) => {
    dispatch[target]({ type: 'set_editing', index, editing })
  }, [dispatch]);

  const setEditingRelationNode = useCallback((target: INodeTargets, index: number, editing:boolean ) => {
    dispatch.actions({ type: 'set_editing_relations', index, editing })
  }, [dispatch]);

  const updateNode = useCallback(({target,index,config, configFields, overview}:{target: INodeTargets, index: number, config: any, configFields: (string|boolean|number)[], overview: string }) => {
    dispatch[target]({ type: 'update', index, config, overview, configFields })
    dispatch[target]({ type: 'set_editing', index, editing: false })
  }, [dispatch]);

  const updateRelationNode = useCallback((target: INodeTargets, index: number, links: IPipelineActionRelationLink[] ) => {
    dispatch.actions({ type: 'update_relations', index, links })
    dispatch.actions({ type: 'set_editing_relations', index, editing: false })
  }, [dispatch]);

  const templateNode = useCallback((target: INodeTargets, index: number, subtype: any) => {
    dispatch[target]({ type: 'set_template', index, nodeSubType: subtype , externalData:{cameras}})
  },[cameras, dispatch])

  /**
   * Node Generation
   */
  interface IGenerateColumnNodes {
    reducer: any[];
    type: INodeTargets;
    column: number;
  }
  const generateColumnNodes = (data : IGenerateColumnNodes) => {
    const {reducer, type, column} = data ;

    if(reducer.length === 0){
      return (
        <ColumnNodeContainer colStart={nodeCol(column)} rowStart={nodeRow(1)}>
          <NodeEmptyState title={t(`pipelines.empty.${type}Title`)} description={t(`pipelines.empty.${type}Description`)}>
          </NodeEmptyState>
        </ColumnNodeContainer>
      )
    }
    const usedSubtypes = reducer.map(({subtype})=>subtype);
    return reducer.map((trigger: IPipelineNodeCore, index) => {
      const { title, icon, config, data: {configFields=[]}={}, configTemplate, configTemplateSource, overview, subtype, editing, meta, unsavedNode = false} = trigger;

      return (
        <ColumnNodeContainer key={type + '-' + column + '-' + index} colStart={nodeCol(column)} colEnd={(editing && subtype === 'SendEmail') ? nodeCol(column+2):nodeCol(column)} rowStart={nodeRow(1 + index)}>
          <Node {...{ title, icon, meta, overview, editing }} handlerSetEdit={(!editing && config?.length) ? () => setEditingNode(type, index, true) : null} handlerRemoveNode={() => removeNode(type, index)}>
            { editing && subtype === 'NewNode' &&
              <NodeSectionAdd handlerCancel={ removeNode } handlerUpdate={ templateNode } filterSubtypes={usedSubtypes} {...{index, type}} />}

            { editing && subtype !== 'NewNode' && subtype !== 'SendEmail' &&
              <NodeSectionEdit handlerCancel={ unsavedNode ? removeNode : setEditingNode } handlerUpdate={ updateNode } {...{index, type, config, configFields,configTemplate, configTemplateSource, externalData:{cameras,tags,catagories}}} />
            }

            { editing && subtype === 'SendEmail' &&
              <NodeSectionEditEmail handlerCancel={ unsavedNode ? removeNode : setEditingNode } handlerUpdate={ updateNode } {...{index, type, config, configFields, configTemplate, configTemplateSource, }} />
            }

            { !editing && subtype !== 'NewNode' && subtype !== 'SendEmail' && config &&
              <NodeSectionDetails {...{index, type, config, configFields}} />}
          </Node>
        </ColumnNodeContainer>
      );
    })

  }

  const outputActionRelations = (action: any, index: number) => {
    const hasRelations = typeof action.relations != 'undefined';

    if(hasRelations){
      const {title, icon, overview, relations, type,data:{links=[]}} = action;
      const {editing, linkedType} = relations;

      return (
        <ColumnNodeContainer key={'col-n4-' + index} colStart={nodeCol(4)} rowStart={nodeRow(1 + index)}>
          <Node meta={t('pipelines.headings.actionRelationsTitle')} handlerSetEdit={!editing ? () => setEditingRelationNode('actions', index, true) : null} {...{ title, overview, icon }}>
            { editing &&
                <NodeSectionEditRelations handlerCancel={ () => setEditingRelationNode('actions', index, false) } handlerUpdate={ updateRelationNode } {...{index, type, links, linkedType, cameras, relays}} />}

            { !editing &&
                <NodeSectionDetailsRelations {...{index, type, links}} />}

          </Node>
        </ColumnNodeContainer>
      )
    } else {
      return (
        <LineContainer key={'col-n4-' + index} colStart={nodeCol(4)} rowStart={nodeRow(1 + index)}><Line /></LineContainer>
      )
    }

  }

  const checkActionsReady = useCallback(({actions}: IPipeline ) => {
    const hasActions = actions.length > 0;
    if (!hasActions) {
      setPipelineStatus(prev => ({...prev, actionAdd: false}));
      return false;
    }
    let actionsReady = true, relayLinksAvailable = true;
    for(let i=0; i<actions.length; i++) {
      if (actions[i].editing) {
        setPipelineStatus(prev => ({...prev, actionEdit: false}));
        actionsReady = false;
        break;
      }
      if (actions[i].action_type === 'relay' && !checkActionReady(actions[i])) {
        setPipelineStatus(prev => ({...prev, relayLinks: false}));
        relayLinksAvailable = false;
        break;
      }
    }
    setPipelineStatus(prev => ({...prev, actionAdd: true, actionEdit: actionsReady, relayLinks: relayLinksAvailable}));
    if (!actionsReady || !relayLinksAvailable) {
      return false;
    }
    return true;
  },[])

  const checkTriggersConditionsReady = useCallback(({triggers,conditions}: IPipeline ) => {
    const hasTriggers = triggers.length > 0;
    if (!hasTriggers) {
      setPipelineStatus(prev => ({...prev, triggerAdd: false}));
      return false;
    }
    // Check no triggers are in edit mode.
    const triggersReady = !triggers.some((trigger) => trigger.editing);
    if (!triggersReady) {
      setPipelineStatus(prev => ({...prev, triggerEdit: false}));
      return false;
    }
    // Check no conditions are in edit mode.
    const conditionsReady = !conditions.some(condition => condition.editing);
    if (!conditionsReady) {
      setPipelineStatus(prev => ({...prev, filtersEdit: false}));
      return false;
    }
    setPipelineStatus(prev => ({...prev, triggerAdd: true, triggerEdit: true, filtersEdit: true}));
    return true;
  },[])



  /**
   * Grid Generation
  */
  const outputSpacers = () => {
    // When we uses rowStart and rowEnd together, the behavior changes and things collapse.
    // This is why we generate these extra blank line containers.
    const rows = Math.max(actions.length, conditions.length, triggers.length) + 1;
    let output = [];

    for(let i = 0; i < rows; i++) {
      output.push(<LineContainer key={'spacer-' + i} colStart={lineCol(1)} rowStart={lineRow(i + 1)}></LineContainer>);
    }
    return output;
  }

  useEffect(() => {
    updatePipelineStatus(pipelineStatus);
  }, [pipelineStatus, updatePipelineStatus]);

  useEffect(()=>{
    const triggersConditions = checkTriggersConditionsReady({actions,triggers,conditions});
    const actionsReady = checkActionsReady({actions,triggers,conditions});
    setTriggersConditionsReady(triggersConditions);
    if(triggersConditions && actionsReady){
      updatePipeline({triggers,actions,conditions});
    } else {
      updatePipeline(undefined);
    }
  },[actions, checkActionsReady, checkTriggersConditionsReady, conditions, triggers, triggersConditionsReady, updatePipeline]);

  return (
    <GridContainer>

      {/* Output Column Headers */}
      { MatrixColumns.map((column, index) => {
        const {title, description} = column;
        return (
          <ColumnHeader key={'col-h-' + index} colStart={nodeCol(index+1)} title={t(`pipelines.headings.${title}`)} description={t(`pipelines.headings.${description}`)} />
        );
      })}


      {/* --- Output Nodes --- */}
      {/* Nodes: Triggers */}
      {generateColumnNodes({ reducer: triggers, type: 'triggers', column: 1 })}

      {/* Nodes: Conditions */}
      {generateColumnNodes({ reducer: conditions, type: 'conditions', column: 2 })}

      {/* Nodes: Actions (and relations) */}
      {generateColumnNodes({ reducer: actions, type: 'actions', column: 3 })}
      {actions.map((action, index) => {
        return (outputActionRelations(action, index))
      })}


      {/* --- Output Pipeline Status --- */}
      {actions.length === 0 && (
          <PipelineStatusContainer colStart={nodeCol(5)} rowStart={nodeRow(1)}>
              <Icon icon={'Warning'} size={32} color='subtle' />
          </PipelineStatusContainer>
        )
      }
      {actions.map((action, index) => {
        const ready = checkActionReady(action) && triggersConditionsReady;
        return (
          <PipelineStatusContainer key={'col-n5-' + index} colStart={nodeCol(5)} rowStart={nodeRow(index + 1)}>
            <Icon icon={ready ? 'Success' : 'Warning'} size={32} color={ready ? 'primary' : 'subtle'} />
          </PipelineStatusContainer>
        )
      })}


      {/* --- Output Lines --- */}
      {/* Lines: General Spacers (Need because rowEnd changes behavior) */}
      {outputSpacers()}

      {/* Lines: Triggers -> Conditions */}
      <LineContainer colStart={lineCol(1)} rowStart={nodeRow(1)}><Line /></LineContainer>

      {/* Lines: Conditions Stack */}
      {conditions.map((condition, index, array) => {
        return index < array.length - 1 ? <LineContainer key={'line-n2-' + index} colStart={nodeCol(2)} rowStart={nodeRow(index + 1)} rowEnd={lineRow(index + 2)} ><LineV /></LineContainer> : null;
      })}

      {/* Lines: Conditions -> Actions */}
      {
        actions.length === 0 && <LineContainer colStart={lineCol(2)} rowStart={nodeRow(1)} rowEnd={nodeRow(1)}><Line /></LineContainer>
      }
      {actions.map((action, index) => {
        const finalNodeIndex = conditions.length !== 0 ? conditions.length : 1;
        const sameRow = nodeRow(finalNodeIndex) === nodeRow(index + 1);
        const line = sameRow ? <Line /> : <LineS direction={ nodeRow(conditions.length) <= nodeRow(index) ? 'down' : 'up' } />;
        return <LineContainer key={'line-n3-' + index} colStart={lineCol(2)} rowStart={nodeRow(finalNodeIndex)} rowEnd={nodeRow(index + 1)}>{line}</LineContainer>
      })}

      {/* Lines: Action Stack */}
      {
        actions.length === 0 && <LineContainer colStart={nodeCol(4)} rowStart={nodeRow(1)}><Line /></LineContainer>
      }

      {/* Lines: Actions -> Relations */}
      {
        actions.length === 0 && <LineContainer colStart={lineCol(3)} rowStart={nodeRow(1)} ><Line /></LineContainer>
      }
      {actions.map((action, index) => {
        return <LineContainer key={'line-n5-' + index} colStart={lineCol(3)} rowStart={nodeRow(index + 1)}><Line /></LineContainer>
      })}

      {/* Lines: Relations -> Pipeline Status */}
      {
        actions.length === 0 && <LineContainer colStart={lineCol(4)} rowStart={nodeRow(1)} ><Line /></LineContainer>
      }
      {actions.map((action, index) => {
        return <LineContainer key={'line-n6-' + index} colStart={lineCol(4)} rowStart={nodeRow(index + 1)}><Line /></LineContainer>
      })}


      {/* --- Output Node Buttons --- */}
      {/* Add Button: Detection Trigger */}
      {triggers.length === 0 ?
        <ColumnButtonContainer colStart={nodeCol(1)} rowStart={lineRow(1)} rowEnd={nodeRow(2)}>
          <ButtonWithIcon icon="Add" design='secondary' size='small' position='left' style={{width: '100%'}} onClick={() => addNode('triggers')}>{t('pipelines.buttons.addTrigger')}</ButtonWithIcon>
        </ColumnButtonContainer>
      : null }

      {/* Add Button: Conditions */}
      <ColumnButtonContainer colStart={nodeCol(2)} rowStart={conditions.length > 0 ? lineRow(conditions.length) : lineRow(1)} rowEnd={conditions.length > 0 ? nodeRow(conditions.length + 1) : nodeRow(2)}>
        <ButtonWithIcon icon="Add" design='secondary' size='small' position='left' style={{width: '100%'}} onClick={() => addNode('conditions')}>{t('pipelines.buttons.addCondition')}</ButtonWithIcon>
      </ColumnButtonContainer>

      {/* Add Button: Actions */}
      <ColumnButtonContainer colStart={nodeCol(3)} rowStart={actions.length > 0 ? lineRow(actions.length) : lineRow(1)} rowEnd={actions.length > 0 ? nodeRow(actions.length + 1) : nodeRow(2)}>
        <ButtonWithIcon icon="Add" design='secondary' size='small' position='left' style={{width: '100%'}} onClick={() => addNode('actions')}>{t('pipelines.buttons.addAction')}</ButtonWithIcon>
      </ColumnButtonContainer>

    </GridContainer>
  );
}

export default RulesMatrix;


