import { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PageHeader, TabList, Tabs, Icon, TabContent, useNotification, SelectField } from 'scorer-ui-kit'
import { Container, PageHeaderWrapper } from '../Style'
import styled from 'styled-components';
import SelectFileTab from '../components/upload-csv/SelectFileTab';
import { Prompt } from 'react-router';
import Encoding from 'encoding-japanese';
import CheckAndConfirmTab from '../components/upload-csv/CheckAndConfirmTab';
import { IImportData, useNumberPlateImport } from '../hooks/useNumberPlateImport';
import ProcessTab from '../components/upload-csv/ProcessTab';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
`;

const ImportSelectContainer = styled.div`
  margin-top: auto;
  > div > label {
    margin-bottom: 0px;
  }
`;

const StyledSelectField = styled(SelectField)`
  width: 164px;
`;

const Divider = styled.div`
  height: 1px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.divider};
  margin-top: 16px;
`;

const TabLi = styled.li<{isSelected:boolean}>`
  color: ${({ isSelected }) => isSelected ? '#5a6269' : '#5a62694d'} !important;
`;

const Tab = styled.ul`
  list-style: none;
  display: inherit;
  padding: 0;
   li {
    height: 16px;
    margin: -6px 24px -8px 0;
    font-size: 14px;
    font-weight: 600;
    text-align: right;
    color: #5a62694d;
    div {
      float: right;
      margin-left: 21px;
    }
  }
`;

const validateHeaders = (headers: string[]) => {
  const [name, description, type, user_type, kana, number, area, tags, category] = headers;

  if (name !== 'name') {
    return false;
  }

  if (description !== 'description') {
    return false;
  }

  if (type !== 'type') {
    return false;
  }

  if (user_type !== 'user_type') {
    return false;
  }

  if (kana !== 'kana') {
    return false;
  }

  if (number !== 'number') {
    return false;
  }

  if (area !== 'area') {
    return false;
  }

  if (tags !== 'tags') {
    return false;
  }

  if (category !== 'category') {
    return false;
  }

  return true;
}

export interface IImportCount {
  imported: number,
  total: number
}

const NumberPlateImport: FC<{}> = () => {
  const { t } = useTranslation(['NumberPlateImport', 'NumberPlates', 'Common']);
  const [selectedTab, setSelectedTab] = useState<string>('selectFiles');
  const [continueShow, setContinue] = useState<boolean>(false);
  const {loadingCsvData, loadingImport, csvData, errorMessage, actions: {uploadCsv, importCsv}} = useNumberPlateImport();
  const [importMode, setImportMode] = useState<string>('patch');
  const [message, setMessage] = useState<string>('');
  const [importCount, setImportCount] = useState<IImportCount>({
    imported: 0,
    total: 0
  });
  const { sendNotification } = useNotification();

  const handleCsvUpload = useCallback(async (text: string) => {
    setSelectedTab('checkAndConfirm');
    try {
      const result = await uploadCsv(text);
      if (!result) {
        setSelectedTab('selectFiles');
        sendNotification({type: 'error', message: t('unknownLoadingError')});
      }
    } catch (error) {
      console.error(error);
      setSelectedTab('selectFiles');
      sendNotification({type: 'error', message: t('unknownLoadingError')});
    }
    setContinue(true);
  }, [t, uploadCsv, sendNotification]);

  const importData = useCallback(async (payload: IImportData) => {
    setSelectedTab('process');
    try {
      await importCsv(payload);
      if (!errorMessage) {
        const totalImport = payload.new_entries.length + payload.updated_entries.length;
        setImportCount({
          imported: totalImport,
          total: totalImport
        });
      }
    } catch (error) {
      console.error(error);
    }
    setContinue(false);
  }, [ errorMessage, importCsv]);

  const handleCancel = useCallback(() => {
    setContinue(false);
    setSelectedTab('selectFiles');
  }, []);

  const onFileSelect = useCallback((file: File) => {

    if (!file.name.match(/.+(\.csv)$/i)) {
      sendNotification({type: 'error', message: t('NumberPlates:invalidFile')});
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = e => {
      if (e === null || e.target === null || e.target.result === null || !(e.target.result instanceof ArrayBuffer)) {
        sendNotification({type: 'error', message: t('NumberPlates:invalidFile')});
        return;
      } else {

        const { result } = e.target;
        if (result.byteLength === 0) {
          sendNotification({type: 'error', message: t('NumberPlates:emptyFile')});
          return;
        }

        const codes = new Uint8Array(result);
        const detectedEncoding = Encoding.detect(codes);
        if(detectedEncoding === false){
          sendNotification({type: 'error', message: t('NumberPlates:invalidFileEncoding')})
          return;
        }

        const output = Encoding.convert(codes, {
          to: 'UNICODE',
          from: detectedEncoding,
          type: 'string'
        });

        const [h, ...rows] = output.replace(/\r\n|\r/g, '\n') // CRLF => LF
                           .replace(/^\uFEFF/gm, '') //remove UTF BOM chars
                           .replace(/^\u00BB\u00BF/gm,'') //remove UTF BOM chars
                           .split('\n');
        const headers: string[] = h.split(new RegExp(',(?=(?:[^"]*"[^"]*")*[^"]*$)')).map((value) => value.toLowerCase());

        if (!validateHeaders(headers)) {
          sendNotification({type: 'error', message: t('NumberPlates:invalidHeaders')});
          return;
        }

        const csvText = headers.join(',') +
        '\n' +
        rows.join('\n');

        handleCsvUpload(csvText);
      }
    };
  }, [t, sendNotification, handleCsvUpload]);

  const keepOnPage = useCallback((e) => {
    const message = '';
    e.preventDefault();
    e.returnValue = message;
    return message;
  }, []);

  useEffect(() => {
    if (selectedTab === 'checkAndConfirm') {
      window.addEventListener('beforeunload', keepOnPage);
    }
    const detectBrowser = () => {
      if(navigator.userAgent.indexOf('Chrome') !== -1 || navigator.userAgent.indexOf('Firefox') !== -1)
        return setMessage('changesCancelConfirm');
    };
    detectBrowser();
    return () => {
      window.removeEventListener('beforeunload', keepOnPage);
    };
  }, [keepOnPage, selectedTab]);

  useEffect(() => {
    const importMode = localStorage.getItem('numberImportMode');    
    if (!importMode) return;
    setImportMode(importMode);
  }, []);

  const onModeChange = useCallback((e) => {
    setImportMode(e);
    localStorage.setItem('numberImportMode', e);
  }, []);

  return (
    <Container>
      <Prompt
        when={continueShow}
        message={() => t(message)}
      />
      <HeaderContainer>
        <PageHeaderWrapper>
          <PageHeader icon='ViewSettings' title={t('newCsvImport')} areaTitle={t('settings')} areaHref='#' />
        </PageHeaderWrapper>
        {
          selectedTab !== 'selectFiles' && <ImportSelectContainer>
            <StyledSelectField
              name='importType'
              changeCallback={onModeChange}
              isCompact
              value={importMode}
              disabled={loadingCsvData || selectedTab === 'process'}
              label={{htmlFor: 'importType', text: t('importType')}}
            >
              <option value='patch'>{t('patchData')}</option>
              <option value='sync'>{t('synchronization')}</option>
            </StyledSelectField>
          </ImportSelectContainer>}
      </HeaderContainer>

      <Tabs>
        <TabList defaultTabId={selectedTab}>
          <Tab>
            <TabLi isSelected={selectedTab === 'selectFiles'}>1. {t('selectionFile')}
              <Icon icon='Right' color='subtle' size={9} />
            </TabLi>
            <TabLi isSelected={selectedTab === 'checkAndConfirm'}>2. {t('checkConfirm')}
              <Icon icon='Right' color='subtle' size={9} />
            </TabLi>
            <TabLi isSelected={selectedTab === 'process'}>3. {t('process')}</TabLi>
          </Tab>
        </TabList>
        <Divider />

        <TabContent tabId='selectFiles'>
          <SelectFileTab {...{onFileSelect}} />
        </TabContent>

        <TabContent tabId='checkAndConfirm'>
          <CheckAndConfirmTab {...{importMode, importData, csvData, loadingCsvData, handleCancel}} />
        </TabContent>

        <TabContent tabId='process'>
          <ProcessTab {...{loadingImport, errorMessage, importCount}} />
        </TabContent>
      </Tabs>
    </Container>
  )
}

export default NumberPlateImport;
