import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Content,
  FilterBar,
  IFilterDatePicker,
  IFilterDropdownConfig,
  PageHeader,
  TypeTable,
  ActionButtons,
  IconButtonData,
  DateInterval,
  IFilterResult,
  IFilterItem,
  useModal,
  ConfirmationModal,
  useNotification,
  ISearchFilter
} from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import Pagination from '../components/Pagination';
import { INITIAL_ROWS } from '../constants';
import { downloadFile, downloadMultipleFiles, dropdownHelper, getActions, getFilteredOptions, getMetaDetectionList, getMetaDetectionVehicle, getSelected, getSelectedDate, readParams, updateParams } from '../utils';
import LinkCell from '../components/atoms/LinkCell';
import { useCameras } from '../hooks/useCameras';
import { useLocations } from '../hooks/useLocations';
import { useZones } from '../hooks/useZones';
import { useHistory, useLocation } from 'react-router-dom';
import LongTextCell from '../components/atoms/LongTextCell';
import { Detection, IFetchDetectionOptions, useDetections } from '../hooks/useDetections';
import { AlgorithmType } from '../hooks/useCameraConfig';
import TableCellRelativeTime from '../components/atoms/TableCellRelativeTime';
import TableThumbnail from '../components/atoms/TableThumbnail';
import i18n from '../i18n';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const TableContainer = styled.div`
  > div > div > div > :first-child {
    width: 105px;
    ::after {
      display: none;
    }
  }
`;

const PageHeaderWrapper = styled.div`
  max-width: 610px !important;
`;

const FilterWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
`;

interface IRichDetections extends Detection {
  name?: string,
  zone_name?: string,
  location_name?: string
}

export interface ISelectedFilterValues {
  [key: string]: DateInterval | string | undefined | Date | IFilterItem[] | boolean
}

const initialFilterValues: ISelectedFilterValues = {
  type: undefined,
  when: undefined,
  location: undefined,
  alertStatus: undefined,
  zone: undefined,
  camera: undefined,
  number: undefined,
  sortDirection: 'asc',
  sortBy: 'datetime',
  paramsLoaded: false
}

const sizeOptions = [10, 20, 50, 100];

const KnownVehiclesDetection: React.FC<{}> = () => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const [richDetections, setRichDetections] = useState<IRichDetections[]>([]);
  const { locations, loading: locationsLoading, actions: { fetchLocations } } = useLocations();
  const { zones, loadingZones, actions: { fetchZones } } = useZones();
  const { detections, detectionCount, isLoading, actions: { fetchDetections, deleteDetection } } = useDetections();
  const { sendNotification } = useNotification();

  const params = useLocation().search;
  const [historyParams] = useState(params);

  const [dropdownConfig, setDropdownConfig] = useState<IFilterDropdownConfig[]>();

  const { cameras, loading: loadingCameras, actions: { fetchCameras } } = useCameras();

  const { push } = useHistory();
  const { createModal } = useModal();
  const { t } = useTranslation(['KnownVehiclesDetection', 'Common']);

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };

  const tableColumns: ITableColumnConfig[] = [
    {
      header: '',
      cellStyle: 'normalImportance',
      columnId: 'thumbnail',
      minWidth: 105
    },
    {
      groupTitle: t('meta'),
      header: t('type'),
      cellStyle: 'firstColumn',
      columnId: 'message'
    },
    {
      groupTitle: t('meta'),
      header: t('numberPlate'),
      cellStyle: 'firstColumn',
    },
    {
      groupTitle: t('eventLocation'),
      header: t('location'),
      sortable: true,
      cellStyle: 'normalImportance',
      columnId: 'camera_zone_location_name',
      sortActive: selectedFilterValues.sortBy ?  (selectedFilterValues.sortBy === 'camera_zone_location_name') : false
    },
    {
      groupTitle: t('eventLocation'),
      header: t('zone'),
      sortable: true,
      cellStyle: 'normalImportance',
      columnId: 'camera_zone_name',
      sortActive: selectedFilterValues.sortBy ?  (selectedFilterValues.sortBy === 'camera_zone_name') : false
    },
    {
      groupTitle: t('eventLocation'),
      header: t('camera'),
      sortable: true,
      cellStyle: 'normalImportance',
      columnId: 'camera_name',
      sortActive: selectedFilterValues.sortBy ?  (selectedFilterValues.sortBy === 'camera_name') : false
    },
    {
      header: t('when'),
      sortable: true,
      cellStyle: 'lowImportance',
      columnId: 'datetime',
      sortActive: selectedFilterValues.sortBy ?  (selectedFilterValues.sortBy === 'datetime') : false
    },
    {
      header: t('actions'),
      cellStyle: 'normalImportance',
    }
  ];

  const getIcon = (subject_type: string): string => {
      switch (subject_type) {
        case 'LISTED':
          return 'VehicleWhitelist'

        case 'IMPORTANT':
          return 'VehicleBlacklist'

        default:
          return 'VehicleUnknown'
      }
  }

  const getDropdownConfig = useCallback(() => {

    const validDetectionTypes: AlgorithmType[] = ['NUMBER_PLATE', 'CAR_COUNTING'];
    const { filteredZones, filteredCameras } = getFilteredOptions(selectedFilterValues, zones, cameras, validDetectionTypes);

    return ([
      {
        id: 'type',
        buttonText: t('typeDetected'),
        list: getMetaDetectionList(),
        buttonIcon: 'VehicleUnknown',
        optionType: 'radio',
        selected: getSelected(selectedFilterValues.type)
      },
      {
        id: 'location',
        buttonText: t('location'),
        list: dropdownHelper(locations, { textKey: 'name', valueKey: 'id' }),
        buttonIcon: 'Location',
        optionType: 'checkbox',
        loadingText: t('loadingLocations'),
        hasOptionsFilter: true,
        isLoading: locationsLoading,
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      },
      {
        id: 'zone',
        buttonText: t('zone'),
        list: dropdownHelper(filteredZones, { textKey: 'name', valueKey: 'id' }),
        buttonIcon: 'Zone',
        optionType: 'checkbox',
        loadingText: t('loadingZones'),
        hasOptionsFilter: true,
        isLoading: loadingZones,
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      },
      {
        id: 'camera',
        buttonText: t('camera'),
        list: dropdownHelper(filteredCameras, { textKey: 'name', valueKey: 'id' }),
        buttonIcon: 'Camera',
        optionType: 'checkbox',
        loadingText: t('loadingCameras'),
        hasOptionsFilter: true,
        isLoading: loadingCameras,
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      }
    ]);
  }, [locationsLoading, t, locations, zones, loadingZones, loadingCameras, cameras, selectedFilterValues]);

  
  const searchers: ISearchFilter[] = [
    {
      id: 'number-search',
      placeholder: t('filterByNumber'),
      name: t('numberPlate')
    }
  ];

  const datePickers: IFilterDatePicker[] = [
    {
      id: 'when',
      buttonIcon: 'DateTime',
      buttonText: t('when'),
      dateMode: 'interval',
      timeMode: 'interval',
      selected: getSelectedDate(selectedFilterValues.when) as DateInterval | undefined,
      dateTimeTextLower: t('Common:dateTo'),
      dateTimeTextUpper:  t('Common:dateFrom'),
      timeZoneTitle: t('Common:timeZone'),
      lang: i18n.language === 'ja' ? 'ja' : 'en',
    }
  ];

  useEffect(() => {
    setSelectedFilterValues(readParams(historyParams, initialFilterValues));
  }, [historyParams]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    push({ search: updateParams(selectedFilterValues) });
  }, [push, selectedFilterValues]);

  useEffect(() => {
    setDropdownConfig(getDropdownConfig() as IFilterDropdownConfig[]);
  }, [getDropdownConfig, locationsLoading, locations, zones, loadingZones, loadingCameras, cameras, selectedFilterValues]);

  useEffect(() => {
    fetchLocations();
    fetchZones();
  }, [fetchLocations, fetchZones]);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);

  const getApiParams = useCallback((): IFetchDetectionOptions => {

    const { when, type, location, zone, camera, number, sortDirection = 'asc', sortBy = 'datetime' } = selectedFilterValues;

    const correctedUISort = sortBy === 'datetime'
    ? sortDirection === 'asc' ? 'desc' : 'asc'
    : sortDirection

    return ({
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      startTime: (when as DateInterval)?.start,
      endTime: (when as DateInterval)?.end,
      algorithm: 'NUMBER_PLATE,CAR_COUNTING',
      locationId: location as string,
      zoneId: zone as string,
      cameraId: camera as string,
      sortBy: sortBy as string,
      sortDirection: correctedUISort as string,
      subjectType: type as string,
      subjectNumber: number as string
    });

  }, [pageSize, currentPage, selectedFilterValues]);

  const handleDelete = useCallback(async (detectionId: number) => {
    const result = await deleteDetection(detectionId);
    if (result) {
      sendNotification({ type: 'success', message: t('deleteSuccess') })
      fetchDetections(getApiParams());
    } else {
      sendNotification({ type: 'error', message: t('deleteError') })
    }
  }, [deleteDetection, fetchDetections, getApiParams, sendNotification, t])

  const openConfirmationModal = useCallback((detectionId: number) => {
    createModal({
      isCloseEnable: false,
      customComponent: (
        <ConfirmationModal
          title={t('Common:confirmEventDeletion')}
          message={t('Common:deleteAlertMsg')}
          leftButtonText={t('Common:cancel')}
          leftButtonDesign='secondary'
          rightButtonText={t('Common:delete')}
          rightButtonDesign='danger'
          rightButtonCallback={() => handleDelete(detectionId)}
        />
      )
    });
  }, [createModal, t, handleDelete]);

  const generateConfigButtons = useCallback((alertId: number, movie_url, algorithm, detection, image_url, cropped_image_url): IconButtonData[] => {

    const baseActions = [
      {
        icon: 'Delete',
        onClick: () => openConfirmationModal(alertId),
      },
      {
        icon: 'DownloadImage',
        onClick: () => downloadMultipleFiles([image_url, cropped_image_url]),
      },
      {
        icon: 'DownloadVideo',
        ...(!movie_url ? {
          color: 'inverse',
          disabled: true,
          hoverColor: 'inverse',
        }: {
          onClick: () => downloadFile(movie_url),
        }),
      }
    ];

    const addAction = getActions(algorithm, detection, image_url, push);

    return [...addAction, ...baseActions];

  }, [openConfirmationModal, push]);

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = (richDetections ?? []).map((alert) => {

      const {
        id,
        movie_url,
        image_url,
        cropped_image_url,
        datetime,
        name = '-',
        zone_name = '-',
        location_name = '-',
        camera_id,
        detection,
        algorithm
      } = alert;
      const {
        subject_area = '-',
        subject_number = '',
        subject_kana = '',
        subject_user_type = '',
        subject_type = '-'
      } = detection || {};
      const mediaUrl = image_url;
      const mediaType = 'img';
      const icon = getIcon(subject_type);

      const row: IRowData = {
        columns: [
          {customComponent: <TableThumbnail {...{id, cropped_image_url, mediaUrl, mediaType, icon }} />},
          { text: getMetaDetectionVehicle(subject_type) },
          { text: `${subject_area}${subject_user_type} ${subject_kana}${subject_number}` },
          { customComponent: <LongTextCell text={location_name} /> },
          { customComponent: <LongTextCell text={zone_name} /> },
          { customComponent: <LinkCell text={name ?? '-'} id={camera_id} fromPage='knownVehicles' /> },
          { customComponent: <TableCellRelativeTime eventTime={datetime.toString() } /> },
          { customComponent: <ActionButtons buttonsConfig={generateConfigButtons(id, movie_url, algorithm, detection, image_url, cropped_image_url)} /> },
        ]
      };
      return row;
    });
    return data.length ? data : INITIAL_ROWS;
  }, [generateConfigButtons, richDetections]);

  useEffect(() => {
    if (isLoading) return;
    setRowData(generateRowData());
  }, [generateRowData, richDetections, isLoading]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    fetchDetections(getApiParams());
  }, [getApiParams, pageSize, currentPage, selectedFilterValues, fetchDetections]);

  const getRichDetections = useCallback(() => {
    if (!detections) return [];

    return detections.map(({ camera_id, ...rest }) => {

      const index = cameras.findIndex((camera) => camera.id === camera_id);

      if (index !== -1) {
        const { name, zone_name, location_name } = cameras[index];
        return ({
          camera_id,
          ...rest,
          name,
          zone_name,
          location_name
        })
      }

      return ({
        camera_id,
        ...rest,
      });
    }) as IRichDetections[];

  }, [cameras, detections]);

  useEffect(() => {
    fetchCameras();
  }, [fetchCameras])

  useEffect(() => {
    setRichDetections(getRichDetections())
  }, [detections, cameras, getRichDetections]);

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {
    setCurrentPage(1);
    const selectedValues: ISelectedFilterValues = res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {

      switch (id) {
        case 'number-search': {
          const {value} = selected as IFilterItem;
          selectedValues.number = value as string;
          break;
        }
        case 'type': selectedValues[id] = (selected as IFilterItem).value as string;
          break;
        case 'when': selectedValues[id] = selected as DateInterval;
          break;
        default:
          selectedValues[id] = (selected as IFilterItem[]).map(({ value }) => value).join(',');
      }
      return selectedValues;
    },
      { ...initialFilterValues, paramsLoaded: selectedFilterValues.paramsLoaded }
    );
    setSelectedFilterValues({ ...selectedValues });
  }, [selectedFilterValues]);

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrentPage(parseInt(val));
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(detectionCount / pageSize));
  }, [detectionCount, pageSize]);

  return (
    <Container>
      <PageHeaderWrapper>
        <PageHeader icon='ProductLogs' title={t('vehiclesDetection')} areaTitle={t('detectionLogs')} areaHref='#' introductionText={t('introText')} />
      </PageHeaderWrapper>

      <FilterWrapper>
        {dropdownConfig &&
          <FilterBar
            searchersConfig={searchers}
            dropdownsConfig={dropdownConfig}
            datePickersConfig={datePickers}
            filtersTitle={t('Common:filter.filters') + ':'}
            resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
            resultsDateFormat={'yyyy/MM/dd HH:mm'}
            totalResults={rowData.length === 1 && rowData[0].columns.length === 0 ? 0 : rowData.length}
            onChangeCallback={onChangeFilterValue}
          />}
      </FilterWrapper>

      <TableContainer>
        <TypeTable
          columnConfig={tableColumns}
          rows={rowData}
          isLoading={isLoading}
          emptyTableText={t('Common:noDetectionsFound')}
          loadingText={t('Common:loadingData')}
          sortCallback={sortCallback}
          hasHeaderGroups
          defaultAscending={ selectedFilterValues.sortDirection === 'asc' ? true : false }
        />
      </TableContainer>

      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={sizeOptions}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}

    </Container>
  );
};

export default KnownVehiclesDetection;