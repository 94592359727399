import { FC } from 'react';
import { useTo } from 'scorer-ui-kit';
import styled from 'styled-components';
import { Camera } from '../hooks/useCameras';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 306px;
  height: 246px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.04);
  background-color: hsla(0,0%,84%,0.1);
`;

const CameraImage = styled.img`
  width: 100%;
  height: 170px;
  background-color: hsla(0,0%,84%,0.2);
  border-radius: 3px;
  border: none;
  // object-fit: contain;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 11px 10px 0 12px;
`;

const Location = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  // font-style: italic;
  letter-spacing: 0.18px;
  margin-bottom: 8px;
`;

const CameraName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 16px;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const CameraCard: FC<Camera> = ({ name, location_name, zone_name, id, scorer_name}) => {
  const to = useTo();
  const IMG_URL = `/snapshot/api/v1/stacks/${scorer_name}/snapshot?timestamp=${Math.ceil(Date.now()/1000)}`;

  return (
    <Container onClick={to(`/cameras/${id}`)}>
      <CameraImage src={IMG_URL} />

      <FooterContainer title={location_name + ' > ' + zone_name + '\n' + name}>
        <Location>{location_name} &gt; {zone_name}</Location>
        <CameraName>{name}</CameraName>
      </FooterContainer>
    </Container>
  );
}

export default CameraCard