import { useCallback, useContext, useEffect, useState } from 'react';
import { IFeatures, FeaturesContext } from '../context/FeaturesContext';



interface IUserResponse {
  message: string
  features: IFeatures
}


export const useUser = () => {
  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [featuresLoaded, setFeaturesLoaded] = useState<boolean | null>(false);

  const [userErrorMessage, setUserErrorMessage] = useState('');
  const { features , setFeatures } = useContext(FeaturesContext);

  const checkLoggedIn = useCallback(async () => {
    try {
      const response = await fetch('/api/authenticated');

      if (response.ok) {
        const { features } = await response.json() as IUserResponse;
        setLoggedIn(true);
        setFeatures(features);
        setFeaturesLoaded(true);
      } else {
        setLoggedIn(false);
        setUserErrorMessage(`${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setLoggedIn(false);
        setUserErrorMessage(error.message);
      } else {
        console.error(error);
        setUserErrorMessage('Unknown Error fetching user action');
      }
    }
  }, [setFeatures]);

  useEffect(() => {
    checkLoggedIn();
  }, [checkLoggedIn]);


  const login = useCallback(async ({ username, password }) => {
    const response = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password })
    });

    if (response.ok) {
      setLoggedIn(true);
      checkLoggedIn()
    } else {
      const { message = 'Error Logging in.' } = await response.json();
      throw new Error(message);
    }
  }, [checkLoggedIn]);

  const logout = useCallback(async () => {
    setUserErrorMessage('')
    const response = await fetch('/api/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      setLoggedIn(false);
      window.location.reload();
    } else {
      const { message = 'Error Logging out.' } = await response.json();
      setUserErrorMessage(message)
      console.error(message);
    }
  }, [])


  return {
    features,
    loggedIn,
    featuresLoaded,
    userErrorMessage,
    actions: {
      login,
      logout
    }
  }
}