import React, { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import { LineUI, LineReducer, LineSetContext, Label, useNotification, ButtonWithLoading, IconButton, ButtonWithIcon } from 'scorer-ui-kit';
import { IPointSet, IVector2, LineUIOptions } from 'scorer-ui-kit/dist/LineUI';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { ICamConfigPayload, ICameraConfig, ILineConfig, useCameraConfig } from '../../hooks/useCameraConfig';
import { useParams } from 'react-router-dom';
import { IParams } from '../../types';
import { getDetectionTypeLocal } from '../../utils';
import { Camera } from '../../hooks/useCameras';
import LineNameEdit from './LineNameEdit';
import { ArrowSVGBold, ArrowUp, ArrowDown } from '../../svg';

const Container = styled.div`
  display: flex;
  margin: 30px 0 20px 0;
`;

const Title = styled.div`
  margin-bottom: 30px;
`;

const LeftSection = styled.div`
  min-width: 500px;
`;

const RightSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  > div, img {
    max-height: 500px;
  }
`;

const ArrowUpContainer = styled.div<{isSelected: boolean}>`
  > svg {
    width: auto;
    height: 50px;
    opacity: ${({ isSelected }) => !isSelected ? 1 : 0.8};
    filter: drop-shadow(1px 3px 4px black);
    > * {
      fill: ${({ isSelected }) => !isSelected ? '#4db5ff' : '#8c9297'};
    }
  }
`;

const ArrowDownContainer = styled.div<{isSelected: boolean}>`
  margin-left: -8px;
  perspective: 12px;
  > svg {
    width: auto;
    height: 50px;
    transform-style: preserve-3d;
    transform: rotate3d(6,-2,1,3deg);
    filter: drop-shadow(1px 3px 4px black);
    opacity: ${({ isSelected }) => !isSelected ? 1 : 0.8};
    > * {
      fill: ${({ isSelected }) => !isSelected ? '#4db5ff' : 'hsl(207,5%,57%)'};
    }
  }
`;

const ArrowUpCheckbox = styled(ArrowSVGBold)`
  width: 85%;
  height: 85%;
  fill: hsla(0,0%,25.1%,1.000);
  fill: ${({theme}) => theme.colors.icons.inverse};
  transform: rotate(180deg);
  display: none;
`;

const ArrowDownCheckbox = styled(ArrowSVGBold)`
  width: 85%;
  height: 85%;
  fill: hsla(0,0%,25.1%,1.000);
  fill: ${({theme}) => theme.colors.icons.inverse};
  display: none;
`;

const ArrowCheckboxContainer = styled.div<{isSelected: boolean}>`
  cursor: pointer;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: 2px solid hsla(208,24.6%,77.6%,1.000);
  :hover {
    border-color: hsla(195,94.2%,66.1%,1.000);
  }
  ${({ isSelected }) => isSelected && css`
    box-shadow: inset 0px 1px 5px 0px hsla(205,50.3%,30%,0.051);
    background-color: hsla(207,95.3%,66.5%,1.000);
    border: none;
    :hover {
      background-color: hsla(195,95.3%,66.5%,1.000);
    }
    > svg {
      display: block;
    }
  `}
`;

const DirectionIconContainer = styled.div`
  min-width: 50px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 1;
  pointer-events: none;
  display: flex;
  gap: 15px 0px;
`;

const DirectionInputs = styled.div`
  margin-top: 30px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px 40px;
`;

const DirectionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0 12px;
  > label {
    width: 275px;
    margin-bottom: 0;
    > span {
      margin-bottom: 0;
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;
`;

const DefinedLineOrAreaGroup = styled.div`
  margin-top: 0px;
`;

const LineOrAreaValue = styled.div`
  color: #acb4bc;
  margin-bottom: 20px;
`;

const FieldsGroup = styled.div`
  margin: 20px 0 40px 0;
`;

const LineDescription = styled.div`
  font-size: 12px;
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
`;

const LinePoints = styled.div``;
const LineInfo = styled.div``;
const LineActions = styled.div``;

const NoImage = styled.div`
  background: #dddddd;
  width: 100%;
  height: 400px;
  font-size: 30px;
  color: ${({ theme }) => theme.colors.divider};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HiddenImage = styled.img`
  display: none;
`;

const generatePointsText = (points: IVector2[]): string => {
  return points.reduce((result, value, index) => {
    return result + `(${value.x},${value.y}) ${index === (points.length - 1) ? '' : ','}`
  }, '');
}

const getLineConfig = (state: IPointSet[]): ILineConfig[] => {
  return state.map((pointSet) => {
    const newLine: ILineConfig = { name: pointSet.name || '', points: pointSet.points }
    return newLine
  })
}

const defaultLinePoints = [
  {
    x: 100,
    y: 100
  },
  {
    x: 200,
    y: 200
  }
]

const defaultShapePoints = [
  {
    x: 100,
    y: 100
  },
  {
    x: 100,
    y: 200
  },
  {
    x: 200,
    y: 200
  },
  {
    x: 200,
    y: 100
  },
]


const isPolygon = (algorithm: string): boolean => {
  switch (algorithm) {
    case 'NUMBER_PLATE':
    case 'FACE_IDENTIFICATION':
    case 'FACE_ANALYTICS':
    case 'INTRUSIONS':
      return true

    case 'CAR_COUNTING':
    case 'PEOPLE_COUNTING':
    default:
      return false
  }
}

interface Props {
  camera?: Camera
}

const CameraConfigTab: React.FC<Props> = ({ camera }) => {
  const { t } = useTranslation(['CameraDetails', 'Common']);
  const [state, dispatch] = useReducer(LineReducer, []);
  const { cameraID }: IParams = useParams();
  const { actions: { getCameraConfigsByCameraID, updateCameraConfig, createCameraConfig } } = useCameraConfig();
  const { sendNotification } = useNotification();
  const [isSaving, setIsSaving] = useState(false);
  const [hasImageLoad, setHasImageLoad] = useState(false);
  const [initialConfig, setInitialConfig] = useState<ICameraConfig | null>(null)
  const { detection_types = null } = camera || {}
  const [excludeDirection, setExcludeDirection] = useState({
    rear: false,
    front: false
  });
  const [showDirectionIcons, setShowDirectionIcons] = useState(false);

  const imgRef = useRef<HTMLImageElement>(null);

  const IMG_URL = `/snapshot/api/v1/stacks/RTSPcam${cameraID}/snapshot?timestamp=${Math.ceil(Date.now()/1000)}`;

  // Testing image
  // const IMG_URL = 'https://i.picsum.photos/id/1026/4621/3070.jpg?hmac=OJ880cIneqAKIwHbYgkRZxQcuMgFZ4IZKJasZ5c5Wcw';

  const [options, setOptions] = useState<LineUIOptions>({
    showSetIndex: false,
    pointIndexOffset: 1,
    showPointLabel: true,
    setIndexOffset: 1,
    showMoveHandle: true,
    showPointHandle: true,
    boundaryOffset: 0,
    showDirectionMark: false
  });

  const setupConfigs = useCallback(async () => {
    if (cameraID && detection_types) {
      const configs = await getCameraConfigsByCameraID(parseInt(cameraID)) || [];
      const config = configs.find(({ algorithm_type }) => algorithm_type === detection_types);
      if (config) {
        const state = config.line_config.map((line) => {
          return { readOnly: false, styling: 'primary', name: line.name, points: line.points }
        });

        const { algo_configs } = config;
        if (algo_configs?.exclude_rear_numberplate !== undefined && algo_configs?.exclude_front_numberplate !== undefined) {
          setExcludeDirection({
            rear: algo_configs.exclude_rear_numberplate,
            front: algo_configs.exclude_front_numberplate
          });
        }

        setOptions((prev) => ({ ...prev, showDirectionMark: !isPolygon(detection_types) }))
        setInitialConfig(config);
        dispatch({
          type: 'LOAD',
          state
        });

      } else {
        // base empty
        setOptions((prev) => ({ ...prev, showDirectionMark: !isPolygon(detection_types) }))

        dispatch({
          type: 'LOAD',
          state: []
        });

      }
    }

  }, [cameraID, detection_types, getCameraConfigsByCameraID])

  const isValidForSave = useCallback((): boolean => {

    const camera_id = parseInt(cameraID);

    let allHaveName = true;
    state.forEach((pointSet) => {
      if (!pointSet.name) {
        allHaveName = false;
      }
    })

    if (!allHaveName) {
      sendNotification({ type: 'error', message: t('configTab.noNameError') })
      return false;
    }


    if ((typeof camera_id !== 'number') || detection_types === null) {
      return false;
    }

    return true;
  }, [cameraID, detection_types, sendNotification, state, t])

  const handleSave = useCallback(async () => {
    const camera_id = parseInt(cameraID);

    const valid = isValidForSave();
    if (!valid) {
      return;
    }

    if (detection_types === null) {
      return;
    }

    setIsSaving(true);
    const line_config: ILineConfig[] = getLineConfig(state);
    const {rear = false, front= false} = excludeDirection;

    const newConfig: ICamConfigPayload = {
      camera_id,
      line_config,
      algorithm_type: detection_types,
      algo_configs: detection_types !== 'NUMBER_PLATE' ? {} : {
        exclude_rear_numberplate: rear,
        exclude_front_numberplate: front
      }
    };

    if (initialConfig) {
      const result = await updateCameraConfig(initialConfig.id, newConfig);
      if (result) {
        sendNotification({ type: 'success', message: t('configTab.updateSuccess') })
      } else {
        sendNotification({ type: 'error', message: t('configTab.updateError') })
      }
    } else {
      const config = await createCameraConfig(newConfig);
      if (config) {
        sendNotification({ type: 'success', message: t('configTab.newConfigSaved') })
        if (config) {
          setInitialConfig(config);
        }
      } else {
        sendNotification({ type: 'error', message: t('configTab.newConfigFailed') })
      }
    }

    setIsSaving(false);
  }, [excludeDirection, cameraID, createCameraConfig, detection_types, initialConfig, isValidForSave, sendNotification, state, t, updateCameraConfig]);

  const handleImageLoad = useCallback(async () => {
    if (!imgRef.current) {
      return;
    }
    const fixedImgDimensions = {
      x: imgRef.current.naturalWidth,
      y: imgRef.current.naturalHeight
    }
    setOptions((prev) => ({ ...prev, fixedImgDimensions }))
    setHasImageLoad(true);
  }, [])

  useEffect(() => {
    setupConfigs()
  }, [setupConfigs]);

  const removeSet = useCallback((index = 0) => {
    dispatch({
      type: 'REMOVE_SET',
      index
    });
  }, []);

  const addSet = useCallback(() => {
    const points = detection_types && isPolygon(detection_types) ? defaultShapePoints : defaultLinePoints;
    const last = state.length + 1;
    dispatch({
      type: 'ADD_SET',
      data: {
        name: `${detection_types && isPolygon(detection_types) ? t('configTab.areaName') : t('configTab.lineName')} ${last}`,
        points
      }
    });
  }, [detection_types, state.length, t]);

  const updateName = useCallback((index: number, name: string) => {

    dispatch({
      type: 'RENAME_SET',
      index,
      data: {
        name,
      }
    })

  }, [])

  const generateLineOrAreaComponents = useCallback(() => {
    return state.map((pointSet, index) => (
      <LineDescription key={index}>
        <LineInfo>
          <LineNameEdit {...{ index }} name={pointSet.name} onSave={updateName} />
          <LinePoints>{generatePointsText(pointSet.points)}</LinePoints>
        </LineInfo>
        <LineActions>
          <IconButton icon='Delete' size={24} color='dimmed' hoverColor='danger' onClick={() => removeSet(index)} />
        </LineActions>
      </LineDescription>
    ))
  }, [removeSet, state, updateName])

  return (
    <Container>
      <LeftSection>
        {detection_types && <Title>{getDetectionTypeLocal(detection_types)}</Title>}
        <FieldsGroup>
          <DefinedLineOrAreaGroup>
            <Label
              htmlFor='definedLineOrArea'
              labelText={detection_types && isPolygon(detection_types) ? t('configTab.definedArea') : t('configTab.definedLine')} 
            />
            <LineOrAreaValue>{state && state[0]?.points ? generateLineOrAreaComponents() : '-'}</LineOrAreaValue>
            {detection_types && <ButtonWithIcon
              icon='Add'
              position='left'
              size='small'
              design='secondary'
              onClick={addSet}
            >{isPolygon(detection_types) ? t('configTab.addShape') : t('configTab.addLine')}
            </ButtonWithIcon>}
          </DefinedLineOrAreaGroup>
          {detection_types === 'NUMBER_PLATE' ? <DirectionInputs>
            <Label
              htmlFor='directionInputs'
              labelText={t('configTab.detectionExclusion')} 
            />
            <CheckboxContainer>
              <DirectionContainer>
                  <ArrowCheckboxContainer isSelected={excludeDirection.rear} title='' onClick={() => setExcludeDirection(prev => ({...prev, rear: !prev.rear}))}>
                    <ArrowUpCheckbox />
                  </ArrowCheckboxContainer>
                  <Label htmlFor='' labelText={t('configTab.exludeRearLabel')} />
              </DirectionContainer>
              <DirectionContainer>
                  <ArrowCheckboxContainer isSelected={excludeDirection.front} title='' onClick={() => setExcludeDirection(prev => ({...prev, front: !prev.front}))}>
                    <ArrowDownCheckbox />
                  </ArrowCheckboxContainer>
                  <Label htmlFor='' labelText={t('configTab.exludeFrontLabel')} />
              </DirectionContainer>
            </CheckboxContainer>
          </DirectionInputs> : null}
        </FieldsGroup>
        <ButtonWithLoading loading={isSaving} disabled={!hasImageLoad} onClick={handleSave}>{t('configTab.saveConfig')}</ButtonWithLoading>
      </LeftSection>
      <RightSection>
        <HiddenImage ref={imgRef} src={IMG_URL} onLoad={handleImageLoad} onError={() => setHasImageLoad(false)} />
        {(hasImageLoad) ?
          <ImageContainer>
            {showDirectionIcons && detection_types === 'NUMBER_PLATE' ? <DirectionIconContainer>
                <ArrowUpContainer isSelected={excludeDirection.rear} title=''>
                  <ArrowUp />
                </ArrowUpContainer>
                <ArrowDownContainer isSelected={excludeDirection.front} title=''>
                  <ArrowDown />
                </ArrowDownContainer>
            </DirectionIconContainer> : null}
            <LineSetContext.Provider value={{ state, dispatch }}>
              <LineUI
                options={options}
                src={IMG_URL}
                onSizeChange={() => {setShowDirectionIcons(true)}}
              />
            </LineSetContext.Provider>
          </ImageContainer>
          : <NoImage>{t('configTab.noImage')}</NoImage>
        }
      </RightSection>
    </Container>
  );
};

export default CameraConfigTab;