import * as React from "react";
function SvgMail(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <rect x={1.5} y={4.75} width={21} height={15} rx={1.5} />
        <path d="M22.161 5.3l-8.144 6.264a3.308 3.308 0 01-4.034 0L1.839 5.3" />
      </g>
    </svg>
  );
}
export default SvgMail;
