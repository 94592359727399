import * as React from "react";
function SvgFaceScan(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M11.246 17.51v-2.276l.764.128a2.276 2.276 0 002.65-2.246V11.25l.71-.177a1.139 1.139 0 00.671-1.736l-1.38-2.07v-.568c0-3.358-3.4-6.038-7.45-5.664a6.824 6.824 0 00-2.794 12.709v3.767m16.103 5.063a1.5 1.5 0 11-2.773-1.148l2.87-6.93a1.501 1.501 0 112.772 1.154l-2.87 6.924zm-8.329-.311a.125.125 0 11-.245.045.125.125 0 01.245-.045m-2.938.587a.125.125 0 11-.245.045.125.125 0 01.245-.045m5.88-1.175a.125.125 0 01-.1.147.125.125 0 11-.049-.245.126.126 0 01.147.1M19.523 7.97a.125.125 0 11.08.237.125.125 0 01-.08-.237m.941 2.848a.125.125 0 11.078.238.125.125 0 01-.078-.238M18.582 5.12a.125.125 0 11.08.238.125.125 0 01-.08-.237"
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
}
export default SvgFaceScan;
