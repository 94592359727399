import format from 'date-fns/format';
import sub from 'date-fns/sub';
import { useState, useContext, useCallback } from 'react';
import { Alert, AlertsContext } from '../context/AlertsContext';



interface AlertResponse {
  alerts: Alert[],
  message: string; // "Succeeded",
  info: {
    limit: number,
    total: number,
    offset: number;
  }
}
export interface IFetchAlertOptions {
  limit?: number;
  offset?: number;
  alertType?: string;
  startTime?: Date;
  endTime?: Date;
  locationId?: string;
  active?: boolean;
  cameraId?: number;
}

const dateTimeFormat = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSxxx';

export const useAlerts = () => {
  const { isAlertsPaused, alerts, updateAlerts, setAlertFilter, setIsAlertsPaused } = useContext(AlertsContext);
  const [alertsLoading, setAlertsLoading] = useState<boolean>(true);
  const [alertsErrorMessage, setAlertsErrorMessage] = useState('');
  const [alertCount, setAlertCount] = useState(0);

  const dismissAlert = useCallback(async (alert: Alert): Promise<boolean> => {
    setAlertsErrorMessage('');
    try {
      const response = await fetch(`/api/alert/${alert.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ active: false })
      })

      if (response.ok) {
        let wasUpdated = false;
        updateAlerts((alerts) => {
          if (!alerts) return alerts;
          const index = alerts.indexOf(alert);
          if (index !== -1) {
            wasUpdated = true;
            return [...alerts.slice(0, index), { ...alert, active: false }, ...alerts.slice(index + 1, alerts.length)]
          } else {
            return alerts;
          }
        });

        return wasUpdated;
      } else {
        console.error(response.statusText);
        setAlertsErrorMessage(`Failed to dismiss alert: ${response.statusText}`);
        return false;
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
      setAlertsErrorMessage('Failed to dismiss alert');
      return false;
    }
  }, [updateAlerts]);


  const fetchAlerts = useCallback(async (options?: IFetchAlertOptions) => {
    const {
      limit = 100,
      offset = 0,
      alertType = '',
      startTime,
      endTime,
      locationId,
      active,
      cameraId
    } = (options || {});

    setAlertsLoading(true);
    setAlertsErrorMessage('');
    try {
      const searchString = new URLSearchParams({
        limit: '' + limit,
        offset: '' + offset,
        start_time: format(startTime ? startTime : sub(new Date(), { days: 12 }), dateTimeFormat),
        end_time: format(endTime ? endTime : new Date(), dateTimeFormat),
        ...(locationId ? { location_id: `${locationId}` } : {}),
        ...(alertType ? { alert_type: alertType } : {}),
        ...(active !== undefined ? { active: `${active}` } : {}),
        ...(cameraId !== undefined ? { camera_id: `${cameraId}` } : {})
      });
      const url = '/api/alert?' + searchString.toString();

      const response = await fetch(url);

      if (response.ok) {

        const {
          alerts = [],
          // offset
          // limit
          info: {
            total
          }
        } = await response.json() as AlertResponse

        updateAlerts(alerts);
        setAlertCount(total);
      } else {
        console.error(`Alerts fetch error: ${response.statusText}`);
        setAlertsErrorMessage(`Alerts fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setAlertsErrorMessage(error.message);
      } else {
        console.error(error);
        setAlertsErrorMessage('Unknown Error fetching Cameras.');
      }
    }
    setAlertsLoading(false);
    setIsAlertsPaused(false);
  }, [updateAlerts, setIsAlertsPaused]);


  return {
    isAlertsPaused,
    alerts,
    alertsLoading,
    alertsErrorMessage,
    alertCount,
    actions: {
      dismissAlert,
      fetchAlerts,
      setAlertFilter,
      setIsAlertsPaused
    }
  }
}