import { useCallback, useState } from 'react';
import { IPipelineTrigger, IPipelineCondition, IPipelineAction } from '../components/EventRules';

export type  ITriggerLayout = Pick<IPipelineTrigger, 'data'|'subtype'|'overview'|'meta'>
export type  IConditionLayout = Pick<IPipelineCondition, 'data'|'subtype'|'overview'|'meta'>
export type  IActionLayout = Pick<IPipelineAction, 'data'|'subtype'|'overview'|'meta'>


export interface ILayout {
  triggers: ITriggerLayout[]
  conditions: IConditionLayout[]
  actions: IActionLayout[]
}
export interface ILogicGroup {
  active: boolean,
  id?: number,
  name: string;
  rules: IRuleAction[]
  layout: ILayout
}

export type RuleEqualityOperator = '=='|'>'|'<'|'>='|'<='|'!='|'range';
export type RuleBooleanOperator = 'and'|'or';
export type RuleCondition = [key: string, operator: RuleEqualityOperator, value: (string|number|boolean|null)];
export type RuleBooleanExpression = [operator:RuleBooleanOperator, leftCondition: RuleCondition, ...rightConditions:RuleCondition[]];

export interface RuleJSON {
  rule?: (RuleBooleanExpression|RuleCondition)[]
}
export interface IRuleAction {
  id?: number;
  action_type: 'relay'|'alert'|'email';
  action_id: number;
  logic_group_id?: number;
  from_time?: string|null; //00:00
  to_time?:	string|null; //00:00
  rule_json: RuleJSON;
  algorithm: IAlgorithm;
}
export type IAlgorithm ='NUMBER_PLATE'|'CAR_COUNTING'|'PEOPLE_COUNTING'|'FACE_IDENTIFICATION'|'FACE_ANALYTICS'|'INTRUSIONS';

export const useLogicGroup = () => {
  const [logicGroup, setLogicGroup] = useState<ILogicGroup>();
  const [logicGroupLoading, setLogicGroupLoading] = useState<boolean>(true);
  const [logicGroupErrorMessage, setLogicGroupErrorMessage] = useState('');

  const fetchLogicGroup = useCallback(async (groupID: number) => {
    setLogicGroupLoading(true);
    setLogicGroupErrorMessage('');
    try {

      const response = await fetch('/api/logicgroup/' + groupID);

      if (response.ok) {
        const {logicgroup} = await response.json() as {logicgroup: ILogicGroup};
        setLogicGroup(logicgroup);
      } else {
        console.error(`Logic Groups fetch error: ${response.statusText}`);
        setLogicGroupErrorMessage(`Logic Groups fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setLogicGroupErrorMessage(error.message);
      } else {
        console.error(error);
        setLogicGroupErrorMessage('Unknown Error fetching logic groups');
      }
    }
    setLogicGroupLoading(false);
  }, []);

  const addLogicGroup = useCallback(async (payload: ILogicGroup): Promise<boolean> => {
    try {
      const res = await fetch('/api/logicgroup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({logicgroup: payload})
      });

      if (!res.ok) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const updateLogicGroup = useCallback(async (groupID, payload: ILogicGroup): Promise<boolean> => {
    try {
      const res = await fetch('/api/logicgroup/' + groupID, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({logicgroup: payload})
      });

      if (!res.ok) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }, [])

  const deleteLogicGroup = useCallback(async (groupID): Promise<boolean> => {
    try {
      const res = await fetch('/api/logicgroup/' + groupID, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
      });

      if (!res.ok) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }, [])

  return {
    logicGroup,
    logicGroupLoading,
    logicGroupErrorMessage,
    actions: {
      fetchLogicGroup,
      addLogicGroup,
      updateLogicGroup,
      deleteLogicGroup
    }
  }

}