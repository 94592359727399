import { useState, useCallback } from 'react';
import { NumberPlate } from './useNumberPlates';

interface NumberPlateResponse {
  NumberPlate: NumberPlate,
  message: string; // "Succeeded",
}

export const useNumberPlate = () => {
  const [numberPlate, setNumberPlate] = useState<NumberPlate>();
  const [numberPlateLoading, setNumberPlateLoading] = useState<boolean>(true);
  const [numberPlateErrorMessage, setNumberPlateErrorMessage] = useState('');

  const fetchNumberPlate = useCallback(async (plateID: number) => {
    setNumberPlateLoading(true);
    setNumberPlateErrorMessage('');
    try {

      const response = await fetch(`/api/number_plate/${plateID}`);

      if (response.ok) {
        const { NumberPlate } = await response.json() as NumberPlateResponse;
        setNumberPlate(NumberPlate);
      } else {
        console.error(`Number Plate fetch error: ${response.statusText}`);
        setNumberPlateErrorMessage(`Number Plate fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setNumberPlateErrorMessage(error.message);
      } else {
        console.error(error);
        setNumberPlateErrorMessage('Unknown Error fetching number plate');
      }
    }
    setNumberPlateLoading(false);
  }, []);

  const updateNumberPlate = useCallback(async (payload: NumberPlate) => {
    let errorMessage = '';
    const { id, ...restPayload } = payload;
    try {
      const response = await fetch('/api/number_plate/' + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(restPayload)
      });

      if (!response.ok) {
        console.error(`Error updating plates: ${response.statusText}`);
        errorMessage = 'failedToUpdateNumberPlate';
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        errorMessage = 'failedToUpdateNumberPlate';
      } else {
        console.error(error);
        errorMessage = 'failedToUpdateNumberPlate';
      }
    }
    return ({ errorMessage })
  }, []);

  const addNumberPlate = useCallback(async (payload: NumberPlate) => {
    let errorMessage = '';
    try {
      const res = await fetch('/api/number_plate/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (!res.ok) {
        if (res.status === 409) {
          return ({ errorMessage: 'numberPlateAlreadyExist' })
        }
        return ({ errorMessage: 'failedToAddNumberPlate' })
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        errorMessage = 'failedToAddNumberPlate';
      } else {
        console.error(error);
        errorMessage = 'failedToAddNumberPlate';
      }
    }
    return ({ errorMessage })
  }, []);

  return {
    numberPlate,
    numberPlateLoading,
    numberPlateErrorMessage,
    actions: {
      fetchNumberPlate,
      updateNumberPlate,
      addNumberPlate
    }
  }
}