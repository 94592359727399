import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

/** Was not sure where to put this one @-@ */
export enum DASHBOARD_PARAMS {
  locationId = 'locationId',
  zoneId = 'zoneId',
  cameraId = 'cameraId',
  layer = 'layer',
  alertId = 'alertId',
  reCenter = 'reCenter',
  tab = 'tab',
  fromTimeHrs = 'fromTimeHrs',
  fromTimeMins = 'fromTimeMins'
}

export const useSelected = () => {
  const { push } = useHistory();
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState<URLSearchParams>(new URLSearchParams(search));

  useEffect(() => {
    const params = new URLSearchParams(search);
    setQueryParams(params);
  }, [search]);

  const updateParam = useCallback((key, value) => {
    const strValue = value.toString();
    if (queryParams.get(key) === strValue) return;

    if (strValue === '') {
      queryParams.delete(key);
    } else {
      queryParams.set(key, value);
    }

    push({ search: queryParams.toString() });
  }, [push, queryParams]);

  const getParam = useCallback((key: string): string => {
    return queryParams.get(key) || '';
  }, [queryParams]);

  return {
    updateParam,
    getParam,
  };
};