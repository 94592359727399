import { FC, useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Content,
  PageHeader,
  useTo,
  Button,
  ButtonWithLoading,
  useNotification,
  SmallInput,
  Switch
} from 'scorer-ui-kit';
import styled from 'styled-components';
import { INodeOptionSet, IPipeline, IPipelineStatus } from '../components/EventRules';
import RulesMatrix from '../components/EventRules/RuleMatrix';

import { useCameras } from '../hooks/useCameras';
import { ILogicGroup, IRuleAction, RuleBooleanExpression, RuleCondition, useLogicGroup } from '../hooks/useLogicGroup';
import { useRelays } from '../hooks/useRelays';
import { IParams } from '../types';
import { useTags } from '../hooks/useTags';
import { useCategories } from '../hooks/useCategories';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    max-width: 610px !important;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledBtn = styled(Button)`
  margin-left: 12px;
`;

const StyledLoadingBtn = styled(ButtonWithLoading)`
  margin-left: 12px;
`;

const HeaderRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const PipelineHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 23px;
  gap: 74px;
  align-items: center;
  label {
    word-break: keep-all;
  }
`;

const SmallTextField = styled(SmallInput)`
  label {
    width: 200px;
  }
`;

// const RequiredText = styled.div`
//   font-family: ${({ theme }) => theme.fontFamily.data};
//   font-size: 14px;
//   color: #788b91;
//   text-align: center;
//   padding-left: 10px;
// `;
type AgeValue = '20s' | '30s' | 'Under 20' | '40s' | '50s' | '60s' | '70s' | 'Higher than 80' | 'All Ages';

type AgeRange = [number, number];

type AgeValueToRangeMap = {
  [key in AgeValue]: AgeRange;
};

const AddEditRelayLogicGroup: FC = () => {

  const { t } = useTranslation(['AddEditRelayLogicGroup', 'RelayLogicGroups', 'Common']);
  const { groupID }: IParams = useParams();
  const { cameras, actions: { fetchCameras } } = useCameras();
  const { logicGroup, actions: { fetchLogicGroup, addLogicGroup, updateLogicGroup } } = useLogicGroup();
  const { relays = [], actions: { fetchRelays } } = useRelays();
  const [pipeline, setPipeline] = useState<IPipeline>();
  const [pipelineStatus, setPipelineStatus] = useState<IPipelineStatus>();
  const { tags, actions: { fetchTags } } = useTags();
  const {catagories, actions: {fetchCategories}} = useCategories();

  const [pipelineForm, setPipelineForm] = useState({
    name: 'Pipeline',
    active: true
  })


  const to = useTo();

  const { sendNotification } = useNotification();

  useEffect(() => {
    fetchCameras();
  }, [fetchCameras]);

  useEffect(() => {
    groupID && fetchLogicGroup(parseInt(groupID));
  }, [groupID, fetchLogicGroup]);

  useEffect(() => {
    fetchRelays();
  }, [fetchRelays]);

  useEffect(()=>{
    const {name = 'Pipeline', active = true} = logicGroup ||{};
    setPipelineForm({
      name,
      active
    })
  },[logicGroup]);



  // const isFormValid = useCallback((pipeline): boolean => {

  //   if (!pipeline) {
  //     sendNotification({ type: 'error', message: t('error.relayLinksAreRequired') })
  //     return false;
  //   }

  //   return true;
  // }, [sendNotification, t]);

  const updatePipelineStatus = useCallback((pipelineStatus) => {
    setPipelineStatus(pipelineStatus);
  }, []);

  const validatePipeline = useCallback(() => {
    if (!pipelineStatus) {
      sendNotification({type: 'error', message: 'Pipeline is not configured.'});
      return false;
    }
    
    const {
      triggerAdd = false,
      triggerEdit = false,
      filtersEdit = false,
      actionAdd = false,
      actionEdit = false,
      relayLinks = false
    } = pipelineStatus;

    if (!triggerAdd) {
      sendNotification({type: 'error', message: t('error.addDetectionTrigger')});
      return false;
    }
    if (!triggerEdit) {
      sendNotification({type: 'error', message: t('error.configureDetectionTrigger')});
      return false;
    }
    if (!filtersEdit) {
      sendNotification({type: 'error', message: t('error.configureFilters')});
      return false;
    }
    if (!actionAdd) {
      sendNotification({type: 'error', message: t('error.addAction')});
      return false;
    }
    if (!actionEdit) {
      sendNotification({type: 'error', message: t('error.configureAction')});
      return false;
    }
    if (!relayLinks) {
      sendNotification({ type: 'error', message: t('error.relayLinksAreRequired') });
      return false;
    }
    return true;
  }, [pipelineStatus, sendNotification, t]);

  const handleAdd = useCallback(async () => {
    const isValidPipeline = validatePipeline();
    if (!isValidPipeline || !pipeline) {
      return;
    }
    const logicGroup = pipelineToLogicGroup(pipeline);
    const {name, active} = pipelineForm;
    const result = await addLogicGroup({...logicGroup,name, active} as ILogicGroup);

    if (result) {
      sendNotification({ type: 'success', message: t('success.groupAddedSuccessfully') })
      to('/event-pipeline')();
    } else {
      sendNotification({ type: 'error', message: t('error.failedToAddGroup') })
    }

  }, [addLogicGroup, pipeline, pipelineForm, sendNotification, t, to, validatePipeline])

  const handleUpdate = useCallback(async () => {
    const isValidPipeline = validatePipeline();
    if (!isValidPipeline || !pipeline) {
      return;
    }
    const logicGroup = pipelineToLogicGroup(pipeline);
    const {name, active} = pipelineForm;
    const result = await updateLogicGroup(parseInt(groupID), {...logicGroup,name, active} as ILogicGroup);

    if (result) {
      sendNotification({ type: 'success', message: t('success.groupUpdatedSuccessfully') })
      to('/event-pipeline')();
    } else {
      sendNotification({ type: 'error', message: t('error.failedToUpdateGroup') })
    }

  }, [pipeline, pipelineForm, updateLogicGroup, groupID, sendNotification, t, to, validatePipeline]);

  const onChangeName = useCallback(({ target: { value } }) => {
    setPipelineForm( pipelineForm=>({...pipelineForm, name: value}));
  }, []);

  const onToggle = useCallback((value) => {
    setPipelineForm( pipelineForm =>({...pipelineForm, active: value}));
  }, []);

  useEffect(() => {
    fetchTags();
  }, [fetchTags]);

  useEffect(()=>{
    fetchCategories();
  },[fetchCategories]);


  return (
    <Container>
      <PageHeaderWrapper>
        <PageHeader icon='DevicesScorerEdge' title={t(groupID ? 'editRelayLogicGroup' : 'addRelayLogicGroup')} areaTitle={t('RelayLogicGroups:relayLogicGroups')} areaHref='/event-pipeline' introductionText={t('introText')} />
          <HeaderRightContainer>
          <ButtonGroup>
            <StyledBtn size='small' design='secondary' onClick={to('/event-pipeline')}>{t('cancel')}</StyledBtn>
            <StyledLoadingBtn loading={false} size='small' onClick={groupID ? handleUpdate : handleAdd}>{t('save')}</StyledLoadingBtn>
          </ButtonGroup>

        </HeaderRightContainer>
      </PageHeaderWrapper>
      <PipelineHeader>
        <SmallTextField fieldState='default' name='name' label={t('other.logicGroupName')} value={pipelineForm.name} onChange={onChangeName} />
        <Switch checked={pipelineForm.active} onChangeCallback={onToggle} labelText={t('other.enabled')} />
      </PipelineHeader>

      <RulesMatrix
        logicGroup={logicGroup}
        tags={tags}
        catagories={catagories}
        updatePipeline={setPipeline}
        updatePipelineStatus={updatePipelineStatus}
        relays={relays}
        cameras={cameras}
      />
    </Container>
  )
}

export default AddEditRelayLogicGroup;
const pipelineToLogicGroup = (pipeline:IPipeline):ILogicGroup |null => {
  const {triggers:[{algorithm,config:triggerConfigs=[], data: {configFields=[]}}], conditions, actions} = pipeline;
  if(!algorithm) return null;

  const actionList: IRuleAction[] =[];
  let baseRule: (RuleCondition|RuleBooleanExpression)[]= [];
  if(algorithm === 'FACE_ANALYTICS'){
    baseRule = [
      ...rulesFromConfigs([triggerConfigs[0]], [configFields[0]]),
      ...rulesFromConfigs([triggerConfigs[1]], [configFields[1]]),
      ...(conditions.map(({config=[],data:{configFields=[]}}) => (rulesFromConfigs(config, configFields))).flat()),
    ];
  }else{
    baseRule = [
      ...rulesFromConfigs(triggerConfigs, configFields),
      ...(conditions.map(({config=[],data:{configFields=[]}}) => (rulesFromConfigs(config, configFields))).flat()),
    ];
  }
  for(const {action_type,data: {links=[], configFields=[]}={}} of actions){
      if (action_type === 'alert'){
        const action = {
          algorithm,
          action_type,
          action_id: 0,
          // from_time: null,
          // to_time: null,
          rule_json: {rule: baseRule}
        }
        actionList.push(action);
      } else if(action_type === 'relay'){
        for(const {cameraId, entityId} of links){
          if(cameraId === null || entityId === null) continue;
          const rule = [['camera_id','==',cameraId] as RuleCondition,...baseRule];
          const action = {
            algorithm,
            action_type,
            action_id: entityId,
            // from_time: null,
            // to_time: null,
            rule_json: {rule}
          }
          actionList.push(action);
        }
    }  else if (action_type === 'email'){
      const [action_id=null] = configFields;
      if(action_id === null) continue;
      const action = {
        algorithm,
        action_type,
        action_id,
        // from_time: null,
        // to_time: null,
        rule_json: {rule: baseRule}
      };
      actionList.push(action);
    }
  }


  const layout = {
    triggers: pipeline.triggers.map(({data,overview,subtype, meta})=>({data,overview,subtype,meta})),
    actions: pipeline.actions.map(({data,overview,subtype,meta})=>({data,overview,subtype,meta})),
    conditions: pipeline.conditions.map(({data,overview,subtype,meta})=>({data,overview,subtype,meta}))
  }

  return {
    name: 'Pipeline',
    rules: actionList,
    active: true,
    layout
  };
}

const rulesFromConfigs = (config:INodeOptionSet[], data:any[]): (RuleCondition|RuleBooleanExpression)[] => {
  const rules :(RuleCondition|RuleBooleanExpression)[]=[];
  const orRuleConditions: RuleCondition[] =[];
  for (const [index, {rule_key, rule_value, fieldType, optionsSource}] of config.entries()){
    const value = data[index];

    if(value === true && rule_key && rule_value && fieldType === 'checkbox' && optionsSource !=='age' && optionsSource !== 'gender'){
      orRuleConditions.push([rule_key, '==' , rule_value]);
    } else if (rule_key && value && optionsSource !=='age' && optionsSource !== 'gender'){
      rules.push([rule_key, '==', value])
    }
    for(let i=0; i<value?.length; i++){
    const ageGenderValues = value[i];
    if(rule_key && ageGenderValues && optionsSource === 'age'){
      const ageValueToRangeMap: AgeValueToRangeMap = {
      '20s': [20, 30],
      '30s': [30, 40],
      'Under 20': [0, 20],
      '40s': [40, 50],
      '50s': [50, 60],
      '60s': [60, 70],
      '70s': [70, 80],
      'Higher than 80': [80, 201],
      'All Ages': [0, 201],
    };
    const ageValue = ageGenderValues as AgeValue;
    const range = ageValueToRangeMap[ageValue];
    if (range) {
      const [minValue, maxValue] = range;
      orRuleConditions.push([optionsSource, 'range', `(${minValue}, ${maxValue})`]);
    }
    }else if (rule_key && ageGenderValues && optionsSource === 'gender') {
      if(ageGenderValues === 'All Genders'){
        orRuleConditions.push([optionsSource, '==', 'Male'])
        orRuleConditions.push([optionsSource, '==', 'Female'])
      }else{
          orRuleConditions.push([optionsSource, '==', ageGenderValues])
        }
      }
    }
  }
  const orRule:(RuleCondition|RuleBooleanExpression)[] = orRuleConditions.length >= 2 ? [['or',...orRuleConditions]] as RuleBooleanExpression[] : orRuleConditions;
  return [...rules, ...orRule];
}
