import { ITypeTableData } from 'scorer-ui-kit/dist/Tables';

export const MEDIA_DIR = '/data/media/';
export const IMAGE_DIR = '/data/images/';

export const INITIAL_ROWS: ITypeTableData = [
  {
    columns: []
  }
];

export const ALERT_TYPES_LIST = [
  'LISTED_DETECTED',
  'INTRUDER_DETECTED',
  'IMPORTANT_DETECTED',
  'UNKNOWN_DETECTED'
];

export const DETECTION_TYPE_LIST = [
  'NUMBER_PLATE',
  'CAR_COUNTING',
  'FACES',
  'PEOPLE',
  'FACE_IDENTIFICATION',
  'PEOPLE_COUNTING',
  'INTRUSIONS'
]


export const PAGE_SIZE_OPTIONS = [1, 10, 20, 50, 100];

export const AgeList = [
  { text:'Under 20', value:'Under 20'},
  { text:'20’s', value:'20s' },
  { text:'30’s', value:'30s' },
  { text:'40’s', value:'40s' },
  { text:'50’s', value:'50s' },
  { text:'60’s', value:'60s' },
  { text:'70’s', value:'70s' },
  { text:'Higher than 80', value:'Higher than 80' },
  { text:'All Ages', value:'All Ages' }
];

export const GenderList = [
  { text:'Male', value:'Male' },
  { text:'Female', value:'Female' },
  { text:'All Genders', value:'All Genders'}
];

