import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Content,
  FilterBar,
  IFilterDropdownConfig,
  PageHeader,
  TypeTable,
  ActionButtons,
  IconButtonData,
  IFilterResult,
  IFilterItem,
  ButtonWithIcon,
  useTo,
  ConfirmationModal,
  useModal,
  useNotification,
  TagList,
  ISearchFilter,
  IFilterDatePicker,
  DateInterval,
  ButtonWithLoading
} from 'scorer-ui-kit';
import { IRowData, ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import styled from 'styled-components';
import Pagination from '../components/Pagination';
import { INITIAL_ROWS } from '../constants';
import { getMetaDetection, getSelected, getCategoTagList, readParams, updateParams, dropdownHelper } from '../utils';
import { useHistory, useLocation } from 'react-router-dom';
import { IFetchNumberPlatesOptions, useNumberPlates } from '../hooks/useNumberPlates';
import { useCategories } from '../hooks/useCategories';
import { useTags } from '../hooks/useTags';
import TableCellRelativeTime from '../components/atoms/TableCellRelativeTime';
import i18n from '../i18n';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    max-width: 610px !important;
  }
`;

const FilterWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
`;

const StyledBtnWithIcon = styled(ButtonWithIcon)`
  margin-bottom: 10px;
`;

const StyledBtnWithLoading = styled(ButtonWithLoading)`
  margin-bottom: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const TagListWrapper = styled.div`
  div {
    border: none;
  }
`;

interface ISelectedFilterValues {
  type?: string,
  sortDirection?: string,
  sortBy?: string
  category?: string
  lastDetected?: DateInterval
  tags?: string
  paramsLoaded: boolean,
  number?: string
  [key:string] : string | DateInterval | boolean |undefined
}
const initialFilterValues: ISelectedFilterValues = {
  type: undefined,
  sortDirection: undefined,
  sortBy: undefined,
  category: undefined,
  lastDetected: undefined,
  tags:undefined,
  number: undefined,
  paramsLoaded: false

}

const sizeOptions = [1, 10, 20, 50, 100];

const NumberPlates: React.FC<{}> = () => {
  const [rowData, setRowData] = useState<ITypeTableData>([]);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const [categoryList, setCategoryList] = useState<IFilterItem[]>([]);
  const [tagList, setTagList] = useState<IFilterItem[]>([]);

  const { sendNotification } = useNotification();
  const {
    numberPlates,
    numberPlatesLoading,
    totalNumberPlates,
    numberPlatesExporting,
    actions: {
      fetchNumberPlates,
      exportNumberPlates,
      deleteNumberPlate,
    } } = useNumberPlates();

  const {catagories, actions: {fetchCategories}} = useCategories();
  const { tags, actions: { fetchTags } } = useTags();

  const params = useLocation().search;
  const [historyParams] = useState(params);


  const to = useTo();

  const { createModal } = useModal();

  const [dropdownConfig, setDropdownConfig] = useState<IFilterDropdownConfig[]>();

  const { push } = useHistory();
  const { t } = useTranslation(['NumberPlates', 'Common']);

  const pageSizeLabel = {
    htmlFor: 'select-page-size',
    text: t('Common:filter.itemsPerPage'),
    isSameRow: true,
  };

  const pageLabel = {
    htmlFor: 'select-page',
    text: t('Common:filter.page'),
    isSameRow: true,
  };

  const tableColumns: ITableColumnConfig[] = [
    {
      columnId: 'type',
      header: t('type'),
      cellStyle: 'firstColumn',
      groupTitle: t('vehicleInformation')
    },
    {
      header: t('numberPlate'),
      cellStyle: 'firstColumn',
      groupTitle: t('vehicleInformation')
    },
    {
      header: t('categoryAndTags'),
      cellStyle: 'normalImportance'
    },
    {
      groupTitle: t('lastDetection'),
      header: t('detected'),
      cellStyle: 'normalImportance',
    },
    {
      header: t('actions'),
      cellStyle: 'normalImportance'
    }
  ];

  const getTypesList = useCallback(() => {
    return [
      {
        text: t('listed'),
        value: 'LISTED'
      },
      {
        text: t('important'),
        value: 'IMPORTANT'
      }
    ];
  }, [t]);

  const getDropdownConfig = useCallback(() => {

    return ([
      {
        id: 'type',
        buttonText: t('type'),
        list: getTypesList(),
        buttonIcon: 'Vehicle',
        optionType: 'radio',
        selected: getSelected(selectedFilterValues.type),
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`,

      },
      {
        id: 'category',
        buttonText: t('category'),
        list: categoryList,
        buttonIcon: 'MetaCategories',
        selected: getSelected(selectedFilterValues.category),
        optionType: 'radio',
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`,
      },
      {
        id: 'tags',
        buttonText: t('tags'),
        list: tagList,
        buttonIcon: 'MetaTags',
        selected: getSelected(selectedFilterValues.category),
        optionType: 'checkbox',
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`,
      }
    ]);
  }, [t, getTypesList, selectedFilterValues.type, selectedFilterValues.category, categoryList, tagList]);

  const searchers: ISearchFilter[] = [
    {
      id: 'numberPlate',
      placeholder: t('filterByNumberPlate'),
    }
  ];

  const datePickers: IFilterDatePicker[] = [
    {
      id: 'lastDetected',
      buttonIcon: 'DateTime',
      buttonText: t('lastDetection'),
      dateMode: 'interval',
      timeMode: 'interval',
      selected: undefined,
      dateTimeTextLower: t('Common:dateTo'),
      dateTimeTextUpper:  t('Common:dateFrom'),
      timeZoneTitle: t('Common:timeZone'),
      lang: i18n.language === 'ja' ? 'ja' : 'en',
    }
  ];

  useEffect(() => {
    setSelectedFilterValues(readParams(historyParams, initialFilterValues));
  }, [historyParams]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    push({ search: updateParams(selectedFilterValues) });
  }, [push, selectedFilterValues]);

  useEffect(() => {
    setDropdownConfig(getDropdownConfig() as IFilterDropdownConfig[]);
  }, [getDropdownConfig, selectedFilterValues]);

  const sortCallback = useCallback((ascending: boolean, columnId: string) => {
    setSelectedFilterValues((prev) => ({ ...prev, sortDirection: ascending ? 'asc' : 'desc', sortBy: columnId }))
  }, []);

  const handleDelete = useCallback(async (plateId: number) => {
    const result = await deleteNumberPlate(plateId);
    if (result) {
      sendNotification({ type: 'success', message: t('successDeleting') })
    } else {
      sendNotification({ type: 'error', message: t('errorDeleting') })
    }

  }, [deleteNumberPlate, sendNotification, t])

  const openConfirmationModal = useCallback((plateId: number) => {
    createModal({
      isCloseEnable: false,
      customComponent: (
        <ConfirmationModal
          title={t('confirmNumberPlateDeletion')}
          message={t('deleteNumberPlateMsg')}
          leftButtonText={t('Common:cancel')}
          leftButtonDesign='secondary'
          rightButtonText={t('Common:delete')}
          rightButtonDesign='danger'
          rightButtonCallback={() => handleDelete(plateId)}
        />
      )
    });
  }, [createModal, t, handleDelete]);

  const generateConfigButtons = useCallback((id: number): IconButtonData[] => {
    return (
      [
        {
          icon: 'Edit',
          onClick: to(`/number-plates/${id}/edit`),
        },
        {
          icon: 'Delete',
          onClick: () => openConfirmationModal(id),
        },
      ]
    )
  }, [to, openConfirmationModal]);

  const generateRowData = useCallback((): ITypeTableData => {
    const data: ITypeTableData = (numberPlates ?? []).map((numberPlate) => {

      const {
        id,
        type,
        area,
        user_type,
        kana,
        number,
        category,
        tags,
        last_detected
      } = numberPlate;

      const row: IRowData = {
        header: {
          icon: type === 'LISTED' ? 'VehicleWhitelist' : 'VehicleBlacklist'
        },
        columns: [
          { text: getMetaDetection(type) },
          { text: `${area}${user_type} ${kana}${number}` || '-', href: `/number-plates/${id}/edit` },
          {
            customComponent: (
              tags
                ? (
                  <TagListWrapper>
                    <TagList tagsConfig={getCategoTagList(category as string, tags as string)} />
                  </TagListWrapper>
                )
                : <></>
            )
          },
          { customComponent: last_detected ?  <TableCellRelativeTime eventTime={last_detected.toString()} /> : <div>-</div>},
          { customComponent: <ActionButtons buttonsConfig={generateConfigButtons(id)} /> },
        ]
      };
      return row;
    });
    return data.length ? data : INITIAL_ROWS;
  }, [generateConfigButtons, numberPlates]);

  useEffect(() => {
    if (numberPlatesLoading) return;
    setRowData(generateRowData());
  }, [generateRowData, numberPlates, numberPlatesLoading]);

  const getApiParams = useCallback((): IFetchNumberPlatesOptions => {

    const { type, sortDirection = 'desc', sortBy = 'name', tags, category, lastDetected, number } = selectedFilterValues;

    return ({
      limit: pageSize,
      offset: pageSize * (currentPage - 1),
      sortBy: sortBy,
      sortDirection: sortDirection,
      ...(lastDetected ? {startTime: (lastDetected as DateInterval)?.start} : {}),
      ...(lastDetected? {endTime: (lastDetected as DateInterval)?.end} : {}),
      ...(type === undefined ? {} : {type}),
      ...(tags === undefined ? {} : {tags}),
      ...(category === undefined ? {} : {category}),
      ...(number === undefined ? {} : {number})
    });

  }, [pageSize, currentPage, selectedFilterValues]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    fetchNumberPlates(getApiParams());
  }, [fetchNumberPlates, getApiParams, pageSize, currentPage, selectedFilterValues]);

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {
    setCurrentPage(1);
    setSelectedFilterValues((selectedFilterValues) =>
      res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {
          switch (id) {
            case 'category':
              selectedValues[id] = (selected as IFilterItem).value as string;
              break;
            case 'type': {
              const {value} = selected as IFilterItem;
              selectedValues.type = value as string;
              break;
            }

            case 'lastDetected': selectedValues[id] = selected as DateInterval;
              break;
            case 'numberPlate': {
              const {value} = selected as IFilterItem;
              selectedValues.number = value as string;
              break;
            }
            default:
              selectedValues[id] = (selected as IFilterItem[]).map(({ value }) => value).join(',');
          }
          return selectedValues;
        },
        { ...initialFilterValues, paramsLoaded: selectedFilterValues.paramsLoaded })
    )
  }, []);

  const onPageSizeChange = useCallback((val: string) => {
    setPageSize(parseInt(val));
    setCurrentPage(1);
  }, []);

  const onPageChange = useCallback((val: string) => {
    setCurrentPage(parseInt(val));
  }, []);

  useEffect(() => {
    setTotalPages(Math.ceil(totalNumberPlates / pageSize) || 1);
  }, [totalNumberPlates, pageSize]);

  useEffect(() => {
    fetchTags();
  }, [fetchTags])

  useEffect(()=>{
    fetchCategories();
  },[fetchCategories])

  useEffect(() => {
    setTagList(dropdownHelper(tags, { textKey: 'tag', valueKey: 'tag' }));
  }, [tags])

  useEffect(()=>{
    setCategoryList(dropdownHelper(catagories, { textKey: 'name', valueKey: 'name' }));
  },[catagories])

  const handleExport = useCallback(async () =>{
    const isExportSuccess = await exportNumberPlates();
    if (isExportSuccess) {
      sendNotification({type: 'success', message: t('successExporting')});
    } else {
      sendNotification({type: 'error', message: t('errorExporting')});
    }
  }, [exportNumberPlates, sendNotification, t]);

  return (
    <Container>
      <PageHeaderWrapper>
        <PageHeader icon='ViewSettings' title={t('numberPlates')} areaTitle={t('dashboard')} areaHref='/' introductionText={t('introText')} />
        <ButtonGroup>
          <StyledBtnWithIcon icon='Add' position='left' size='small' onClick={to('/number-plates/add')}>{t('addNumberPlate')}</StyledBtnWithIcon>
          {numberPlatesExporting ?
            <StyledBtnWithLoading loading={numberPlatesExporting} design='secondary' position='left' size='small'>{t('exportData')}</StyledBtnWithLoading>
            : <StyledBtnWithIcon icon='FileTypesCsv' design='secondary' position='left' size='small' onClick={handleExport}>{t('exportData')}</StyledBtnWithIcon>
          }
        </ButtonGroup>

      </PageHeaderWrapper>

      <FilterWrapper>
        {dropdownConfig &&
          <FilterBar
            searchersConfig={searchers}
            dropdownsConfig={dropdownConfig}
            datePickersConfig={datePickers}
            filtersTitle={t('Common:filter.filters') + ':'}
            resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
            resultsDateFormat={'yyyy/MM/dd HH:mm'}
            totalResults={rowData.length === 1 && rowData[0].columns.length === 0 ? 0 : rowData.length}
            onChangeCallback={onChangeFilterValue}
          />}
      </FilterWrapper>

      <TypeTable
        columnConfig={tableColumns}
        rows={rowData}
        isLoading={numberPlatesLoading}
        emptyTableText={t('noNumberPlatesFound')}
        loadingText={t('Common:loadingData')}
        sortCallback={sortCallback}
        hasHeaderGroups
        hasTypeIcon
      />

      {rowData.length !== 0 &&
        <Pagination
          pageSizeOptions={sizeOptions}
          totalPages={totalPages}
          defaultPage={currentPage}
          defaultPageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          pageSizeLabel={pageSizeLabel}
          pageLabel={pageLabel}
        />}

    </Container>
  );
};

export default NumberPlates;