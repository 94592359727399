import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, FilterDropdown, Icon, IFilterItem, IFilterValue } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import { Camera } from '../../../hooks/useCameras';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { IFetchAlertOptions, useAlerts } from '../../../hooks/useAlerts';
import RowActions, { IAction } from '../molecules/RowActions';
import RowThumbnail from '../atoms/RowThumbnail';
import EventRow, { ICellItem } from '../atoms/EventRow';
import { dropdownHelper, getAlertTypeLocal, getTypeIcon } from '../../../utils';
import LinkCell from '../../atoms/LinkCell';
import { TypeCellAlignment } from 'scorer-ui-kit/dist/Tables';
import { useHistory } from 'react-router-dom';
import LongTextCell from '../../atoms/LongTextCell';
import i18n from '../../../i18n';
import { useLocations } from '../../../hooks/useLocations';
import { ICarDetection, IFaceDetection, IIntrusionDetection, isCarDetection, isFaceDetection } from '../../../hooks/useDetections';
import { AlgorithmType } from '../../../hooks/useCameraConfig';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import TableCellRelativeTime from '../../atoms/TableCellRelativeTime';
import { Alert } from '../../../context/AlertsContext';
import { EllipsisStyles } from '../../../Style';

const Container = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.data};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 14px 0 10px 20px;
`;

const Title = styled.div`
  margin-left: 12px;
`;

const TableWrapper = styled.div`
  position: relative;
  overflow-y: scroll;
  margin-right:-17px;
  flex: 1;
`;

const EventTable = styled.div`
  display: table;
  width: 100%;
`;

const TableHeader = styled.div`
  display: table-row;
  position: sticky;
  top: 0;
  ${({ theme }) => theme.styles.global.background};
  z-index: 9;
  div:first-child {
    width: 90px;
  }
`;

const HeaderItem = styled.div<{ minWidth?: string, isFirst: boolean, alignment?: TypeCellAlignment }>`
  display: table-cell;
  color: hsl(0, 0%, 46%);
  font-family: ${(({ theme }) => theme.fontFamily.data)};
  font-size: 12px;
  padding-bottom: 2px;
  padding-top: 2px;
  position: relative;
  border-top: 1px ${({ theme }) => theme.colors.divider} solid;
  border-bottom: 1px ${({ theme }) => theme.colors.divider} solid;

  ${({ isFirst }) => isFirst && `
    padding-left: 15px`
  };

  ${({ minWidth }) => minWidth
    ? `min-width: ${minWidth};`
    : 'min-width: 70px;'
  };

  ${({ alignment }) => alignment && css`
    text-align: ${alignment};
  `};
`;

const AlertTypeText = styled.div`
  font-size: 14px;
  color: hsl(0, 0%, 46%);
`;

const StateText = styled.div`
  font-size: 14px;
  text-align: center;
  font-style: italic;
  color: hsla(195, 10%, 52%, 0.72);
`;

const MetaText = styled.div`
  font-size: 14px;
  color: hsl(196, 10%, 56%);
  white-space: pre;
`;

// const WhenText = styled.div`
//   font-size: 14px;
//   font-style: italic;
//   color: hsla(195, 10%, 52%, 0.72);
// `;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FilterWrapper = styled.div<{ isFilterOpen: boolean }>`
  display: flex;
  flex-direction: row;
  border-left: 1px solid ${({ theme }) => theme.colors.divider};
  padding-left: 6px;
  padding-right: 11px;
  align-items: center;
  gap: 5px;
  ${({ isFilterOpen }) => !isFilterOpen && css`
  cursor: pointer;
  `}
`;

const ClearAllButton = styled(Button)``;

const IconWrapper = styled.div`
  cursor: pointer;
  margin-right: 4px;
  padding: 4px;
`;

const MetaLongText = styled.div`
  max-width: 165px;
  ${EllipsisStyles}
`;

const FilterResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 15px;
  user-select: none;
`;

const FiltersText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  color: hsl(0,0%,46%);
`;

const SelectedFilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const SelectedFiltersText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  font-style: italic;
  line-height: 1.67;
  letter-spacing: 0.15px;
  color: hsla(195,10%,52%,0.72);
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface IFaceMeta {
  primaryText: string,
  ageText: string,
  genderText: string
}

interface ITypes {
  [key: string]: string
}

const TYPES: ITypes = {
  'LISTED_DETECTED': 'Dashboard:alertType.listed',
  'IMPORTANT_DETECTED': 'Dashboard:alertType.important',
  'INTRUDER_DETECTED': 'Common:alertType.intruderPerson',
  'UNKNOWN_DETECTED': 'Dashboard:alertType.unknown',
}

const getTypesList = () => {
  return [
    // {
    //   text: i18n.t('KnownPeopleDetection:all'),
    //   value: 'ALL'
    // },
    {
      text: i18n.t('Dashboard:alertType.listed'),
      value: 'LISTED_DETECTED'
    },
    {
      text: i18n.t('Dashboard:alertType.important'),
      value: 'IMPORTANT_DETECTED'
    },
    {
      text: i18n.t('Common:alertType.intruderPerson'),
      value: 'INTRUDER_DETECTED'
    },
    {
      text: i18n.t('Dashboard:alertType.unknown'),
      value: 'UNKNOWN_DETECTED'
    }
  ];
}

const getStatusList = () => {
  return [
    // {
    //   text: i18n.t('KnownPeopleDetection:all'),
    //   value: 'ALL'
    // },
    {
      text: i18n.t('Dashboard:alarmStates.active'),
      value: 'true'
    },
    {
      text: i18n.t('Dashboard:alarmStates.dismissed'),
      value: 'false'
    }
  ];
}

function getMetaFromAlert({ algorithm, detection }: { algorithm: AlgorithmType, detection: ICarDetection | IFaceDetection | IIntrusionDetection | {} }) {
  if (isCarDetection(algorithm)) {
    const { subject_area = '-', subject_user_type = '', subject_kana = '', subject_number = '' } = detection as ICarDetection;
    const primaryText = `${subject_area}${subject_user_type} ${subject_kana}${subject_number}`;
    return {primaryText}
  } else if (isFaceDetection(algorithm)) {
    const { subject_full_name = '-', age = null, gender = null } = detection as IFaceDetection;
    const primaryText= (subject_full_name === 'UNKNOWN') ? i18n.t('Dashboard:unknown') : subject_full_name;
    let ageText='', genderText='';
    if (age !== null) {
      ageText = i18n.t('Dashboard:age') + ': ' + i18n.t(`${age}`);
    }
    if (gender !== null) {
      genderText = i18n.t('Dashboard:gender') + ': ' + i18n.t(`Dashboard:${gender}`);
    }

    return ({primaryText, ageText, genderText});
  } else {
    const primaryText = '-';
    return {primaryText}
  }
}

interface IHeaderRow {
  title: string
  minWidth?: string
  alignment?: TypeCellAlignment
}

interface IEventRow {
  id: number
  cellItems: ICellItem[]
}

interface IDashboardEvents {
  cameras: Camera[];
}

interface ISelectedFilterValues {
  type: IFilterValue,
  location: IFilterValue,
  status: IFilterItem|null
}

const initialFilterValues: ISelectedFilterValues = {
  type: null,
  location: null,
  status: null
}
const getStatusValue = (status:IFilterItem|null) => {
  const {value=null}= status||{}
  switch(value){
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return undefined;
  }
}

const DashboardEvents: React.FC<IDashboardEvents> = ({ cameras }) => {
  const [eventsRows, setEventsRows] = useState<IEventRow[]>([]);
  const [animateDown, setAnimateDown] = useState(false);
  const { alerts, actions: { dismissAlert, fetchAlerts, setAlertFilter } } = useAlerts();
  const [selectedFilterValues, setSelectedFilterValues] = useLocalStorage<ISelectedFilterValues>('dashboard.filters', { ...initialFilterValues });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const { getParam, updateParam } = useSelected();
  const { locations, loading: locationsLoading, actions: { fetchLocations } } = useLocations();
  const wasDismissed = useRef(false);
  const reCenterParam = getParam(DASHBOARD_PARAMS.reCenter);

  const { t } = useTranslation(['Dashboard']);
  const { push } = useHistory();

  const headerConfig: IHeaderRow[] = [
    {
      title: ''
    },
    {
      title: '',
      minWidth: '20px'
    },
    {
      title: t('type'),
    },
    {
      title: t('when'),
    },
    {
      title: t('meta'),
      minWidth: '120px'
    },
    {
      title: t('location'),
      minWidth: '100px'
    },
    {
      title: t('zone'),
      minWidth: '100px'
    },
    {
      title: t('camera'),
      minWidth: '100px'
    },
    {
      title: t('alarmState'),
      minWidth: '100px',
      alignment: 'center',
    },
    {
      title: t('actions')
    }
  ];

  const getState = useCallback((isActive: boolean) => {
    return isActive ? t('Dashboard:alarmStates.active') : t('Dashboard:alarmStates.dismissed')
  }, [t])

  const handleDismiss = useCallback(async (alert: Alert) => {
    wasDismissed.current = true;
    const wasUpdated = await dismissAlert(alert);

    if (!wasUpdated) {
      wasDismissed.current = false;
    }

  }, [dismissAlert]);

  const handleLocationClick = useCallback((layer = 1, locId = '', zId = '', camId = '') => {
    updateParam(DASHBOARD_PARAMS.layer, layer);
    updateParam(DASHBOARD_PARAMS.locationId, locId);
    updateParam(DASHBOARD_PARAMS.zoneId, zId);
    updateParam(DASHBOARD_PARAMS.cameraId, camId);
    if (reCenterParam === 'required') {
      updateParam(DASHBOARD_PARAMS.reCenter, '');
    }
  }, [reCenterParam, updateParam]);

  const rowMaker = useCallback((): IEventRow[] => {

    const newRows: IEventRow[] = alerts.map((alert) => {
      const {
        id,
        alert_type,
        movie_url,
        datetime,
        camera_id,
        active,
        detection = {},
        algorithm,
        image_url,
        cropped_image_url
      } = alert;

      const meta = getMetaFromAlert({ algorithm, detection }) as IFaceMeta;

      const { location_name = '-', location_id, zone_id, name: cameraName, zone_name = '-' } = cameras.find(({ id }) => id === camera_id) || {};

      const actions: IAction[] = [];

      if (active) {
        actions.push({
          text: t('Dashboard:dismiss'),
          onClick: () => { handleDismiss(alert) }
        })
      } else {
        actions.push({
          blank: true
        })
      }

      actions.push({
        text: t('Dashboard:details'),
        onClick: () => {
          updateParam(DASHBOARD_PARAMS.tab, 'alert');
          updateParam(DASHBOARD_PARAMS.alertId, id);
        }
      });

      if (alert_type === 'UNKNOWN_DETECTED' && isCarDetection(algorithm)) {
        const { subject_area, subject_user_type, subject_kana, subject_number } = detection as ICarDetection;
        actions.push({
          text: t('Dashboard:register'),
          onClick: () => {
            push({
              pathname: '/number-plates/add',
              state: { isUnknown: true, area: subject_area, user_type: subject_user_type, kana: subject_kana, number: subject_number, image_url }
            })
          }
        });
      } else if (alert_type === 'UNKNOWN_DETECTED' && algorithm === 'FACE_IDENTIFICATION') {
        actions.push({
          text: t('Dashboard:register'),
          onClick: () => {
            push({
              pathname: '/people/add',
              state: { isUnknown: true, photo_url: cropped_image_url }
            })
          }
        });
      } else {
        actions.push({
          blank: true
        })
      }

      return {
        id,
        cellItems: [
          <RowThumbnail snapshotUrl={image_url} videoUrl={movie_url} />,
          <Icon icon={getTypeIcon(alert_type, algorithm, detection)} size={12} color='dimmed' weight='light' />,
          <AlertTypeText>{getAlertTypeLocal(alert_type, algorithm, detection)}</AlertTypeText>,
          <TableCellRelativeTime eventTime={datetime} />,
          <MetaText title={meta.primaryText + '\n' + meta.ageText + ' ' + meta.genderText}>
          {
            isFaceDetection(algorithm) ?
              <>
                {algorithm !== 'FACE_ANALYTICS' && <MetaLongText>{meta.primaryText}</MetaLongText>}
                <div>{meta.ageText + ' ' + meta.genderText}</div>
              </>
            : <>{meta.primaryText}</>
          }
          </MetaText>,
          <LongTextCell text={location_name} hasHover={true} onClickCallback={() => handleLocationClick(2, location_id)} />,
          <LongTextCell text={zone_name} hasHover={true} onClickCallback={() => handleLocationClick(3, location_id, zone_id)} />,
          <LinkCell text={cameraName ?? '-'} hasHover={true} fromPage='dashboard' onClickCallback={() => handleLocationClick(3, location_id, zone_id, camera_id)} id={camera_id} />,
          <StateText>{getState(active)}</StateText>,
          <RowActions {... { actions }} />
        ]
      }
    })

    return newRows;
  }, [alerts, cameras, t, getState, handleDismiss, handleLocationClick, updateParam, push]);

  useEffect(() => {
    setEventsRows(rowMaker());
  }, [rowMaker]);

  useEffect(() => {
    if (!wasDismissed.current) {
      setAnimateDown(true);
    } else {
      wasDismissed.current = false;
    }

  }, [alerts, wasDismissed])

  const handleEndAnimation = useCallback(() => {
    setAnimateDown(false);
  }, []);

  useEffect(() => {
    fetchLocations();
  }, [fetchLocations])

  const onClickClearAll = useCallback(() => {
    setSelectedFilterValues({ ...initialFilterValues });
  }, [setSelectedFilterValues]);

  const toggleFilter = () => {
    setIsFilterOpen((prev) => !prev);
  }

  const getApiParams = useCallback((): IFetchAlertOptions => {
    const limit = 100
    const { type, status, location } = selectedFilterValues;

    const active = getStatusValue(status);
    setAlertFilter({
      limit,
      active,
      alertType: type ? (type as IFilterItem[]).map(({ value }) => value) as string[] : null,
      location: location ? (location as IFilterItem[]).map(({ value }) => value) as number[] : null
    })
    return ({
      limit,
      alertType: type ? (type as IFilterItem[]).map(({ value }) => value).join(',') as string : undefined,
      active,
      locationId: location ? (location as IFilterItem[]).map(({ value }) => value).join(',') as string : undefined
    });
  }, [selectedFilterValues, setAlertFilter]);

  useEffect(() => {
    fetchAlerts(getApiParams());
  }, [fetchAlerts, getApiParams, selectedFilterValues])

  const handleFilterChange = useCallback((key: string) => (newSelection: IFilterValue) => {
    setSelectedFilterValues((prev) => ({ ...prev, [key]: newSelection }));
  }, [setSelectedFilterValues]);

  const getLocationNames = useCallback(() => {
    return (selectedFilterValues.location as IFilterItem[]).map(({ text }) => ' ' + text).join(',')
  }, [selectedFilterValues.location]);

  const getTypes = useCallback(() => {
    return (selectedFilterValues.type as IFilterItem[]).map(({ value }) => ' ' + t(TYPES[value])).join(',')
  }, [selectedFilterValues.type, t]);

  return (
    <Container>

      <Header>
        <TitleGroup>
          <Icon icon='Critical' size={12} color='dimmed' />
          <Title>{t('alarmsEvents')}</Title>
        </TitleGroup>


        <FilterWrapper isFilterOpen={isFilterOpen} {...(isFilterOpen ? {} : { onClick: toggleFilter })}>
          <IconWrapper {...(isFilterOpen ? { onClick: toggleFilter } : {})}>
            <Icon size={11} icon={isFilterOpen ? 'CloseCompact' : 'Left'} color='dimmed' />
          </IconWrapper>
          {isFilterOpen ?
            <>
              <FilterDropdown
                list={getTypesList()}
                onSelect={handleFilterChange('type')}
                optionType='checkbox'
                selected={selectedFilterValues.type}
                buttonIcon='AnalysisType'
                buttonText={t('type')}
                searchResultText={`${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`}
              // hasOptionsFilter
              />

              <FilterDropdown
                list={dropdownHelper(locations, { textKey: 'name', valueKey: 'id' })}
                onSelect={handleFilterChange('location')}
                optionType='checkbox'
                selected={selectedFilterValues.location}
                buttonIcon='Location'
                buttonText={t('location')}
                isLoading={locationsLoading}
                searchResultText={`${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`}
                hasOptionsFilter
              />

              <FilterDropdown
                list={getStatusList()}
                onSelect={handleFilterChange('status')}
                optionType='radio'
                selected={selectedFilterValues.status}
                buttonIcon='Notifications'
                buttonText={t('alertStatus')}
              />

              <ClearAllButton onClick={onClickClearAll} size='small'>{t('clearAll')}</ClearAllButton>
            </> :
            <FilterResultWrapper>
              <FiltersText>{t('filters')}</FiltersText>
              <SelectedFilterWrapper>
                {selectedFilterValues.type &&
                  <SelectedFiltersText title={getTypes()}>
                    {t('type')}: {getTypes()}
                  </SelectedFiltersText>}
                {selectedFilterValues.location &&
                  <SelectedFiltersText title={getLocationNames()}>
                    {selectedFilterValues.type && <>,&nbsp;</>}
                    {t('location')}: {getLocationNames()}
                  </SelectedFiltersText>}
                {selectedFilterValues.status &&
                  <SelectedFiltersText>
                    {(selectedFilterValues.type || selectedFilterValues.location) && <>,&nbsp;</>}
                    {(selectedFilterValues.status as IFilterItem).value === 'active' ? t('Dashboard:alarmStates.active') : t('Dashboard:alarmStates.dismissed')}
                  </SelectedFiltersText>}
              </SelectedFilterWrapper>
            </FilterResultWrapper>}
        </FilterWrapper>
      </Header>

      <TableWrapper>
        <EventTable>
          <TableHeader>
            {headerConfig.map(({ title, minWidth, alignment }, index) => {
              return <HeaderItem key={`${index}-header`} {...{ minWidth, alignment }} isFirst={index === 0}>{title}</HeaderItem>
            })}
          </TableHeader>
          {eventsRows.map(({ id, cellItems }) => {
            return (
              <EventRow
                key={id}
                rowId={id}
                rowData={cellItems}
                hasEntryAnimation={animateDown}
                animationEndCallback={handleEndAnimation}
              />
            )
          })}
        </EventTable>
      </TableWrapper>
    </Container>
  );
};

export default DashboardEvents;
