import { useCallback, useState } from 'react';

export interface IUsers {
  id: number,
  feature: string,
  username: string,
  created_at: Date | string,
  notes: string,
  name: string,
  is_admin: string
}

interface IUsersResponse {
  users: IUsers[],
  message: string;
  info: {
    limit: number,
    total: number,
    offset: number;
  }
}

export interface IFetchUsersOptions {
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortDirection?: string;
}

export interface IFeatures {
  [key: string]: boolean
}

export interface IUser {
  id: number,
  feature: IFeatures,
  username: string,
  name: string,
  is_admin: boolean,
  password: string
}

export interface IUserPayload extends Partial<IUser> {
  id?: number,
  feature: IFeatures,
  username: string,
  name: string,
  notes?: string,
  is_admin: boolean,
  password: string
}

interface IUserResponse {
  user: IUser,
  message: string
}

interface IUpdatePasswordPayload {
  current_password: string
  new_password: string,
}

export const useUserManagement = () => {
  const [users, setUsers] = useState<IUsers[]>([]);
  const [user, setUser] = useState<IUser>();
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [usersLoading, setUsersLoading] = useState<boolean>(true);
  const [userLoading, setUserLoading] = useState<boolean>(true);
  const [usersErrorMessage, setUsersErrorMessage] = useState('');
  const [userErrorMessage, setUserErrorMessage] = useState('');

  const fetchUsers = useCallback(async (options?: IFetchUsersOptions) => {
    const {
      limit = 100,
      offset = 0,
      sortBy,
      sortDirection
    } = options || {};

    setUsersLoading(true);
    setUsersErrorMessage('');
    try {

      const searchString = new URLSearchParams({
        limit: '' + limit,
        offset: '' + offset,
        ...(sortBy ? { sort_by: `${sortBy}` } : {}),
        ...(sortDirection ? { sort_direction: `${sortDirection}` } : {})
      });

      const response = await fetch('/api/user?' + searchString);

      if (response.ok) {
        const { users = [], info: { total } } = await response.json() as IUsersResponse;
        setUsers(users);
        setTotalUsers(total);      
      } else {
        console.error(`Users fetch error: ${response.statusText}`);
        setUsersErrorMessage(`Users fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setUsersErrorMessage(error.message);
      } else {
        console.error(error);
        setUsersErrorMessage('Unknown Error fetching Users');
      }
    } finally {
      setUsersLoading(false);
    }
  }, []);

  const fetchUser = useCallback(async (id: number) => {
    setUserLoading(true);
    setUserErrorMessage('');
    try {
      const response = await fetch('/api/user/' + id);

      if (response.ok) {
        const { user } = await response.json() as IUserResponse;
        setUser(user);
      } else {
        console.error(`User fetch error: ${response.statusText}`);
        setUserErrorMessage(`User fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setUserErrorMessage(error.message);
      } else {
        console.error(error);
        setUserErrorMessage('Unknown Error fetching User');
      }
    } finally {
      setUserLoading(false);
    }
  }, []);

  const deleteUser = useCallback(async (id: number) => {
    try {
      const res = await fetch(`/api/user/${id}`, {
        method: 'DELETE',
      });
      if (!res.ok) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const updateUser = useCallback(async (data: IUserPayload, method: string) => {
    const {id, ...payload} = data;
    setUserErrorMessage('');
    try {
      const response = await fetch(method === 'POST' ? '/api/user' : `/api/user/${id}`, {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        const { user } = await response.json() as IUserResponse;
        setUser(user);
        return true;
      } else {
        console.error(`User update error: ${response.statusText}`);
        setUserErrorMessage(`User update error: ${response.statusText}`);
        return false;
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setUserErrorMessage(error.message);
      } else {
        console.error(error);
        setUserErrorMessage('Unknown Error updating User');
      }
      return false;
    }
  }, []);

  const updatePassword = useCallback(async (payload: IUpdatePasswordPayload) => {
    setUserErrorMessage('');
    try {
      const response = await fetch('/api/change_password', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        return true;
      } else {
        console.error(`Password update error: ${response.statusText}`);
        setUserErrorMessage(`Password update error: ${response.statusText}`);
        return false;
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setUserErrorMessage(error.message);
      } else {
        console.error(error);
        setUserErrorMessage('Unknown Error updating Password');
      }
      return false;
    }
  }, []);

  return {
    users,
    totalUsers,
    usersLoading,
    usersErrorMessage,
    user,
    userLoading,
    userErrorMessage,
    actions: {
      fetchUsers,
      fetchUser,
      updateUser,
      updatePassword,
      deleteUser
    }
  }
}