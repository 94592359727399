import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import L from 'leaflet';
import {
  MapConsumer,
  MapContainer,
  Pane,
} from 'react-leaflet';
import MapLocations from '../molecules/MapLocations';

import {
  camerasZIndex,
  locationsZIndex,
  mapOverlayZIndex,
  zoneZIndex,
  ZOOM_LAYER0,
  ZOOM_LAYER3,
  ZOOM_LAYER_TOP,
} from '../mapSetup';

import { Location } from '../../../hooks/useLocations';
import CameraMarks from '../molecules/CameraMarks';
import { Camera } from '../../../hooks/useCameras';
import MapZones from '../molecules/MapZones';
import { Zone } from '../../../hooks/useZones';
import { StatsMap } from '../../../hooks/useStats';
import MapStats from '../atoms/MapStats';
import { CameraAlertCount } from '../../../hooks/useAlertCount';
import { LAYER, MapZoomListener } from '../atoms/MapZoomListener';
import MapHoverProvider from '../../../context/MapHoverContext';
import DashboardOverlay from '../atoms/DashboardOverlay';
import { MapSettings } from '../../../types';
import { getMapBounds } from '../mapUtils';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import MapTopBar from '../molecules/MapTopBar';

const MapWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
  #map {
    background-color: hsl(204, 15%, 42%);
    height: calc(100% - 40px);
    width: 100%;
  }
`;

// https://leafletjs.com/reference.html#map-pane

const OverlayPane = styled(Pane)`
  z-index: ${mapOverlayZIndex};
`;

const CamerasPane = styled(Pane)`
  z-index: ${camerasZIndex};
`;

const ZonesPane = styled(Pane)`
  z-index: ${zoneZIndex};
`;

const LocationsPane = styled(Pane)`
  z-index: ${locationsZIndex};
`;

const FullMapHiddenImage = styled.img`
  display: none;
`;

interface IDashboardMap {
  locations: Location[]
  cameras: Camera[]
  zones: Zone[]
  stats: StatsMap
  alertCounts: CameraAlertCount,
  mapResize: boolean;
}
const { REACT_APP_BUILD_VERSION=Date.now() } = process.env;
/// TODO : Fix Query Params update should also set the map

const DashboardMap: React.FC<IDashboardMap> = ({ cameras, zones, locations, alertCounts, stats, mapResize }) => {
  const fullMapImgRef = useRef<HTMLImageElement>(null);
  const [statsVisible, setStatsVisible] = useState(true);
  const [defaultMapSettings, setDefaultMapSettings] = useState<MapSettings | null>(null);
  const { getParam, updateParam } = useSelected();
  const layer = getParam(DASHBOARD_PARAMS.layer);
  const mapWrapperRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<L.Map>();

  const setDefaults = useCallback(() => {
    if (fullMapImgRef.current && fullMapImgRef.current.height !== 0) {
      const { height, width } = fullMapImgRef.current;
      const defBounds = getMapBounds(height, width);
      setDefaultMapSettings({
        bounds: defBounds,
        maxBounds: defBounds,
        center: [-height / 2, width / 2],
        zoom: ZOOM_LAYER0,
        minZoom: ZOOM_LAYER_TOP,
        maxZoom: ZOOM_LAYER3
      });
    }
  }, []);

  useEffect(()=>{
    if(mapRef.current) {
      mapRef.current.invalidateSize();
    }
  },[mapResize]);

  useEffect(() => {
    if (!layer) {
      updateParam(DASHBOARD_PARAMS.layer, LAYER.locations);
    }
  }, [layer, updateParam]);

  const toggleStats = useCallback(() => {
    setStatsVisible((prev) => !prev);
  }, []);

  const toggleFullScreen = useCallback(() => {
    if (mapWrapperRef.current && document.fullscreenElement == null) {
      mapWrapperRef.current.requestFullscreen();
    } else if (document.fullscreenElement !== null) {
      document.exitFullscreen();
    }
  }, []);

  return (
    <MapHoverProvider>
      <MapWrapper ref={mapWrapperRef}>
        <MapTopBar {...{ statsVisible, cameras, zones, locations, toggleStats, toggleFullScreen }} />
        {/* This fullMapUrl might become a prop when handling 100 maps*/}
        <FullMapHiddenImage ref={fullMapImgRef} onLoad={setDefaults} src={`/data/maps/tbe-main-map.jpg?hostname=${REACT_APP_BUILD_VERSION}`} />
        {defaultMapSettings && fullMapImgRef.current && (
          <MapContainer
            id="map"
            crs={L.CRS.Simple}
            center={defaultMapSettings.center}
            zoom={defaultMapSettings.zoom}
            minZoom={defaultMapSettings.minZoom}
            maxZoom={defaultMapSettings.maxZoom}
            maxBoundsViscosity={1.0}
            scrollWheelZoom={false}
            attributionControl={false}
          >
            <MapConsumer>
              {(map) => {
                mapRef.current = map;
                return null
              }}
            </MapConsumer>
            <OverlayPane name='map-pane'>
              <DashboardOverlay
                {...{ locations, zones, cameras, defaultMapSettings }}
                fullMap={fullMapImgRef.current}
              />
            </OverlayPane>
            <CamerasPane name='cameras-pane'>
              <CameraMarks {...{ cameras, alertCounts }} />
            </CamerasPane>
            <ZonesPane name='zones-pane'>
              <MapZones {...{ cameras, zones, locations, alertCounts }} />
            </ZonesPane>
            <LocationsPane name='locations-pane'>
              <MapLocations {...{ locations, cameras, alertCounts }} />
            </LocationsPane>
            <MapZoomListener {...{ zones, locations }} />
          </MapContainer>
        )}
        <MapStats {...{ stats, statsVisible, zones, locations, cameras }} />
      </MapWrapper>
    </MapHoverProvider>
  );
}

export default DashboardMap;
