import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { INodeTargets, INodeOptionSet, IPipelineNodeCore } from '..';

export const MetaLabel = styled.div`
  font-family: ${({theme}) => theme.fontFamily.data};
  font-weight: 600;
  color: hsl(194, 10%, 52%);
  text-transform: titlecase;
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 5px;
`
export const MetaValue = styled.div`
  font-family: ${({theme}) => theme.fontFamily.data};
  font-weight: 500;
  color: rgb(120, 139, 145);
  text-transform: titlecase;
  font-size: 12px;
  line-height: 14px;
  margin: 0 0 5px;
`

interface INodeSectionDetails {
  type: INodeTargets;
  index: number;
  config?: INodeOptionSet[];
  configFields?: IPipelineNodeCore['data']['configFields'],

}


const NodeSectionDetails : React.FC<INodeSectionDetails> = ({type: _, index, config, configFields=[]}) => {
  const { t } = useTranslation(['Common']);

  const createSingleValueReadout = (config : INodeOptionSet, value: any, optionIndex: number) => {
    const {label} = config;
    return (
      <MetaValue key={`nodedetails-${index}-${optionIndex}`}>{label}: {value || t('eventRules.notSet')}</MetaValue>
    )
  }

  const createMultiValueReadout = (config : INodeOptionSet, value: any, optionIndex: number) => {
    const {label} = config;
    const commaSeparatedValues = Array.isArray(value) ? value.map((item) => t(`${item}`)).join(', ') : t(value);
    return (
      <MetaValue key={`nodedetails-${index}-${optionIndex}`}>{label}: {commaSeparatedValues || t('eventRules.notSet')}</MetaValue>
    );
  };

  const createCheckboxField = (config : INodeOptionSet, value: any, optionIndex: number) => {
    const {label} = config;

    return (
      <MetaValue key={`option-${index}-${optionIndex}`}>{value ? label : null}</MetaValue>
    )
  }


  return (
    <>
      <MetaLabel>{t('eventRules.details')}</MetaLabel>
        {config && config.length > 0 ?
        config?.map((conf, i) => {
          const {fieldType} = conf;
          const value = configFields[i];
          switch(fieldType){
            case 'select':
              return createMultiValueReadout(conf, value, i);
            case 'text':
              return createSingleValueReadout(conf, value, i);
            case 'checkbox':
              return createCheckboxField(conf, value, i);
            default:
              throw new Error();
          }
        })
      : <MetaValue>{t('eventRules.notSet')}</MetaValue>}

    </>
  )

}

export default NodeSectionDetails;