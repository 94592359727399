import React from 'react';
import styled from 'styled-components';
import { Icon } from 'scorer-ui-kit';
import NodeButton from './NodeButton';

const Container = styled.div`
  position: relative;
  background-color: hsl(208, 52%, 95%);
  border-radius: 3px;
  box-shadow: 0 4px 5px 0px hsla(206, 61%, 55%, 0.071);
  border: 1px solid rgb(77, 181, 255);
`
const Header = styled.div`
  font-family: ${({theme}) => theme.fontFamily.ui};
  display: flex;
  min-height: 50px;
`

const ButtonContainer = styled.div`
  position: absolute;
  right: -12px;
  top: -12px;

  display: grid;
  grid-template-columns: 24px;
  grid-auto-flow: column;
  column-gap: 5px;

`

const IconSection = styled.div`
  flex: 0 0 37px;
  border-right: hsl(206, 43%, 90%) 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TitleSection = styled.div`
  flex: 1;
  padding: 12px;
`

const MetaLabel = styled.div`
  font-family: ${({theme}) => theme.fontFamily.ui};
  font-weight: 500;
  color: hsl(197, 29%, 71%);
  font-size: 10px;
  line-height: 10px;
  margin: 0 0 5px;
`

const Title = styled.h3`
  font-family: ${({theme}) => theme.fontFamily.ui};
  font-weight: 600;
  color: hsl(194, 10%, 52%);
  font-size: 14px;
  line-height: 14px;
  margin: 0 0 5px;
`

const Overview = styled.h4`
  font-family: ${({theme}) => theme.fontFamily.data};
  color: hsl(194, 10%, 52%);
  font-weight: 500;
  font-size: 14px;
  /* line-height: 14px; */
  margin: 0;

`

const Content = styled.div`
  border-top: hsl(206, 43%, 90%) 1px solid;
  padding: 12px;
`

interface INode {
  title: string;
  meta?: string;
  icon?: string;
  editing?: boolean;
  overview?: string;
  handlerSetEdit?: any;
  handlerRemoveNode?: any;
}

const Node : React.FC<INode> = ({title, icon = 'Question', meta, editing, overview, handlerSetEdit, handlerRemoveNode, children}) => {

  return (
    <Container>
      <ButtonContainer>
        {handlerSetEdit ? <NodeButton icon='Edit' onClick={handlerSetEdit} /> : null }
        {handlerRemoveNode ? <NodeButton icon='CloseCompact' onClick={handlerRemoveNode} /> : null }
      </ButtonContainer>
      <Header>
        <IconSection>
          <Icon size={18} weight='light' color='dimmed' {...{icon}} />
        </IconSection>
        <TitleSection>
          <MetaLabel>{meta}</MetaLabel>
          <Title>{title}</Title>
          {!editing && !(title === 'Face Analysis' || title === '顔属性検知リスト') ? <Overview>{overview ? overview : ''}</Overview> : null}
        </TitleSection>
      </Header>
      {React.Children.toArray(children).some(a=>a) ?
        <Content>{children}</Content>
        : null}

    </Container>
  )
}

export default Node;