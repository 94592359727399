import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonWithIcon, Icon, useTo, TagList, useModal, ButtonWithLoading } from 'scorer-ui-kit';
import styled from 'styled-components';
import { Camera } from '../../hooks/useCameras';
import { getDetectionTypesLocal, getTagList, getTimeSince } from '../../utils';
import { AlertsCountWithIcon } from './AlertsCountWithIcon';
import Breadcrumb from './Breadcrumb';
import DismissAllAlertsModal from './DismissAllAlertsModal';
import ValueWithIcon from './ValueWithIcon';
import { useAlerts } from '../../hooks/useAlerts';

const Container = styled.div``;

const Button = styled(ButtonWithIcon)`
  width: min-content;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px 15px;
  margin-left: 45px;
  padding: 0 26px 10px 9px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  height: 77px;
`;

const IconWrapper = styled.div`
  min-width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.divider};
`;

const CameraDetailsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
  margin-bottom: 16px;
`;

const MetaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 12px 22px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 16px 21px 23px;
`;

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  line-height: 1.67;
  color: #8b9196;
`;

const DescriptionTitle = styled(Description)`
  font-weight: 500;
  margin-bottom: 10px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-left: 20px;
  overflow: hidden;
`;

interface ICameraInfo {
  cameraDetails: Camera,
  totalAlerts: number,
  lastAlert: string,
  dismissAllAlerts: (property: string, alertId: number) => Promise<void>
}

const CameraInfo: FC<ICameraInfo> = ({ cameraDetails: { name = '-', location_name = '-', zone_name = '-', tags = '', detection_types, details, id }, totalAlerts, lastAlert, dismissAllAlerts }) => {

  const { t } = useTranslation(['Dashboard', 'Common']);
  const to = useTo();
  const { createModal, setModalOpen } = useModal();
  const { isAlertsPaused, actions: { setIsAlertsPaused, fetchAlerts } } = useAlerts();

  const onDismiss = useCallback(async () => {
    if (isAlertsPaused) return;
    setIsAlertsPaused(true);
    try {
      await dismissAllAlerts('camera', id);
      await fetchAlerts();
    } catch (error) {      
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    } finally {
      setIsAlertsPaused(false);
    }
  }, [id, isAlertsPaused, dismissAllAlerts, fetchAlerts, setIsAlertsPaused]);

  const onDismissAllCallback = useCallback(async () => {
    setModalOpen(false);
    onDismiss();
  }, [setModalOpen, onDismiss]);

  const openDismissAllModal = useCallback(() => {
    createModal({
      isCloseEnable: true,
      closeText: t('Common:close'),
      width: '580px',
      padding: false,
      customComponent: <DismissAllAlertsModal onDismissAll={onDismissAllCallback} />,
    });
  }, [createModal, onDismissAllCallback, t]);

  const onDismissAll = useCallback(() => {
    openDismissAllModal();
  }, [openDismissAllModal]);

  return (
    <Container>

      <TitleContainer>
        <IconWrapper>
          <Icon icon='Camera' color='dimmed' size={18} />
        </IconWrapper>
        <TitleWrapper>
          <Breadcrumb list={[location_name, zone_name]} title={name} />
          <AlertsCountWithIcon count={totalAlerts} rightMargin='15px' minWidth='20px'/>
        </TitleWrapper>
      </TitleContainer>

      <CameraDetailsContainer>
        <IconWrapper>
          <Icon icon='Information' color='dimmed' size={18} />
        </IconWrapper>

        <MetaWrapper>
          <MetaContainer>
            {tags && <TagList tagsConfig={getTagList(tags)} />}
            {/* <ValueWithIcon icon='Circle' value='Online' /> */} {/* todo status will be shown in next phase */}
            <ValueWithIcon icon='Analyse' value={getDetectionTypesLocal(detection_types)} />
            <ValueWithIcon icon='Notifications' value={`${t('cameraTab.lastAlert')} ${getTimeSince(lastAlert)}`} />
          </MetaContainer>

          <DescriptionContainer>
            <DescriptionTitle>{t('cameraTab.description')}</DescriptionTitle>
            <Description>{details || '-'}</Description>
          </DescriptionContainer>
        </MetaWrapper>

      </CameraDetailsContainer>

      <ButtonsWrapper>
        {
          !isAlertsPaused ?
          <Button icon='NotificationsOff' position='left' size='small' design='secondary' onClick={onDismissAll} disabled={totalAlerts === 0}>{t('cameraTab.dismissAllAlerts')}</Button>
          :
          <ButtonWithLoading loading={isAlertsPaused} position="left" size='small' design='secondary'>{t('cameraTab.dismissAllAlerts')}</ButtonWithLoading>}
        <Button icon='Link' position='left' size='small' design='secondary' onClick={to(`cameras/${id}?fromPage=dashboard`)}>{t('cameraTab.goToCameraPage')}</Button>
      </ButtonsWrapper>

    </Container>
  )
};

export default CameraInfo;