import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Content, PageHeader, Button, ButtonWithLoading, useTo, TextField, useNotification, ConfirmationModal, useModal } from 'scorer-ui-kit';
import styled from 'styled-components';
import PortTable from '../components/relay-controller/molecules/PortTable';
import { IRelayControllerInfo, IRelayInfo, useRelayController } from '../hooks/useRelayController';
import { useRelays } from '../hooks/useRelays';
import { IParams } from '../types';

const Container = styled(Content)`
  overflow: inherit;
  margin-bottom: 30px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`

const CancelButton = styled(Button)`
  margin-right: 9px;
`;
const DeleteButton = styled(Button)`
  margin-left: 9px;
`;

const BtnGroup = styled.div`
  display: flex;
`;

const FormRow = styled.div`
  display: flex;
  label {
    width: 100%;
  }
  label:first-child {
    margin-right: 20px;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.colors.divider};
  margin: 38px 0 29px 0;
`;

const MiddleSection = styled.div`
  margin-top: 53px;
`;

const Title = styled.div`
  font-size: 20px;
  color: hsl(208, 8%, 38%);
  margin-bottom: 22px;
`;


const AddEditRelayController: React.FC = () => {
  const { t } = useTranslation(['RelayController']);
  const to = useTo();
  const { relayControllerID }: IParams = useParams();
  const { isLoading, actions: { addRelayController, getRelayControllerById, updateRelayController, deleteRelayController } } = useRelayController();
  const { relays, actions: { fetchRelays } } = useRelays();
  const { sendNotification } = useNotification();
  const { createModal } = useModal();
  const [relayControllerForm, setRelayControllerForm] = useState<IRelayControllerInfo>({
    name: '',
    address: '',
    snmp_community_string: '',
    relays: [
      { name: 'Port 0', port: 1, duration: 15, on_delay: 0, active: true },
      { name: 'Port 1', port: 2, duration: 15, on_delay: 0, active: true },
      { name: 'Port 2', port: 3, duration: 15, on_delay: 0, active: true },
      { name: 'Port 3', port: 4, duration: 15, on_delay: 0, active: true },
      { name: 'Port 4', port: 5, duration: 15, on_delay: 0, active: true },
      { name: 'Port 5', port: 6, duration: 15, on_delay: 0, active: true },
      { name: 'Port 6', port: 7, duration: 15, on_delay: 0, active: true },
      { name: 'Port 7', port: 8, duration: 15, on_delay: 0, active: true },
    ]
  });

  const validateForm = useCallback((): boolean => {

    if (!relayControllerForm.name.trim()) {
      sendNotification({ type: 'error', message: t('error.missingName') });
      return false;
    }

    if (relayControllerForm.address === '') {
      sendNotification({ type: 'error', message: t('error.missingUrl') })
      return false;
    }

    if (relayControllerForm.snmp_community_string === '') {
      sendNotification({ type: 'error', message: t('error.missingCommunityString') })
      return false;
    }

    return true;
  }, [relayControllerForm.address, relayControllerForm.snmp_community_string, relayControllerForm.name, sendNotification, t]);

  const handleAdd = useCallback(async () => {

    const isFormValid = validateForm();
    if (!isFormValid) {
      return;
    }

    const result = await addRelayController(relayControllerForm);

    if (result) {
      sendNotification({ type: 'success', message: t('result.addSuccess') })
      to('/relay-controller')();
    } else {
      sendNotification({ type: 'error', message: t('result.addError') })
    }

  }, [addRelayController, relayControllerForm, sendNotification, t, to, validateForm])

  const handleUpdate = useCallback(async () => {
    const isFormValid = validateForm();
    const id = parseInt(relayControllerID);
    if (!isFormValid||!id) {
      return;
    }


    const result = await updateRelayController({id, ...relayControllerForm});

    if (result) {
      sendNotification({ type: 'success', message: t('result.editSuccess') })
      to('/relay-controller')();
    } else {
      sendNotification({ type: 'error', message: t('result.editError') })
    }

  }, [relayControllerForm, relayControllerID, sendNotification, t, to, updateRelayController, validateForm])

  const handleNameUpdate = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setRelayControllerForm((prev) => ({ ...prev, name: value }));
  }, [])

  const handleCommunityStringUpdate = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setRelayControllerForm((prev) => ({ ...prev, snmp_community_string: value }))
  }, [])

  const handleAddress = useCallback(({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setRelayControllerForm((prev) => ({ ...prev, address: value }))
  }, [])

  const initializeRelayController = useCallback(async () => {

    if (!relayControllerID) {
      return;
    }

    const initialValues = await getRelayControllerById(relayControllerID);
    const controllerRelays: IRelayInfo[] = relays.sort((a, b) => (a.port - b.port))

    if (initialValues !== null) {
      const { name, address, snmp_community_string } = initialValues;
      setRelayControllerForm((prev) => ({ ...prev, name, address, snmp_community_string, relays: controllerRelays }))
    }
  }, [getRelayControllerById, relayControllerID, relays]);



  const handleDeleteRelayController = useCallback(async (id: number) => {
    const validId = id.toString();
    const result = await deleteRelayController(validId);

    if (result) {
      sendNotification({ type: 'success', message: t('result.deleteSuccess') })
      to('/relay-controller')();
      if (!result) {
        console.error('Fetching failed, please check if config has change in the backend');
      }
    } else {
      sendNotification({ type: 'error', message: t('result.deleteError') })
    }

  }, [deleteRelayController, sendNotification, t, to])

  const openConfirmationModal = useCallback((relayControllerID: string) => {
    const rcID = Number.parseInt(relayControllerID);
    createModal({
      isCloseEnable: false,
      customComponent: (
        <ConfirmationModal
          title={t('confirmRelayControllerDeletion')}
          message={t('deleteRelayControllerMsg')}
          leftButtonText={t('Common:cancel')}
          leftButtonDesign='secondary'
          rightButtonText={t('Common:delete')}
          rightButtonDesign='danger'
          rightButtonCallback={() => handleDeleteRelayController(rcID)}
        />
      )
    });
  }, [createModal, handleDeleteRelayController, t]);

  useEffect(() => {
    initializeRelayController();
  }, [initializeRelayController])

  useEffect(() => {
    fetchRelays({device_id: relayControllerID});
  }, [fetchRelays, relayControllerID])

  const handleRelaysChange = useCallback((updatedRelays: IRelayInfo[]) => {
    setRelayControllerForm((prev) => ({ ...prev, relays: updatedRelays }))
  }, [])

  const handleKeyPress = (event: { key: string; preventDefault: () => void; }) => {
    if (!/^[a-zA-Z0-9 \-*/.]+$/.test(event.key)) {
      event.preventDefault();
    }
  };

  return (
    <Container>
      <TopSection>
        <PageHeader
          icon='LayoutGrid'
          title={relayControllerID ? t('editRelayController') : t('addRelayController')}
        />
        <BtnGroup>
          <CancelButton design='secondary' size='small' onClick={to('/relay-controller')}>{t('Common:cancel')}</CancelButton>
          <ButtonWithLoading size='small' onClick={relayControllerID ? handleUpdate : handleAdd} loading={isLoading} >{t('Common:save')}</ButtonWithLoading>
          {relayControllerID && <DeleteButton design='danger' size='small' onClick={() => openConfirmationModal(relayControllerID)} >{t('Common:delete')}</DeleteButton>}

        </BtnGroup>
      </TopSection>
      <MiddleSection>
        <FormRow>
          <TextField
            fieldState="default"
            label={t('deviceName')}
            name="deviceName"
            maxLength={128}
            onKeyPress={handleKeyPress}
            onChange={handleNameUpdate}
            value={relayControllerForm.name}
            required
          />
          <TextField
            fieldState="default"
            label={t('deviceAddress')}
            name="deviceAddress"
            maxLength={256}
            value={relayControllerForm.address}
            onChange={handleAddress}
            required
          />
        </FormRow>

        <TextField
          fieldState="default"
          label={t('communityString')}
          name="communityString"
          maxLength={64}
          value={relayControllerForm.snmp_community_string}
          onChange={handleCommunityStringUpdate}
          required
        />
      </MiddleSection>
      <Divider />
      {relayControllerForm.relays && <>
        <Title>{t('relay')}</Title>
        <PortTable relays={relayControllerForm.relays} onRelaysChange={handleRelaysChange} />
      </>}

    </Container>
  );
};

export default AddEditRelayController;