import React, { useMemo, useState } from 'react';


export interface IFeaturesContext {
  features: IFeatures
  setFeatures: (features: IFeatures) => void
}

export type FeatureList = keyof IFeatures
export interface IFeatures {
  // tascube?: boolean
  tas?: boolean;
  tasStreaming?: boolean;
  cameraEdit?: boolean;
  cameraConfig?: boolean;
  cameraFiles?: boolean;
  cameraSnapshot?: boolean;
  streamingAnnotations?: boolean;
  registerUnknown?: boolean;
  updatePassword?: boolean;
  userManagement?: boolean;
  //Base detection permissions
  intrusionDetection?: boolean;
  faceDetection?: boolean;
  faceAnalysis?: boolean;
  peopleCount?: boolean;
  carCount?: boolean;
  numberPlateDetection?: boolean;
  // Other Features:
  relayController?: boolean;
  eventPipeline?: boolean;
  numberPlateImport?: boolean;
  systemExport?: boolean;
}

const defaultContext: IFeaturesContext = {
  features: {},
  setFeatures: () => {
    throw new Error('Error FeatureContext used outside FeatureProvider')
  }
}


const FeaturesContext = React.createContext<IFeaturesContext>(defaultContext);

const FeaturesProvider: React.FC = ({ children }) => {
  const [features, setFeatures] = useState<IFeatures>({});

  const featuresContext = useMemo(() => ({ features, setFeatures }), [features]);
  return (
    <FeaturesContext.Provider value={featuresContext}>
      {children}
    </FeaturesContext.Provider>
  );
}


export { FeaturesContext }
export default FeaturesProvider