import React, { useCallback, useEffect, useState } from 'react';
import { SmallInput } from 'scorer-ui-kit';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { SlimInput } from '../../../themes/common';
import EventRow, { ICellItem } from '../../dashboard-table/atoms/EventRow';
import { IRelayInfo } from '../../../hooks/useRelayController';
import { regexOnly5Numbers } from '../../../utils';
import CheckboxWithTitle from '../atoms/CheckboxWithTitle';
import { TypeCellAlignment } from 'scorer-ui-kit/dist/Tables';

const Container = styled.div`
  display: table;
  width: 100%;
`;

const TableHeader = styled.div`
  display: table-row;
`;

const HeaderItem = styled.div<{ minWidth?: string, alignment?: TypeCellAlignment}>`
  display: table-cell;
  padding-bottom: 2px;
  padding-top: 2px;
  position: relative;
  color: hsl(207, 5%, 57%);
  font-size: 14px;

  ${({ minWidth }) => minWidth
    ? `min-width: ${minWidth};`
    : 'min-width: 70px;'
  };

  ${({ alignment }) => alignment && css`
    text-align: ${alignment};
  `};
`;

const StyledSlimInput = styled(SlimInput)`
  height: 30px;
  max-width: 400px;
`;

const StyledSmallInput = styled(SmallInput)`
  max-width: 200px;
  label {
    margin: 0;
  }
  span {
    margin: 0;
  }

  input {
    background: transparent;
  }
`;

const StyledCheckboxTitle = styled(CheckboxWithTitle)`
  justify-content: flex-end;
`;

interface IEventRow {
  id: number
  cellItems: ICellItem[]
}

interface IRelayTable {
  relays: IRelayInfo[]
  onRelaysChange?: (updatedRelays: IRelayInfo[]) => void
}

const PortTable: React.FC<IRelayTable> = ({
  relays,
  onRelaysChange = () => { }
}) => {
  const [rows, setRows] = useState<IEventRow[]>([]);
  const { t } = useTranslation(['RelayController']);

  const handleName = useCallback((newValue: string, port: number) => {
    const updatedRelays = [...relays];
    const foundPort = updatedRelays.find((relay) => port === relay.port);

    if (foundPort && newValue.length < 128) {
      foundPort.name = newValue;
      onRelaysChange(updatedRelays);
    }

  }, [onRelaysChange, relays])

  const handleTimeout = useCallback((newValue: string, port: number) => {
    const updatedRelays = [...relays];
    const foundPort = updatedRelays.find((relay) => port === relay.port);


    if (foundPort && (newValue.match(regexOnly5Numbers) || newValue === '')) {
      const newDuration = newValue === '' ? 0 : parseInt(newValue);
      foundPort.duration = newDuration;
      onRelaysChange(updatedRelays);
    }

  }, [onRelaysChange, relays]);

  const handleOnDelay = useCallback((newValue: string, port: number) => {
    const updatedRelays = [...relays];
    const foundPort = updatedRelays.find((relay) => port === relay.port);


    if (foundPort && (newValue.match(regexOnly5Numbers) || newValue === '')) {
      const newOnDelay = newValue === '' ? 0 : parseInt(newValue);
      foundPort.on_delay = newOnDelay;
      onRelaysChange(updatedRelays);
    }

  }, [onRelaysChange, relays]);

  const handleShow = useCallback((newValue: boolean, port: number) => {
    const updatedRelays = [...relays];
    const foundPort = updatedRelays.find((relay) => port === relay.port);


    if (foundPort) {
      foundPort.active = newValue;
      onRelaysChange(updatedRelays);
    }

  }, [onRelaysChange, relays])

  const rowMaker = useCallback(() => {
    const newRows = relays.map(({ name, port, duration, on_delay, active }, index) => {
      return {
        id: index,
        cellItems: [
          `${port}`,
          <StyledSlimInput value={name} onChange={(e) => { handleName(e.target.value, port) }} maxLength={128} />,
          <StyledSmallInput label={''} value={on_delay} type='number' max={86399} maxLength={5} onChange={(e) => { handleOnDelay(e.target.value, port) }} unit={t('portTable.seconds')} />,
          <StyledSmallInput label={''} value={duration} type='number' max={86399} maxLength={5} onChange={(e) => { handleTimeout(e.target.value, port) }} unit={t('portTable.seconds')} />,
          <StyledCheckboxTitle checked={active} onChangeCallback={(checked) => { handleShow(checked, port) }} />,
        ]
      }
    })

    setRows(newRows)
  }, [handleName, handleOnDelay, handleShow, handleTimeout, relays, t])

  useEffect(() => {
    rowMaker()
  }, [rowMaker])

  return (
    <Container>
      <TableHeader>
        <HeaderItem>
          {t('portTable.port')}
        </HeaderItem>
        <HeaderItem minWidth='420px'>
          {t('portTable.name')}
        </HeaderItem>
        <HeaderItem minWidth='200px'>
          {t('portTable.onDelay')}
        </HeaderItem>
        <HeaderItem minWidth='200px'>
          {t('portTable.timeout')}
        </HeaderItem>
        <HeaderItem alignment='right'>
          {t('portTable.showRelay')}
        </HeaderItem>
      </TableHeader>

      {rows.map(({ id, cellItems }) => {
        return (
          <EventRow key={id} rowId={id} rowData={cellItems} noStripes />
        )
      })}
    </Container>
  );
};

export default PortTable;