import React, { ButtonHTMLAttributes } from 'react';
import { Icon, resetButtonStyles } from 'scorer-ui-kit';
import styled from 'styled-components';

const ButtonContainer = styled.button`
  ${resetButtonStyles};
  color: hsl(0, 0%, 50%);
  display: flex;
  align-items: center;
  padding: 0 15px;
  flex-shrink: 0;
  :hover {
    background: ${({ theme }) => theme.styles.form.button.primary.default.backgroundImage};    
    color: hsl(0, 0%, 100%);
    > div > div > svg > g {
      stroke: hsl(0, 0%, 100%);
    }
  }
`;

const WrapperIcon = styled.div`
  margin-right: 12px;
  > div {
    display: flex;
    align-items: center;
  }
`;

interface OwnProps {
  icon: string
}

type ITopBarButton = OwnProps & ButtonHTMLAttributes<HTMLButtonElement>

const TopBarButton: React.FC<ITopBarButton> = ({
  icon,
  children,
  ...props
}) => {

  return (
    <ButtonContainer {...props}>
      <WrapperIcon>
        <Icon {...{ icon }} size={18} color='dimmed' weight='light' />
      </WrapperIcon>

      {children}
    </ButtonContainer>
  );
};

export default TopBarButton;