import * as React from "react";
function SvgProductLogs(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <path d="M7.5 4h-4A1.5 1.5 0 002 5.5V22a1.5 1.5 0 001.5 1.5H20a1.5 1.5 0 001.5-1.5V5.5A1.5 1.5 0 0020 4h-4" />
        <path d="M15.671 4.987a.751.751 0 01-.712.513H8.541a.751.751 0 01-.712-.513l-1-3A.749.749 0 017.541 1h8.418a.749.749 0 01.712.987l-1 3zM18.5 7v9.878a1.5 1.5 0 01-.439 1.061l-2.122 2.121a1.5 1.5 0 01-1.06.44H5.75a.75.75 0 01-.75-.75V7" />
        <path d="M14 20.5v-3a1.5 1.5 0 011.5-1.5h3" />
      </g>
    </svg>
  );
}
export default SvgProductLogs;
