import { FC, useState } from 'react';
import { Icon, useTo } from 'scorer-ui-kit';
import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
  
const IconContainer = styled.span`
  > div {
    display: flex;
    align-items: center;
  }
  cursor: pointer;
`;

const Text = styled.p<{hasHover: boolean}>`
  margin-right: 7px;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  color: hsla(195,9.8%,51.8%,0.722);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 104px;
  ${({ hasHover }) => hasHover && css`
    cursor: pointer;
    :hover {
      color: hsl(205, 100%, 65%);
    }
  `};
`;

interface ILinkCell {
  text: string,
  id: number,
  hasHover?: boolean,
  fromPage?: string,
  onClickCallback?: () => void
}

const LinkCell: FC<ILinkCell> = ({ text = '-', id, fromPage = '', hasHover = false, onClickCallback = () => {}}) => {

  const [hovering, setHovering] = useState(false);

  const to = useTo();

  const onHoverProps = {
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false),
  };

  return (
    <Container title={text}>
      <Text hasHover={hasHover} onClick={onClickCallback}>{text}</Text>
      <IconContainer {...onHoverProps} onClick={to(`/cameras/${id}?fromPage=${fromPage}`)}>
        <Icon icon='Forward' color={hovering ? 'primary' : 'dimmed'} size={12} weight='light'/>
      </IconContainer>
    </Container>
  );
};

export default LinkCell;