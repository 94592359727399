import * as React from "react";
function SvgAnalysisType(props: ISvgIcons) {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <path
        d="M18.75 3.75V1.118A1.128 1.128 0 0017.619 0H1.125A1.121 1.121 0 000 1.112v13.519c.005.62.51 1.12 1.131 1.119H10.5M0 3.75h18.75m3.547 17.641l-4.185-6.96a.753.753 0 01-.112-.394V9.75a.75.75 0 00-.75-.75h-1.5a.75.75 0 00-.75.75v4.287c0 .14-.039.276-.112.394l-4.408 6.96a.749.749 0 00.658 1.109h10.5a.75.75 0 00.659-1.109h0zm-2.49-4.141h-6.704M21 5.25v3m-1.5-1.5h3m-12 0v3M9 8.25h3"
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      />
    </svg>
  );
}
export default SvgAnalysisType;
