import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Camera } from '../../hooks/useCameras';
import CameraInfo from './CameraInfo';
// import CameraVideo from './CameraVideo';
import VideoStream from '../atoms/VideoStream';
import EmptyState from '../atoms/EmptyState';
import { Button } from 'scorer-ui-kit';
import FeatureCondition from '../atoms/FeatureContition';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const VideoContainer = styled.div`
  width: 100%;
  min-height: 250px;
`;

const SwitchContainer = styled.div`
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.divider};
`
const Label = styled.div`
  width: 72px;
  height: 14px;
  margin: 3px 10px 3px 0;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  font-weight: 600;
  color: #8d8d8d;
`
const VideoModeSwitch = styled.div`

`
const On = styled(Button)`
  border-radius: 3px 0 0 3px;
`
const Off = styled(Button)`
  border-radius: 0 3px 3px 0;

`
interface ICameraTab {
  cameraDetails: Camera | undefined,
  totalAlerts: number,
  lastAlert: string,
  dismissAllAlerts: (property: string, alertId: number) => Promise<void>
}

const CameraTab: FC<ICameraTab> = ({ cameraDetails, totalAlerts, lastAlert, dismissAllAlerts }) => {

  const { t } = useTranslation('Dashboard');
  const { id, meta:{ rtspURL = ''}={} } = cameraDetails || {};
  const [cameraAnnotations, setCameraAnnotations] = useState(false);
  const url = '/websocket_sink/socket.io';
  const [streamMatch, setStreamMatch] = useState<RegExp>();

  useEffect(()=>{
    const streamMatch = cameraAnnotations ? new RegExp(`${id}_detections$`) : new RegExp(`${id}$`);
    setStreamMatch(streamMatch);
  },[cameraAnnotations, id]);

  useEffect(()=>{
    if(rtspURL === '' ) return;
    fetch('/api_streaming/streaming',{
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body:JSON.stringify({
        URI: rtspURL,
        STREAM_ID: `${id}`
      })
    });
  },[id, rtspURL]);


  return (
    <Container>

      {cameraDetails ?
        <>
          <VideoContainer>
            <VideoStream url={url} streamMatch={streamMatch} />
          </VideoContainer>
          <FeatureCondition enabledFeatures={['streamingAnnotations']} >
            <SwitchContainer>
              <Label>Annotations:</Label>
              <VideoModeSwitch>
                <On
                  design={cameraAnnotations ? 'primary': 'secondary'}
                  size='xsmall'
                  onClick={()=>setCameraAnnotations(true)}
                >ON</On>
                <Off
                  design={!cameraAnnotations ? 'primary': 'secondary'}
                  size='xsmall'
                  onClick={()=>setCameraAnnotations(false)}
                >OFF</Off>
              </VideoModeSwitch>
            </SwitchContainer>
          </FeatureCondition>

          <CameraInfo {...{ cameraDetails, totalAlerts, lastAlert, dismissAllAlerts }} />
        </> :
        <EmptyState icon='Camera' message={t('cameraTab.noCameraSelected')} />}

    </Container >
  )
};

export default CameraTab;