import React, { useCallback, useEffect, useState } from 'react';
import { DASHBOARD_PARAMS, useSelected } from '../../../hooks/useSelected';
import { Icon } from 'scorer-ui-kit';
import styled from 'styled-components';
import { Camera } from '../../../hooks/useCameras';
import { Zone } from '../../../hooks/useZones';
import { Location } from '../../../hooks/useLocations';
import { getBreadCrumbs } from '../../../utils';
import { useTranslation } from 'react-i18next';
const { locationId, zoneId, cameraId, reCenter } = DASHBOARD_PARAMS;

const Container = styled.div`
  display: flex;
  width: 100%;
  padding-left: 20px;
`;

const LayerGroup = styled.div`
  display: flex;
  align-items: center;
  color: hsl(0, 0%, 50%);
  font-size: 12px;
`;

const LayerTextHeading  = styled.span`
  cursor: pointer;
  :hover {
    color: hsl(205, 100%, 65%);
  }
`;

const LayerText = styled.div`
  margin-left: 8px;
`;

const CrumbList = styled.div`
  display: flex;
  align-items: center;
  color: hsl(0, 0%, 50%);
  font-size: 14px;
`;

const CrumbItem = styled.div`
  display: flex;
  align-items: baseline;
  overflow: hidden;
`;


const CrumbText = styled.div`
  margin: 0 10px;
  cursor: pointer;
  :hover {
    color: hsl(205, 100%, 65%);
  }
`;

const IconWrapper = styled.div`
    & > div {
      display: flex;
      align-items: center;
    }
`;

interface IBreadCrumbList {
  crumb: string,
  layer: number,
  locId?: string,
  zId?: string,
  camId?: string
}

interface ITopBreadCrumbs {
  locations: Location[]
  zones: Zone[]
  cameras: Camera[]
}

const TopBreadCrumbs: React.FC<ITopBreadCrumbs> = ({ locations, zones, cameras, ...props }) => {
  const [breadcrumbList, setBreadcrumbList] = useState<IBreadCrumbList[]>([]);
  const { getParam, updateParam } = useSelected();
  const layer = getParam(DASHBOARD_PARAMS.layer);
  const reCenterParam = getParam(reCenter);
  const camId = getParam(cameraId);
  const locId = getParam(locationId);
  const zId = getParam(zoneId);
  const { t } = useTranslation(['Dashboard']);

  useEffect(() => {

    setBreadcrumbList(getBreadCrumbs(locId, zId, camId, cameras, zones, locations))

  }, [getParam, cameras, locations, zones, locId, zId, camId]);

  const handleSegmentClick = useCallback((layer, locId = '', zId = '', camId = '') => {
      updateParam(DASHBOARD_PARAMS.layer, layer);
      updateParam(DASHBOARD_PARAMS.locationId, locId);
      updateParam(DASHBOARD_PARAMS.zoneId, zId);
      updateParam(DASHBOARD_PARAMS.cameraId, camId);
      if (reCenterParam === 'required') {
        updateParam(DASHBOARD_PARAMS.reCenter, '');
      }
  }, [reCenterParam, updateParam]);

  return (
    <Container {...props}>
      <LayerGroup>
        <IconWrapper><Icon icon='ProductMapLayers' size={16} color='dimmed' weight='light' /></IconWrapper>
        <LayerText onClick={() => handleSegmentClick(1)}>
          <LayerTextHeading>{t('mapTopBar.layerHeading')}</LayerTextHeading>{` ${layer}${breadcrumbList.length !== 0 ? ' ::' : ''}`}
        </LayerText>
      </LayerGroup>
      <CrumbList>
        {breadcrumbList.map(({crumb, layer, locId, zId, camId}, index) => {
          return (
            <CrumbItem key={index} >
              {(index !== 0) && < IconWrapper > <Icon icon='Right' color='dimmed' size={9} weight='light' /></IconWrapper>}
              <CrumbText onClick={() => handleSegmentClick(layer, locId, zId, camId)}>{crumb}</CrumbText>
            </CrumbItem>
          )
        })}
      </CrumbList>
    </Container >
  );
};

export default TopBreadCrumbs;