import { useCallback, useState } from 'react';

export interface IPerson {
  id: number,
  category: string,
  description: string,
  features: string,
  full_name: string,
  age: number | null,
  gender: string | null,
  photo_url: string,
  tags: string,
  type: string,
}

export interface IPersonPayload extends Partial<IPerson> {
  id?: number
}

interface IPersonResponse {
  person: IPerson,
  message: string
}

interface IImageResponse {
  message: string,
  img_path?: string,
  features?: string
}

export const usePerson = () => {
  const [person, setPerson] = useState<IPerson>();
  const [personLoading, setPersonLoading] = useState<boolean>(true);
  const [personErrorMessage, setPersonErrorMessage] = useState('');

  const fetchPerson = useCallback(async (id: number) => {
    setPersonLoading(true);
    setPersonErrorMessage('');
    try {
      const response = await fetch('/api/people/' + id);

      if (response.ok) {
        const { person } = await response.json() as IPersonResponse;
        setPerson(person);
      } else {
        console.error(`Person fetch error: ${response.statusText}`);
        setPersonErrorMessage(`Person fetch error: ${response.statusText}`);
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setPersonErrorMessage(error.message);
      } else {
        console.error(error);
        setPersonErrorMessage('Unknown Error fetching person');
      }
    } finally {
      setPersonLoading(false);
    }
  }, []);

  const deletePerson = useCallback(async (id: number) => {
    try {
      const res = await fetch(`/api/people/${id}`, {
        method: 'DELETE',
      });
      if (!res.ok) {
        return false;
      }
      return true;
    } catch (error) {
      return false;
    }
  }, []);

  const updatePerson = useCallback(async (payload: IPersonPayload, method: string) => {
    setPersonErrorMessage('');
    try {
      const response = await fetch(method === 'POST' ? '/api/people' : `/api/people/${payload.id}`, {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        const { person } = await response.json() as IPersonResponse;
        setPerson(person);
        return true;
      } else {
        console.error(`Person update error: ${response.statusText}`);
        setPersonErrorMessage(`Person update error: ${response.statusText}`);
        return false;
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setPersonErrorMessage(error.message);
      } else {
        console.error(error);
        setPersonErrorMessage('Unknown Error updating person');
      }
      return false;
    }
  }, []);

  const uploadPhoto = useCallback(async (file) => {
    try {
      const response = await fetch('/face_detection/register', {
        method: 'POST',
        body: file
      });

      if (response.ok) {
        const { message, img_path, features } = await response.json() as IImageResponse;
        return ({ message, img_path, features });
      } else {
        const res = await response.json() as { message: string };
        return ({ message: res.message });
      }

    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
      return ({ message: 'Photograph upload error' });
    }
  }, []);

  return {
    person,
    personLoading,
    personErrorMessage,
    actions: {
      fetchPerson,
      uploadPhoto,
      updatePerson,
      deletePerson
    }
  }
}