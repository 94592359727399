import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Button, IconButton, SelectField, SmallInput, Switch, useModal} from 'scorer-ui-kit';

import { INodeTargets, INodeOptionSet, IPipelineNodeCore } from '..';
import { useTranslation } from 'react-i18next';
import { useEmailTemplates } from '../../../hooks/useEmailTemplates';
import { IEmailPayload, useEmailAction } from '../../../hooks/useEmailAction';
import EmailPreviewModal from '../Modal/EmailPreviewModal';

const NodeButtonRow = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  margin-top: 10px;

  button {
    margin-left: 10px;
  }
`

const Title = styled.h3`
  font-family: ${({theme}) => theme.fontFamily.ui};
  font-weight: 600;
  color: hsl(194, 10%, 52%);
  font-size: 14px;
  line-height: 14px;
  margin: 0 0 5px;
`

const DestinationRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  gap: 9px;
  > div {
    height: 30px;

    //input
    &:nth-child(2){
      flex: 1;
      & > label {
        width: 100%;
      }
    }
    //Inconsistent span height
    label span {
      margin: 0 !important;
    }
  }
`
const HeadingRow = styled.div<{marginTop?: string}>`
  margin-top: ${({marginTop = '0'}) => marginTop};
  margin-bottom: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const SwitchContainer = styled.div`
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`


const validateEmail = (address:string)=> /^[^\s@]+@[^\s@]+$/.test(address);

const ErrorContainer = styled.div<{hasError?: string}>`
  height: 30px;
  padding: 6px;
  color: hsl(0, 0%, 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-size: 14px;
  font-weight: bold;
  ${(({hasError})=> hasError
    ?
      css`
        border-radius: 3px;
        background-color: hsl(0, 79%, 70%);
        margin-bottom: 10px;
      `
      :
      css``)}

`

const  NoTemplateFound = styled.h3``;

const ErrorBox: React.FC<{errorMessage: string}> = ({errorMessage}) => {
  return (
    <ErrorContainer hasError={errorMessage}>
      {errorMessage}
    </ErrorContainer>
  )
}

interface INodeSectionEdit {
  type: INodeTargets;
  index: number;
  config?: INodeOptionSet[];
  configFields?: IPipelineNodeCore['data']['configFields'],
  handlerCancel?: any
  handlerUpdate?: any
}


const NodeSectionEditEmail: React.FC<INodeSectionEdit> = ({ type, index, config, configFields = [], handlerCancel, handlerUpdate }) => {
  const { t } = useTranslation(['Common']);
  const { emailTemplates=[], actions:{  fetchEmailTemplates} } = useEmailTemplates();
  const { email, actions:{  fetchEmailAction, updateEmailAction} } = useEmailAction();
  const [destinations, setDestinations] = useState<{addressType: string, address: string}[]>([{addressType: 'to', address: ''}]);
  const { createModal } = useModal();
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [emailAction, setEmailAction] = useState<IEmailPayload>({
    email_bcc: '',
    email_cc: '',
    email_to: '',
    snapshot: true,
    cropped_snapshot: true,
    clip: true
  });
  const [action_id = null]=configFields;

  const validateForm = useCallback(()=>{
    if(!emailAction.template_id){
      setErrorMessage(t('eventRules.unselectedEmailTemplate'));
    } else if(destinations.some(({address})=>!validateEmail(address))){
      setErrorMessage(t('eventRules.invalidEmail'));
    } else if(destinations.filter(({addressType}) => addressType === 'to').length < 1){
      setErrorMessage(t('eventRules.needOneToAddress'));
    } else if(destinations.filter(({addressType}) => addressType === 'to').length > 10){
      setErrorMessage(t('eventRules.tooManyToRecipients'));
    } else if(destinations.filter(({addressType}) => addressType === 'cc').length > 10){
      setErrorMessage(t('eventRules.tooManyCCRecipients'));
    } else if(destinations.filter(({addressType}) => addressType === 'bcc').length > 10){
      setErrorMessage(t('eventRules.tooManyBCCRecipients'));
    } else {
      setErrorMessage('');
      return true
    }
    return false;
  },[destinations, emailAction.template_id, t])

  const saveEmailTemplate = useCallback(async ()=>{
    if(!validateForm()) return;
    const email_to = destinations
      .filter(({addressType, address}) => addressType === 'to' && address !== '')
      .map(({address}) => address)
      .join(',')
    const email_cc = destinations
      .filter(({addressType, address}) => addressType === 'cc' && address !== '')
      .map(({address}) => address)
      .join(',')
    const email_bcc = destinations
      .filter(({addressType, address}) => addressType === 'bcc' && address !== '')
      .map(({address}) => address)
      .join(',')
    const body = {...emailAction, email_to,email_bcc,email_cc};
    const {id=null} = await updateEmailAction(body)||{}

    const {name:templateName='-'} = emailTemplates.find(({ id }) => id === emailAction.template_id)||{};

    handlerUpdate && handlerUpdate({ target: type, index, config, overview:  `${t('eventRules.sendUsingTemplate')}: "${templateName}"`,  configFields: [id] });

  },[config, destinations, emailAction, emailTemplates, handlerUpdate, index, t, type, updateEmailAction, validateForm])

  const setTemplate = useCallback((template)=>{
    setEmailAction((emailAction)=> ({...emailAction, template_id: parseInt(template)}))
  },[]);

  const setDestinationAddressType = useCallback(index => (addressType: string) => {
    setDestinations((d)=> [...d.slice(0,index),{...d[index],addressType},...d.slice(index+1,d.length)])
  },[]);

  const setDestinationAddress = useCallback(index => ({target:{value:address}}: ChangeEvent<HTMLInputElement>) =>
    setDestinations((d)=> [...d.slice(0,index),{...d[index],address},...d.slice(index+1,d.length)])
  ,[]);

  const removeDestination = useCallback(index => () =>
    setDestinations((d)=> [...d.slice(0,index),...d.slice(index+1,d.length)])
  ,[])

  const handlePreview = useCallback(() => {

    const foundTemplate = emailTemplates.find(({ id }) => id === emailAction.template_id)

    if (foundTemplate) {
      const { name, subject, body } = foundTemplate;

      createModal({
        isCloseEnable: true,
        closeText: t('Common:close'),
        customComponent: (
          <EmailPreviewModal {...{ name, subject, body }} />
        )
      });
    } else {
      createModal({
        isCloseEnable: true,
        closeText: t('Common:close'),
        customComponent: (
          <NoTemplateFound>
            {t('Common:eventRules.noTemplateFound')}
          </NoTemplateFound>
        )
      });
    }

  },[createModal, emailAction.template_id, emailTemplates, t]);

  useEffect(()=>{
    fetchEmailTemplates();
  },[fetchEmailTemplates]);

  const validateEmailField = useCallback((address?:string)=>(!address || address ===''||validateEmail(address)) ? 'default' : 'invalid',[])

  useEffect(()=>{
    if(action_id !== null){
      fetchEmailAction(action_id);
    }
  },[action_id, fetchEmailAction]);

  useEffect(()=>{
    if(!email || action_id === null) return;

      setEmailAction(email);
      const {email_to='',email_cc='', email_bcc=''} = email||{};
      setDestinations([
        ...email_to.split(',').filter(a => a!=='').map(address => ({address, addressType: 'to'})),
        ...email_cc.split(',').filter(a => a!=='').map(address => ({address, addressType: 'cc'})),
        ...email_bcc.split(',').filter(a => a!=='').map(address => ({address, addressType: 'bcc'}))
      ]);

  },[action_id, email]);

  return (
    <>
      <HeadingRow marginTop='3px'>
        <Title>{t('eventRules.template')}</Title>
        <Button size='xsmall' design='secondary' disabled={!emailAction.template_id} onClick={handlePreview}>{t('eventRules.previewTemplate')}</Button>
      </HeadingRow>

      <SelectField isCompact value={emailAction.template_id} label={{ text: '', htmlFor: 'template_id' }} changeCallback={setTemplate} placeholder={t('eventRules.selectTemplate')} >
        {
          emailTemplates.map((option, index) => (
            <option key={index} value={option.id}>{option.name}</option>
          ))
        }
      </SelectField>

      <HeadingRow marginTop='6px'>
          <Title>{t('eventRules.recipient')}</Title>
          <Button size='xsmall' design='secondary' disabled={destinations.length >= 30} onClick={()=>setDestinations(d=>[...d,{address:'', addressType:'to'}])} >{t('eventRules.addRecipient')}  </Button>
      </HeadingRow>

      {
        destinations.map(({addressType, address = ''}, index) => (
          <DestinationRow key={index} >
            <SelectField isCompact defaultValue={addressType} changeCallback={setDestinationAddressType(index)} label={{ text: '', htmlFor: 'addressType' }} >
              <option selected={addressType==='to'} value='to' >{t('eventRules.to')}</option>
              <option selected={addressType==='cc'} value='cc' >{t('eventRules.cc')}</option>
              <option selected={addressType==='bcc'} value='bcc' >{t('eventRules.bcc')}</option>
            </SelectField>

            <SmallInput  type='text' fieldState={validateEmailField(address)}  value={address} onChange={setDestinationAddress(index)} label=''/>

            <IconButton icon='ActionRemove' size={15} color='dimmed' hoverColor='danger' disabled={destinations.length===1} onClick={removeDestination(index)} ></IconButton>
          </DestinationRow>
        ))
      }

      <ErrorBox errorMessage={errorMessage} />

      <HeadingRow >
        <Title>{t('eventRules.attachments')}</Title>
      </HeadingRow>
      <SwitchContainer>
        {<Switch checked={emailAction.snapshot} onChangeCallback={()=> setEmailAction((e)=>({...e, snapshot: !e.snapshot}))} labelText={t('eventRules.detectionSnapshotFull')}></Switch>}
        {<Switch checked={emailAction.cropped_snapshot} onChangeCallback={()=> setEmailAction((e)=>({...e, cropped_snapshot: !e.cropped_snapshot}))} labelText={t('eventRules.detectionSnapshotCropped')}></Switch>}
        {<Switch checked={emailAction.clip}  onChangeCallback={()=> setEmailAction((e)=>({...e, clip: !e.clip}))}  labelText={t('eventRules.detectionMovieClip')}></Switch>}
      </SwitchContainer>



      <NodeButtonRow>
        {handlerCancel ? <Button size='xsmall' design='secondary' onClick={() => handlerCancel(type, index, false)}>{t('cancel')}</Button> : null}
        {handlerUpdate ? <Button size='xsmall' onClick={saveEmailTemplate}>{t('save')}</Button> : null}
      </NodeButtonRow>
    </>
  )

}

export default NodeSectionEditEmail;
