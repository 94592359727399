import { useCallback, useState } from 'react';
import { NumberPlate } from './useNumberPlates';

export interface IImportEntries extends Omit<NumberPlate, 'id'> {}

export interface IImportData {
  mode: string,
  new_entries: IImportEntries[],
  updated_entries: IImportEntries[],
  deleted_entries: IImportEntries[]
}

export interface ICsvData {
  new_entries: NumberPlate[],
  updated_entries: NumberPlate[],
  deleted_entries: NumberPlate[],
  failed_entries: {
    entry: NumberPlate,
    failure_code: number,
    column_name: string,
    column_length: number
  }[]
}

export const useNumberPlateImport = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingCsvData, setLoadingCsvData] = useState<boolean>(false);
  const [loadingImport, setLoadingImport] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [csvData, setCsvData] = useState<ICsvData>({
    new_entries: [],
    updated_entries: [],
    deleted_entries: [],
    failed_entries: []
  });

  const uploadFile = useCallback(async (text: string): Promise<boolean> => {
    setIsLoading(true);
    const blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
    const formData = new FormData();
    formData.append('data_file', blob)

    try {
      const response = await fetch('/api/number_plate_import', {
        method: 'POST',
        body: formData
      })

      if (!response.ok) {
        return false;
      }
      return true;
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        return false;
      } else {
        console.error(error);
        return false;
      }
    } finally {
      setIsLoading(false);
    }

  }, []);

  const uploadCsv = useCallback(async (text: string): Promise<boolean> => {
    let result = false;
    setLoadingCsvData(true);

    const blob = new Blob([text], { type: 'text/csv;charset=utf-8;' });
    const formData = new FormData();
    formData.append('data_file', blob)

    try {
      const response = await fetch('/api/number_plate_import_check?mode=sync', {
        method: 'POST',
        body: formData
      });
      if (!response.ok) {
        result = false;
      } else {
        const res = await response.json();
        setCsvData(res.data);
        result = true;
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
      result = false;
    } finally {
      setLoadingCsvData(false);
    }
    return result;
  }, []);

  
  const importCsv = useCallback(async (payload: IImportData) => {
    setErrorMessage('');
    setLoadingImport(true);
    try {
      const response = await fetch('/api/number_plate_import_json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      const res = await response.json();
      if (response.ok) {
        if (!res.failed_rows) {
          setErrorMessage('');
        } else {
          setErrorMessage(res.message);
        }
      } else {
        setErrorMessage(res.message);
      }
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
        setErrorMessage(error.message);
      } else {
        console.error(error);
        setErrorMessage('unknownImportError');
      }
    } finally {
      setLoadingImport(false);
    }
  }, []);

  return {
    isLoading,
    loadingCsvData,
    loadingImport,
    csvData,
    errorMessage,
    actions: {
      uploadFile,
      uploadCsv,
      importCsv
    }
  }
}