import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertBar, Button, ButtonWithLoading, Label, PasswordField, useModal, useNotification } from 'scorer-ui-kit';
import styled from 'styled-components';
import { useUser } from '../../hooks/useUser';
import { useUserManagement } from '../../hooks/useUserManagement';

const Container = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
`;

const LabelTitle = styled(Label)`
  font-size: 20px;
  margin-bottom: 14px;
`;

const StatusBar = styled.div<{ color: string }>`
  width: 130px;
  height: 5px;
  margin: -6px 0 37px 0;
  border-radius: 2.5px;
  background-color: ${({ color }) => color};
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0 10px;
`;

const StatusBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StatusText = styled(Label)`
  font-size: 12px;
  margin-bottom: 0;
  margin-top: -13px;
  > span {
    margin-bottom: 0;
  }
`;
const AlertBox = styled.div`
  margin-bottom: 10px;
`;

interface Alert {
  type: 'error'|'warning'|'info'|'success';
  message: string;
}

interface IPassword {
  current_password: string,
  new_password: string,
  confirmPassword: string
}

const UpdatePassword: FC = () => {
  const {userErrorMessage, actions: { updatePassword }} = useUserManagement();
  const { actions: { logout } } = useUser();
  const [updateLoading, setUpdateLoading] = useState<boolean>(false);
  const [password, setPassword] = useState<IPassword>({ current_password: '', new_password: '', confirmPassword: '' });
  const [status, setStatus] = useState('#d9d9d9');
  const { t } = useTranslation(['Users', 'AddEditUser', 'Common']);
  const [goodPassword, setGoodPassword] = useState(''); 
  const [alert, setAlert] = useState<Alert|null>(null);
  const { setModalOpen } = useModal();
  const { sendNotification } = useNotification();

  useEffect(() => {
    if (!password.new_password && !password.confirmPassword) {
      setStatus('#d9d9d9');
      setGoodPassword('');
      return;
    }
    if (password.new_password === password.confirmPassword) {
      setStatus('#9dd09d');
      setGoodPassword('matched');
    } else {
      setStatus('#ff6666');
      setGoodPassword('notMatched');
    }
  }, [password]);
  

  const onChangeHandler = useCallback(({ target: { name, value } }) => {
    setPassword(prev => ({ ...prev, [name]: value }));
  }, []);

  const UpdatePassword = useCallback(async () => {
    const { current_password, new_password } = password;
    if (new_password.length < 8) {
      setAlert({
        message: t('AddEditUser:passwordLengthError'),
        type: 'error'
      });
      return;
    }
    setUpdateLoading(true);

    const res: boolean = await updatePassword({current_password, new_password});

    if (res) {
      setUpdateLoading(false);
      setModalOpen(false);
      logout();
    } else {
      setUpdateLoading(true);
      sendNotification({ type: 'error', message: userErrorMessage });
    }
  }, [userErrorMessage, t, updatePassword, setModalOpen, logout, sendNotification, password]);

  return (
    <Container>
      <LabelTitle htmlFor='' labelText={t('AddEditUser:updatePasswordText')} />
      <PasswordField
        tabIndex={1}
        name='current_password'
        label={t('oldPassword')}
        fieldState='default'
        onChange={onChangeHandler}
        value={password.current_password}
        autoComplete='new-password'
      />
      <PasswordField
        name='new_password'
        label={t('newPassword')}
        fieldState='default'
        onChange={onChangeHandler}
        value={password.new_password}
        autoComplete='new-password'
      />
      <PasswordField
        name='confirmPassword'
        label={t('confirmNewPassword')}
        fieldState='default'
        onChange={onChangeHandler}
        value={password.confirmPassword}
        autoComplete='new-password'
      />
      <StatusBox>
        <StatusBar color={status} />
        <StatusText htmlFor='' labelText={t(goodPassword)} />
      </StatusBox>
      {alert && <AlertBox onClick={() => setAlert(null)}><AlertBar type={alert.type} message={alert.message} /></AlertBox>}
      <ButtonBox>
        <Button design='secondary' onClick={() => { setModalOpen(false); }}>{t('Common:cancel')}</Button>
        {!updateLoading ?
          <Button design='primary' onClick={UpdatePassword} disabled={password.current_password === '' || !goodPassword || goodPassword !== 'matched'}>{t('AddEditUser:updatePasswordText')}</Button>
          : <ButtonWithLoading loading={updateLoading} position='left' design='primary'>{t('AddEditUser:updatePasswordText')}</ButtonWithLoading>}
      </ButtonBox>
    </Container>
  );
};

export default memo(UpdatePassword);