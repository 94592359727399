import React from 'react';
import styled, { css } from 'styled-components';
import { SVGOverlay } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import { getCentroid } from '../mapUtils';
import { Points } from '../../../types';

const baseRectStyle = css`
  pointer-events: none;
  fill: #FFF;
  stroke: #CCC;
  stroke-width: .4;
  rx: 1%;
  ry:1%;
  height: 14px;
`;

const baseTextStyle = css`
  pointer-events: none;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  fill: hsl(0, 0%, 50%);
  font-size: 8px;
  text-anchor: middle;
`;

const SvgContainer = styled(SVGOverlay)``;

const TitleRect = styled.rect`
  ${baseRectStyle};
  width: 70px;
  x: 15;
  y: 8;
`;

const TitleText = styled.text`
  ${baseTextStyle};
  transform: translate(50px, 18px);
`;

const CameraRect = styled.rect`
  ${baseRectStyle};
  width: 33px;
  x: 15px;
  y: 26px;
`;

const CameraText = styled.text`
  ${baseTextStyle};
  transform: translate(39px, 36px);
`;

const AlertRect = styled.rect`
  ${baseRectStyle};
  width: 33px;
  x: 52px;
  y: 26px;
`;

const AlertText = styled.text`
  ${baseTextStyle};
  transform: translate(76px, 36px);
`;

const CameraG = styled.g`
  pointer-events: none;
  transform: scale(0.4) translate(46px, 74px);
  vector-effect: non-scaling-stroke;
  height: 5px;
  width: 5px;

  g {
    stroke: hsl(0, 0%, 50%);
  }
`;

const AlertG = styled.g`
  pointer-events: none;
  transform: scale(0.4) translate(140px, 72px);
  vector-effect: non-scaling-stroke;

  g {
    stroke: hsl(0, 0%, 50%);
  }
`;

interface IPolygonInfo {
  name: string
  totalCameras: number
  totalAlerts: number
  bounds: LatLngTuple[]
  size: number
  points: Points[]
}

const LocationInfo: React.FC<IPolygonInfo> = ({ name, totalCameras, totalAlerts, bounds, size , points}) => {
  let offsetWidth = size;
  let offsetHeight = size / 2;
  let [lat, lng] = getCentroid(bounds);
  const pinRight = points[0].x === 3264;

  if(pinRight) {
    lat = L.latLngBounds(bounds).getNorthEast().lat - 120;
    lng = L.latLngBounds(bounds).getNorthEast().lng - 400;
  }

  const newBounds: LatLngTuple[] = [[lat - offsetHeight, lng - offsetWidth], [lat + offsetHeight, lng + offsetWidth]];

  return (
    <SvgContainer
      attributes={{ viewBox: '0 0 100 50' }}
      bounds={newBounds}
      interactive={false}
    >
      <TitleRect />
      <TitleText >{name}</TitleText>
      <CameraRect />
      <CameraText>{totalCameras}</CameraText>
      <AlertRect />
      <AlertText>{totalAlerts > 100 ? '100+' : totalAlerts}</AlertText>

      <CameraG fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g>
          <g>
            <path d="M8.25 12v3.333c-.097 1.562-1.437 2.754-3 2.667H.75M.75 15.75L.75 19.5M15 9H1.5c-.414 0-.75-.336-.75-.75V4.5C.75 2.015 2.765 0 5.25 0h16.5c.828 0 1.5.672 1.5 1.5v1.275c0 .593-.35 1.13-.891 1.371L16.5 6.751V7.5c0 .828-.672 1.5-1.5 1.5z" transform="translate(-438 -222) translate(438 222)" />
            <path d="M20.25 5.084V10.5c0 .828-.672 1.5-1.5 1.5H5.25c-1.657 0-3-1.343-3-3h0M23.25 6.75L23.25 9.75" transform="translate(-438 -222) translate(438 222)" />
          </g>
        </g>
      </CameraG>
      <AlertG fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
        <g>
          <g>
            <path d="M12 16.5c-.207 0-.375.168-.375.375s.168.375.375.375.375-.168.375-.375-.168-.375-.375-.375h0M12 13.5L12 6" transform="translate(-276 -222) translate(276 222)" />
            <path d="M1.28 13.281c-.707-.707-.707-1.854 0-2.561l9.44-9.439c.34-.34.8-.531 1.28-.531s.94.191 1.28.531l9.44 9.439c.707.707.707 1.854 0 2.561l-9.44 9.439c-.707.706-1.853.706-2.56 0l-9.44-9.439z" transform="translate(-276 -222) translate(276 222)" />
          </g>
        </g>
      </AlertG>
    </SvgContainer>
  );
};

export default LocationInfo;