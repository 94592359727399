import { FC } from 'react';
import styled from 'styled-components';

const CellText = styled.div`
  font-size: 14px;
  color: hsla(195,10%,52%,0.72);
  font-family: ${({ theme }) => theme.fontFamily.data};
  font-style: italic;
  max-width: 150px;
`;

const TimeZone = styled.span`
  margin-left: 5px;
  font-size: 12px;
  color: hsla(195,10%,52%,0.72);
  font-family: ${({ theme }) => theme.fontFamily.data};
`;

interface ITextCell {
  text: string | Date
  timeZone?: string
}

const TimeCell: FC<ITextCell> = ({text, timeZone = 'JST'}) => {

  return <CellText>{text}<TimeZone>{timeZone}</TimeZone></CellText>
}

export default TimeCell;