import React, { ChangeEvent, FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { SelectField, TextField, PasswordField, TextAreaField, ButtonWithIcon } from 'scorer-ui-kit';
import styled from 'styled-components';
import { IForm } from '../../pages/AddEditUser';

const FormContainer = styled.div`
  margin-top: 53px;
`;

const SectionTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 16px;
  font-weight: 500;
  color: #5a6269;
  display: flex;

  &:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #eee;
    margin: auto;
    margin-left: 10px;
  }
`;

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 33px 0 10px;
  gap: 10px 20px;
  > div, > label {
    flex-grow: 1;
    width: 50%;
    max-width: 380px;
  }
  span::after{
    font-weight: bold;
    content: ' *';
  }
`;

const NotesBox = styled.div`
  max-width: 780px;
  margin-bottom: 65px;
  textarea {
    resize: none;
    min-width: 200px;
    max-width: 100%;
    min-height: 100px;
  }
  @media (max-width: 960px) {
    max-width: 380px;
  }
`;

interface IGeneralTab {
  userID: string,
  form: IForm,
  onFormChange: (name: string, value: string|boolean) => void
}

const GeneralTab: FC<IGeneralTab> = ({userID, form, onFormChange}) => {
  const [showNotes, setShowNotes] = useState<boolean>(false);

  const { t } = useTranslation(['AddEditUser', 'Users', 'Common']);

  const onChangeInput = useCallback(({target: {value, name}}: ChangeEvent<HTMLInputElement|HTMLTextAreaElement>) => {
    onFormChange(name, value);
  }, [onFormChange]);

  const onChangeSelect = useCallback((value: string) => {
    onFormChange('role', value);
  }, [onFormChange]);

  return (
    <FormContainer>
      <SectionTitle>{t('basicDetails')}</SectionTitle>

      <FieldsRow>
        <TextField
          name='username'
          label={t('Users:userName')}
          fieldState={userID ? 'disabled' : 'default'}
          value={form.username}
          onChange={onChangeInput}
          maxLength={64}
        />
        <SelectField
          label={{
            htmlFor: 'userType',
            text: t('userType'),
          }}
          changeCallback={onChangeSelect}
          placeholder={t('userTypePlaceholder')}
          defaultValue={userID ? form.is_admin ? 'admin' : 'user' : form.role}
        >
          <option value='admin'>{t('Users:admin')}</option>
          <option value='user'>{t('Users:user')}</option>
        </SelectField>
        <TextField
          name='name'
          label={t('fullName')}
          fieldState='default'
          value={form.name}
          onChange={onChangeInput}
          maxLength={128}
        />
      </FieldsRow>
      <NotesBox>
        {!showNotes && !form.notes ?
          <ButtonWithIcon
            design='primary'
            icon='Add'
            onClick={() => setShowNotes(true)}
            position='left'
            size='small'
          >
            {t('addNotes')}
          </ButtonWithIcon>
          :
          <TextAreaField
            tabIndex={3}
            value={form.notes}
            fieldState='default'
            label={t('Users:notes')}
            name='notes'
            onChange={onChangeInput}
            maxLength={256}
          />}
      </NotesBox>

      <SectionTitle>{userID ? t('updatePasswordText') : t('setPassword')}</SectionTitle>
      
      <FieldsRow>
        <PasswordField
          name='password'
          label={t('Common:password')}
          fieldState='default'
          value={form.password}
          maxLength={64}
          onChange={onChangeInput}
          onPaste={(e) => { e.preventDefault(); }}
          onCopy={(e) => { e.preventDefault(); }}
          autoComplete='new-password'
        />
        <PasswordField
          name='confirmPassword'
          label={t('confirmPassword')}
          fieldState='default'
          value={form.confirmPassword}
          maxLength={64}
          onChange={onChangeInput}
          onPaste={(e) => { e.preventDefault(); }}
          onCopy={(e) => { e.preventDefault(); }}
          autoComplete='new-password'
        />
      </FieldsRow>
    </FormContainer>
  )
}

export default GeneralTab;
