import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { IFetchCamerasOptions, useCameras } from '../../hooks/useCameras';
import styled from 'styled-components';
import { FilterBar, IFilterDropdownConfig, IFilterItem, IFilterResult, ISearchFilter, PageHeader, Spinner } from 'scorer-ui-kit';
import CameraCard from '../CameraCard';
import { useTranslation } from 'react-i18next';
import { PageHeaderWrapper } from '../../Style';
import { dropdownHelper, getDetectionTypesLocal, getSelected, readParams, updateParams } from '../../utils';
import { useZones } from '../../hooks/useZones';
import { useLocations } from '../../hooks/useLocations';
import { ISelectedFilterValues } from '../../pages/Cameras';
import { useHistory, useLocation } from 'react-router-dom';

const FilterWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: 48px;
`;

const CamerasContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
`;

const NoCamerasText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 20px;
  font-weight: 500;
`;

interface IList {
  [key: string]: any;
}

const initialFilterValues: ISelectedFilterValues = {
  type: undefined,
  location: undefined,
  zone: undefined,
  tags: undefined,
  camName: '',
  paramsLoaded: false,
}

const CameraGridView: FC = () => {
  
  const [dropdownConfig, setDropdownConfig] = useState<IFilterDropdownConfig[]>();
  const { locations, loading: locationsLoading, actions: { fetchLocations } } = useLocations();
  const { zones, loadingZones, actions: { fetchZones } } = useZones();
  const { cameras, loading, actions: { fetchCameras } } = useCameras();
  const typeList = useRef<IList[]>([]);
  const tagList = useRef<IList[]>([]);
  const [isCamerasAvailable, setIsCamerasAvailable] = useState(true);
  const [selectedFilterValues, setSelectedFilterValues] = useState<ISelectedFilterValues>(initialFilterValues);
  const { t } = useTranslation(['Cameras', 'Common']);

  const { push } = useHistory();
  const params = useLocation().search;
  const [historyParams] = useState(params);

  const searchers: ISearchFilter[] = [
    {
      id: 'camera-search',
      placeholder: t('filterByCameraName'),
    }
  ];
  
  // This will genrate unique dropdown items for Tags and Types filter
  useEffect(() => {
    if (cameras.length > 0 && isCamerasAvailable) {
      let tempTags: string[] = [];
      let analysisTypes: IList[] = [];
      cameras.forEach(camera => {
        const {tags = '', detection_types} = camera;
        analysisTypes = [...analysisTypes, {text: getDetectionTypesLocal(detection_types), value: detection_types}];
        const cameraTags = tags.split(',');
        tempTags = [...tempTags, ...cameraTags];
      });
      const uniqueTags = [...new Map(tempTags.map(item => [item, item])).values()];
      const uniqueTypes = [...new Map(analysisTypes.map(item => [item.text, item])).values()];
      typeList.current = uniqueTypes;
      tagList.current = uniqueTags.map(tag => {return {text: tag, value: tag}});
      setIsCamerasAvailable(false);
    }
  }, [cameras, isCamerasAvailable]);

  const getDropdownConfig = useCallback(() => {

    return ([
      {
        id: 'tags',
        buttonText: t('tags'),
        list: dropdownHelper(tagList.current, { textKey: 'text', valueKey: 'value' }),
        buttonIcon: 'MetaTags',
        selected: getSelected(selectedFilterValues.tags),
        optionType: 'radio',
        loadingText: t('loadingTags'),
        isLoading: loading && isCamerasAvailable
      },
      {
        id: 'location',
        buttonText: t('location'),
        list: dropdownHelper(locations, { textKey: 'name', valueKey: 'id' }),
        buttonIcon: 'Location',
        optionType: 'checkbox',
        loadingText: t('loadingLocations'),
        hasOptionsFilter: true,
        isLoading: locationsLoading,
      },
      {
        id: 'zone',
        buttonText: t('zone'),
        list: dropdownHelper(zones, { textKey: 'name', valueKey: 'id' }),
        buttonIcon: 'Zone',
        optionType: 'checkbox',
        loadingText: t('loadingZones'),
        hasOptionsFilter: true,
        isLoading: loadingZones,
        searchResultText: `${t('Common:filter.showing')} [VISIBLE] ${t('Common:filter.of')} [TOTAL]`
      },
      {
        id: 'type',
        buttonText: t('type'),
        list: dropdownHelper(typeList.current, { textKey: 'text', valueKey: 'value' }),
        buttonIcon: 'AnalysisType',
        optionType: 'radio',
        loadingText: t('loadingCameras'),
        isLoading: loading && isCamerasAvailable,
        selected: getSelected(selectedFilterValues.type)
      }
    ]);
  }, [isCamerasAvailable, locationsLoading, loading, locations, zones, selectedFilterValues.tags, selectedFilterValues.type, loadingZones, t]);

  useEffect(() => {
    setDropdownConfig(getDropdownConfig() as IFilterDropdownConfig[]);
  }, [getDropdownConfig, locationsLoading, locations, zones, loadingZones, selectedFilterValues]);

  useEffect(() => {
    fetchLocations();
    fetchZones();
  }, [fetchLocations, fetchZones]);

  useEffect(() => {
    setSelectedFilterValues(readParams(historyParams, initialFilterValues));
  }, [historyParams]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    push({ search: updateParams(selectedFilterValues) });
  }, [push, selectedFilterValues]);

  const getApiParams = useCallback((): IFetchCamerasOptions => {

    const { type, location, zone, camName, tags } = selectedFilterValues;

    return ({
      locationId: location as string,
      zoneId: zone as string,
      camName: camName as string,
      type: type as string,
      tags: tags as string
    });

  }, [selectedFilterValues]);

  useEffect(() => {
    if (!selectedFilterValues.paramsLoaded) return;
    fetchCameras(getApiParams());
  }, [selectedFilterValues, fetchCameras, getApiParams]);

  const onChangeFilterValue = useCallback((res: IFilterResult[]) => {

    const selectedValues: ISelectedFilterValues = res.reduce((selectedValues: ISelectedFilterValues, { id, selected }) => {
      switch (id) {
        case 'type': selectedValues[id] = (selected as IFilterItem).value as string;
          break;
        case 'tags': selectedValues[id] = (selected as IFilterItem).value as string;
          break;
        case 'camera-search': {
          const {value} = selected as IFilterItem;
          selectedValues.camName = value as string;
          break;
        }
        default:
          selectedValues[id] = (selected as IFilterItem[]).map(({ value }) => value).join(',');
      }
      return selectedValues;
    },
      { ...initialFilterValues, paramsLoaded: selectedFilterValues.paramsLoaded }
    );
    setSelectedFilterValues({...selectedValues});
  }, [selectedFilterValues]);

  return (
    <>
      <PageHeaderWrapper>
        <PageHeader icon='Camera' title={t('cameras')} introductionText={t('introText')} />
      </PageHeaderWrapper>
      <FilterWrapper>
        {dropdownConfig && <FilterBar
          searchersConfig={searchers}
          dropdownsConfig={dropdownConfig}
          datePickersConfig={[]}
          filtersTitle={t('Common:filter.filters') + ':'}
          resultTextTemplate={t('Common:filter.showingResults') + ' ([TOTAL_RESULTS]):'}
          totalResults={cameras.length}
          onChangeCallback={onChangeFilterValue}
        />}
      </FilterWrapper>
      <CamerasContainer>
        {loading ?
          <Spinner styling='primary' size='large' /> :
            cameras.length ?
              cameras.map((camera) => <CameraCard key={camera.id} {...camera} />) :
              <NoCamerasText>{t('noCamerasAvailable')}</NoCamerasText>}
      </CamerasContainer>
    </>
  )
}

export default CameraGridView