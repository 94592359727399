import { LatLngBounds } from 'leaflet';
import { FC, useCallback, useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  Content,
  PageHeader,
  ButtonWithLoading,
  Button,
  TextField,
  TextAreaField,
  useTo,
  Spinner,
  useNotification,
  SelectField
} from 'scorer-ui-kit';
import styled from 'styled-components';
import { useCamera } from '../hooks/useCamera';
import { Camera } from '../hooks/useCameras';
import { useElementTitle } from '../hooks/useElementTitle';
import { useLocations } from '../hooks/useLocations';
import { useZones } from '../hooks/useZones';
import { getZoneBounds } from '../components/Map/mapUtils';
import { EllipsisStyles } from '../Style';

const Container = styled(Content)`
  overflow: inherit;
`;

const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-Between;
  margin-bottom: 30px;
`;

const PageHeaderLeftContainer = styled.div`
  max-width: 610px;
  a {
    max-width: 180px;
    ${EllipsisStyles}
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const CancelButton = styled(Button)`
  margin-right: 9px;
`;

const FormContainer = styled.div`
  margin-top: 53px;
`;

const SectionTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 16px;
  font-weight: 500;
  color: #5a6269;
  display: flex;

  &:after{
    content: "";
    flex: 1 1;
    border-bottom: 1px solid #eee;
    margin: auto;
    margin-left: 10px;
  }
`;

const InputContainer = styled.div`
  flex: 1;
  min-width: 200px;
`;

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 33px;
  justify-content: space-between;
  gap: 20px;
`;

const PositionFieldsRow = styled(FieldsRow)`
  margin: 14px 0 62px;
`;

const DescriptionRow = styled(FieldsRow)`
  margin-top: 14px;
  > label {
    flex: 1;
  }
`;

const RightDiv = styled(InputContainer)`
  margin-top: 0px;
  display: flex;
  gap: 20px;
  > label {
    flex: 1;
  }
`;

interface IParams {
  [key: string]: string
}

interface IZoneBounds {
  id: number,
  bound: LatLngBounds
}

interface IZoneRange {
  posXRange: string
  posYRange: string
}

const EditCamera: FC = () => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [cameraForm, setCameraForm] = useState<Camera>();
  const { camera, cameraLoading, cameraErrorMessage, actions: { fetchCamera, updateCamera } } = useCamera();
  const { locations, loading: loadingLocations, actions: { fetchLocations } } = useLocations();
  const { zones, loadingZones, actions: { fetchZones } } = useZones();
  const [zoneBounds, setZoneBounds] = useState<IZoneBounds[]>([]);
  const [zoneBoundsLoaded, setZoneBoundsLoaded] = useState(false);
  const [zoneRange, setZoneRange] = useState<IZoneRange>();

  const { t } = useTranslation('EditCamera');
  const { cameraID }: IParams = useParams();
  useElementTitle('a', camera?.name!);

  const to = useTo();
  const { sendNotification } = useNotification();

  useEffect(() => {
    fetchZones();
    fetchLocations();
  }, [fetchZones, fetchLocations]);

  // for setting zone bounds
  useEffect(() => {
    if (!loadingZones && !zoneBoundsLoaded) {
      const zoneBounds = zones.map((zone) => {
        const {id, points} = zone;
        const bound = getZoneBounds(points);
        return ({id, bound});
      });
      setZoneBounds(zoneBounds);
      setZoneBoundsLoaded(true);
    }
  }, [loadingZones, zones, zoneBoundsLoaded]);

  // for setting zone range
  useEffect(() => {
    if (cameraForm === undefined) return;
    const zoneBound = zoneBounds.find(item => item.id === cameraForm.zone_id);
    if (zoneBound !== undefined && zoneBound.bound.isValid()) {
      const South = Math.abs(zoneBound.bound.getSouth()).toString();
      const North = Math.abs(zoneBound.bound.getNorth()).toString();
      const West = zoneBound.bound.getWest().toString();
      const East = zoneBound.bound.getEast().toString();
      const posXRange = `[${West+'-'+East}]`;
      const posYRange = `[${North+'-'+South}]`;
      setZoneRange({posXRange, posYRange});
    }
  }, [zoneBounds, cameraForm]);

  const validateForm = useCallback(() => {
    if (cameraForm === undefined) {
      return false;
    };

    const {position_x, position_y, zone_id, name} = cameraForm;
    const zoneBound = zoneBounds.find(item => item.id === zone_id);

    if (!name) {
      sendNotification({ type: 'error', message: t('cameraNameError') });
      return false;
    }
    if (position_x.toString() === '') {
      sendNotification({ type: 'error', message: t('positionXError') });
      return false;
    } else if (position_y.toString() === '') {
      sendNotification({ type: 'error', message: t('positionYError') });
      return false;
    }
    if (
        zoneRange !== undefined &&
        zoneBound !== undefined &&
        zoneBound.bound.isValid() &&
        !(zoneBound.bound.contains([-position_y, position_x]))
      ) {
      sendNotification({
        type: 'error',
        message: t('positionOutOfRange').replace('posXRange', zoneRange.posXRange).replace('posYRange', zoneRange.posYRange)    
      });
      return false;
    }
    return true;
  }, [cameraForm, zoneRange, zoneBounds, sendNotification, t]);

  const onSave = useCallback(async () => {
    if (cameraForm === undefined) return;
    
    if (!validateForm()) return;
    
    setUpdateLoading(true);
    const { errorMessage } = await updateCamera(cameraForm);
    if (errorMessage === '') {
      sendNotification({ type: 'success', message: t('success.cameraUpdatedSuccessfully') })
      to(`/cameras/${cameraID}`)();
    } else {
      sendNotification({ type: 'error', message: t('error.failedToUpdateCamera') })
    }
    setUpdateLoading(false);

  }, [cameraForm, updateCamera, cameraID, to, sendNotification, t, validateForm]);

  useEffect(() => {
    camera && setCameraForm(camera);
  }, [camera]);

  useEffect(() => {
    cameraID && fetchCamera(parseInt(cameraID));
  }, [cameraID, fetchCamera]);

  const onChangeHandler = useCallback(({ target: { name, value } }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    cameraForm && setCameraForm(() => {
      if (['position_x', 'position_y'].includes(name)) {

        if (!(/^[0-9]{0,6}$/.test(value))) return ({...cameraForm});

        return ({ ...cameraForm, [name]: (value === '' ? value : parseInt(value)) });
      }
      return ({ ...cameraForm, [name]: value });
    });
  }, [cameraForm]);

  const onSelectFieldChange = useCallback((name, value) => {
    cameraForm && setCameraForm(({ ...cameraForm, [name]: parseInt(value) }));
  }, [cameraForm]);

  useEffect(() => {
    cameraErrorMessage && sendNotification({ type: 'error', message: t('error.failedToFetchCamera') });
  }, [cameraErrorMessage, sendNotification, t])

  const getZoneRangeText = useCallback((range?: string) => {
    if (range === undefined) return '';
    return range;
  }, []);

  return (
    <Container>
      <PageHeaderWrapper>

        <PageHeaderLeftContainer>
          <PageHeader icon='Camera' title={t('editCameraMeta')} areaTitle={cameraForm?.name ?? '-'} areaHref={'/cameras/' + cameraID} introductionText={t('introText')} />
        </PageHeaderLeftContainer>

        <ButtonsWrapper>
          <CancelButton design='secondary' size='small' onClick={to(`/cameras/${cameraID}`)}>{t('cancel')}</CancelButton>
          <ButtonWithLoading loading={updateLoading} size='small' onClick={onSave}>{t('save')}</ButtonWithLoading>
        </ButtonsWrapper>

      </PageHeaderWrapper>
      {cameraLoading || loadingLocations || loadingZones || cameraErrorMessage !== '' ?
        <Spinner size='large' styling='primary' /> :

        <FormContainer>
          <SectionTitle>{t('basicDetails')}</SectionTitle>

          <FieldsRow>
            <InputContainer>
              <TextField
                name='name'
                label={t('cameraName')}
                fieldState='default'
                value={cameraForm?.name}
                onChange={onChangeHandler}
                maxLength={128}
              />
            </InputContainer>
            <InputContainer>
              <SelectField
                label={{
                  htmlFor: 'location',
                  text: t('location'),
                }}
                changeCallback={(e) => {onSelectFieldChange('location_id', e)}}
                defaultValue={cameraForm?.location_id}
              >
                {
                  locations.map(location => {
                    return <option value={location.id}>{location.name}</option>
                  })
                }
              </SelectField>
            </InputContainer>
          </FieldsRow>

          <PositionFieldsRow>
            <InputContainer>
              <SelectField
                label={{
                  htmlFor: 'zone',
                  text: t('zone'),
                }}
                changeCallback={(e) => onSelectFieldChange('zone_id', e)}
                defaultValue={cameraForm?.zone_id}
              >
                {
                  zones.map(zone => {
                    return <option value={zone.id}>{zone.name}</option>
                  })
                }
              </SelectField>
            </InputContainer>
            <RightDiv>
              <TextField
                name='position_x'
                label={`${t('positionX')} ${getZoneRangeText(zoneRange?.posXRange)}`}
                fieldState='default'
                value={cameraForm?.position_x}
                onChange={onChangeHandler}
                maxLength={6}
              />

              <TextField
                name='position_y'
                label={`${t('positionY')} ${getZoneRangeText(zoneRange?.posYRange)}`}
                fieldState='default'
                value={cameraForm?.position_y}
                onChange={onChangeHandler}
                maxLength={6}
              />
            </RightDiv>

          </PositionFieldsRow>

          <SectionTitle>{t('metaData')}</SectionTitle>

          <FieldsRow>
            <InputContainer>
              <TextField
                name='category'
                label={t('category')}
                fieldState='default'
                value={cameraForm?.category}
                onChange={onChangeHandler}
                maxLength={128}
              />
            </InputContainer>
            <InputContainer>
              <TextField
                name='tags'
                label={t('tags')}
                fieldState='default'
                value={cameraForm?.tags}
                onChange={onChangeHandler}
                maxLength={256}
              />
            </InputContainer>
          </FieldsRow>

          <DescriptionRow>
            <TextAreaField
              name='details'
              label={t('description')}
              fieldState='default'
              rows={6}
              value={cameraForm?.details}
              onChange={onChangeHandler}
              maxLength={256}
            />
          </DescriptionRow>
        </FormContainer>}

    </Container>
  )
};

export default EditCamera;