import { useState, useCallback } from 'react'
import { Points } from '../types';

export interface Zone {
  id: number;
  name: string;
  points: Points[]
  location_id: number
  image: string
}

interface ZoneResponse {
  zones: Zone[]
  message: string
  info : {
    total: number
  }
}

export const useZones = () => {
  const [zones, setZones] = useState<Zone[]>([]);
  const [loadingZones, setLoadingZones] = useState(true);
  const [zonesErrorMsg, setZonesErrorMsg] = useState('');
  const [zoneCount, setZoneCount] = useState(0);

  const fetchZones = useCallback(async () => {
    setLoadingZones(true);
    setZonesErrorMsg('');
    try {
      const response = await fetch('/api/zone');
      if (response.ok) {
        const {
          zones = [],
          info: { total = 0 }
        } = await response.json() as ZoneResponse
        setZones(zones);
        setZoneCount(total);
      } else {
        console.error(`Zones fetch error: ${response.statusText}`)
        setZonesErrorMsg(`Zones fetch error: ${response.statusText}`);
      }
    } catch (error) {
      setZonesErrorMsg('Unknown error fetching zones');
    }
    setLoadingZones(false);
  }, [])

  return {
    zones,
    zoneCount,
    zonesErrorMsg,
    loadingZones,
    actions: {
      fetchZones
    }
  }
}